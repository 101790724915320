import React, { Component } from "react";
import { createAppContainer } from "react-navigation";
import { createBottomTabNavigator } from "react-navigation-tabs";
import { Image, StyleSheet } from "react-native";
import color from "./Component/Color";
// 新页面
import ResourceHome from "./PostEnglish/ResourceHome";
// src\PostEnglish\ResourceHome.js
import My from "./gMy/my";

const Tab = createBottomTabNavigator(
  {
    ResourceHome: {
      screen: ResourceHome,
      navigationOptions: {
        tabBarLabel: "资源",
        tabBarIcon: ({ tintColor, focused }) => {
          if (focused) {
            return (
              <Image
                style={[{ height: 20, width: 20 }]}
                resizeMode="contain"
                source={require("./images/tab/isClass.png")}
              />
            );
          } else {
            return (
              <Image
                style={[{ height: 20, width: 20 }]}
                resizeMode="contain"
                source={require("./images/tab/class.png")}
              />
            );
          }
        },
      },
    },
    My: {
      screen: My,
      navigationOptions: {
        tabBarLabel: "我的",
        tabBarIcon: ({ tintColor, focused }) => {
          if (focused) {
            return (
              <Image
                style={[{ height: 20, width: 20 }]}
                resizeMode="contain"
                source={require("./images/tab/isMy.png")}
              />
            );
          } else {
            return (
              <Image
                style={[{ height: 20, width: 20 }]}
                resizeMode="contain"
                source={require("./images/tab/my.png")}
              />
            );
          }
        },
      },
    },
  },
  {
    tabBarPosition: "bottom",
    lazy: true, // 是否懒加载
    //是否在更改标签时显示动画
    animationEnabled: true,
    //是否允许在标签之间进行滑动
    swipeEnabled: false,
    //按 back 键是否跳转到第一个Tab(首页)， none 为不跳转
    backBehavior: "none",
    initialRouteName: "ResourceHome",
    tabBarOptions: {
      activeTintColor: color.tabColor, //选中tab时 图片和字体的颜色
      showIcon: true, //android 默认不显示 icon, 需要设置为 true 才会显示
      pressOpacity: 0.8,
      style: {
        height: 50,
        backgroundColor: color.white, //tabBar上方有一条颜色线
        zIndex: 0,
        position: "relative",
      },
      labelStyle: {
        fontSize: color.above, // 文字大小
        paddingVertical: 0,
        marginTop: -4,
      },
      iconStyle: {
        marginTop: -3,
      },
      tabStyle: {
        backgroundColor: color.white, // TabBar 背景色
      },
    },
  }
);

const styles = StyleSheet.create({
  images: {
    width: 20,
    height: 20,
  },
});

export default createAppContainer(Tab);
