import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Image,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import ReturnPHCom from "../../Component/returnPHCom";
import color from "../../Component/Color";

export default class syntaxTagList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // 传参
      documentTitle: this.props.navigation.state.params.Title,
      Code: this.props.navigation.state.params.Code,
      DValue: this.props.navigation.state.params.DValue,
      // 列表
      List: [],
      foldState: false,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.getData();
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage(
        "gSyntax/syntaxTagList",
        this.state.documentTitle + "标签语法列表"
      );
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getData = () => {
    let info = { Code: this.state.Code, DValue: this.state.DValue };

    HttpUtil.post("76112_2", info).then((response) => {
      console.log("76112_2", response);
      if (response.Code == 1) {
        let data = response.Data;
        let list = data[0].Children;
        this.setState({
          List: list,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  showTreeFun = (item, flag) => {
    item.showFlag = flag;
    var arr = [];
    arr = arr.concat(this.state.List);
    this.setState(
      {
        List: arr,
      },
      () => {
        this.inspectFold();
      }
    );
  };

  // 递归折叠
  handleFold = (data, type) => {
    if (data.length == 0 || data == null) {
      return;
    }
    data.forEach((item) => {
      this.showTreeFun(item, type);
      if (item.Children && item.Children.length > 0) {
        this.handleFold(item.Children, type);
      }
    });
  };

  inspectFold = () => {
    let state = true;
    for (let i = 0; i < this.state.List.length; i++) {
      if (this.state.List[i].showFlag === 0) {
        state = true;
      } else {
        state = false;
        break;
      }
    }
    this.setState({
      foldState: state,
    });
  };

  handleNodeClick = (item, flag) => {
    // 1 收起 0/undefined 展开
    if (item.Children && item.Children.length > 0) {
      flag = flag === undefined ? 1 : flag;
      let state = Number(!flag);
      this.showTreeFun(item, state);
      return;
    }
    this.gotoDetail(item);
    // if (item.disabledFlag != 1) {
    //   this.gotoPointsInfo(item);
    // }
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };
  gotoDetail = (item, index) => {
    let query = {
      Title: item.DText,
      ID: item.ID,
      // Index: index,
      // Array: this.state.List,
    };
    this.props.navigation.navigate("syntaxDetail", query);
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  // 树形切换
  treeComponent = (item, key, num) => {
    return (
      <View
        key={key}
        style={{
          width: "100%",
          backgroundColor:
            num == 0 &&
            item.showFlag != 0 &&
            item.Children &&
            item.Children.length > 0
              ? "#c7e8fe"
              : "#fff",
        }}
      >
        <View style={styles.treeLine}>
          <View style={[styles.treeInLine, { width: 100 - num * 8 + "%" }]}>
            <TouchableOpacity
              style={styles.textShow}
              onPress={() => this.handleNodeClick(item, item.showFlag)}
            >
              <Text style={styles.TitleSty}>
                {key + 1 + ".  " + item.DText}
              </Text>
              {item.Children == null && (
                <Image
                  source={require("../../images/my/gt.png")}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
              )}
            </TouchableOpacity>
          </View>
        </View>
        {item.Children &&
          item.Children.length > 0 &&
          (item.showFlag === 1 || item.showFlag === undefined) &&
          item.Children.map((item, key) => {
            return this.treeComponent(item, key, num + 1);
          })}
      </View>
    );
  };

  _renderItem = (item, index) => {
    return (
      <TouchableOpacity
        key={index}
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 15,
          backgroundColor: index % 2 === 0 ? color.ZebraPattern : "#ffffff",
          paddingHorizontal: 20,
        }}
        onPress={() => this.gotoDetail(item, index)}
      >
        <Text style={styles.TitleSty}>{index + 1 + ".  " + item.DText}</Text>
        <Image
          source={require("../../images/my/gt.png")}
          style={{
            width: 20,
            height: 20,
          }}
        />
      </TouchableOpacity>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ flex: 1, backgroundColor: "#fff" }}
          >
            {this._renderHeader()}
            {this.state.List.length > 0 &&
              this.state.List.map((item, index) => {
                return this.treeComponent(item, index, 0);
              })}
          </ScrollView>
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  TitleSty: { flex: 1, paddingRight: 15, fontSize: 15, lineHeight: 20 },
  /* 树 */
  treeLine: {
    paddingVertical: 8,
    marginHorizontal: 20,
    borderBottomColor: "#dedede",
    borderBottomWidth: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  treeInLine: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textShow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 30,
  },
  /* end */
});
