/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-01 11:12:06
 * @LastEditTime: 2019-10-17 00:54:57
 * @LastEditors: Please set LastEditors
 */
/**
 * 接口详情
 */

var config = {
  /**
   * 微信id
   */
  wxId: "wxc9114ab804a417ef",
  // 音频url
  audioUrl: "http://www.math110.com/Math110Back/doc",
  /**
   * 版本号
   */
  chapterMsg: "",
  version: "1.1.1",
  termMsg: "",
  /**
   * 公司名称
   */
  company: "",
  ChoseclassMsg: "",
  /**
   * 英语课程信息
   */
  englishCourseInfor: "",
  englishCourseChapter: "",
  /**
   * 登录的用户信息
   */
  userid: "0",
  user: "",
  username: "",
  isSwitch: "",
  error: "网络请求失败，请稍后重试!",
  classMsg: "",
  classInfo: "",
  studentInfo: "",
  examList: [],
  wordSlogan: [],
  ColorObj: {
    // 高亮
    HighLight: "red",
  },
  WhiteSpace: 100,
};
export default config;
