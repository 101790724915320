import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../../Component/BaseView";
import config from "../../../Component/Config";
import { ToastShort, consolePage } from "../../../Component/ToastUtlis";
import HttpUtil from "../../../Component/HttpUtil";
import test from "../../../ApublicStyle/TestStyle";
import ApublicColorFun from "../../../ApublicStyle/TestColorFun";
import ReturnPHCom from "../../../Component/returnPHCom";

var deviceHeight = Dimensions.get("window").height;

export default class knowledgeExamPointsTestDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      quesIndex: 0,
      pageState: 1,
      // 提交数据之后
      testQuestion: [],
      rightNum: "--",
      isSubmit: false,
      end: false,
      modalVisible2: false,
      rightWord: "",
      loading: false,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("KP_GrammarTestDetail");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getQuestions();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  replaceString = (inputString) => {
    // 使用正则表达式替换'###'为换行符'\n'，'&&&'为'________'
    const replacedString = inputString
      .split(/&&&/g)
      .join("________")
      .split(/###/g)
      .join("\n");
    return replacedString;
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.back();
    }
  }

  getQuestions = () => {
    let info = {
      DValue: this.props.navigation.state.params.dictValue,
      dictId: this.props.navigation.state.params.dictID,
    };
    HttpUtil.post("626362", info).then((res) => {
      let data = res.Data;
      if (data && data.length == 0) {
        this.setState({
          pageState: 1,
        });
        return;
      }
      data.forEach((i) => {
        i.question = i.title ? this.replaceString(i.title) : "";
        i.myAnswer = null;
        const wordArray = i.options
          ? i.options.split("&").map((item) => ({ word: item, color: "" }))
          : [];
        i.options = wordArray;
      });
      console.log("获取题目626362", data);
      this.setState({
        list: data,
        pageState: 1,
      });
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  // 答题结果 背景色 边框颜色
  Option = (item, index, type, option) => {
    let doState = this.state.list[this.state.quesIndex].myAnswer;
    let myAnswer = option;
    let correctAnswer = this.state.list[this.state.quesIndex].answer;
    if (type == "backgroundColor") {
      if (doState != null && doState != correctAnswer && myAnswer == doState) {
        return "#fef0f0";
      }
      if (doState != null && myAnswer == correctAnswer) {
        return "#f0f9eb";
      }
      if (doState == null || myAnswer != doState) {
        return "#fff";
      }
    } else if (type == "borderColor") {
      if (doState != null && doState != correctAnswer && myAnswer == doState) {
        // 答错
        return "#fbc4c4";
      }
      if (doState != null && myAnswer == correctAnswer) {
        // 答对
        return "#c2e7b0";
      }
      if (doState == null || myAnswer != doState) {
        // 未答题
        return "#fff";
      }
    }
  };

  OptionRecord = (item, correct, type) => {
    let option = type; // 选项
    let correctAnswer = correct.answer; // 正确选项
    let myAnswer = correct.myAnswer; // 我的答案
    if (option == correctAnswer) {
      return "#05c08b";
    }
    if (myAnswer != correctAnswer && myAnswer == option) {
      return "#F56C6C";
    }
    return "#909399";
  };

  /*************************************************************************** 逻辑 ***************************************************************************/
  // 答题 选择

  switch = (item) => {
    let num = 0;
    if (item == "A") {
      num = 0;
    } else if (item == "B") {
      num = 1;
    } else if (item == "C") {
      num = 2;
    } else if (item == "D") {
      num = 3;
    } else if (item == "E") {
      num = 4;
    }
    return num;
  };

  doQues = (item, index, option) => {
    let obj = this.state.list[this.state.quesIndex];
    if (obj.myAnswer != null) {
      // 选择之后 禁止修改
      return;
    }
    obj.myAnswer = option;
    if (
      (option == obj.answer && obj.myAnswer == obj.answer) ||
      option == obj.answer
    ) {
      // 正确
      item.color = 1;
      obj.isCorrect = 1;
      this.setState({
        rightWord: item.word,
      });
    } else if (obj.myAnswer != obj.answer && obj.myAnswer == option) {
      // 错误
      item.color = 2;
      obj.isCorrect = 0;
      this.setState({
        rightWord: obj.options[this.switch(obj.answer)].word,
      });
    } else {
      item.color = "";
    }
    let newAnwer = JSON.parse(JSON.stringify(this.state.list));
    this.setState({
      list: newAnwer,
    });
    if (
      obj.myAnswer == obj.answer &&
      this.state.quesIndex + 1 < this.state.list.length
    ) {
      setTimeout(
        () => {
          this.doNext();
        },
        localStorage.getItem("delayTime")
          ? localStorage.getItem("delayTime") * 1000
          : 500
      );
    }
    if (
      obj.myAnswer != "" &&
      this.state.quesIndex + 1 == this.state.list.length
    ) {
      this.setState({
        end: true,
      });
    }
  };

  // 下一题
  doNext = () => {
    if (this.state.end) {
      this.submit();
      return;
    }
    let num = this.state.quesIndex + 1;
    this.setState({
      quesIndex: num,
    });
  };

  // 返回
  nextRound = () => {
    this.props.navigation.pop();
  };

  // 提交
  submit = () => {
    if (this.state.loading) {
      ToastShort("请勿重复提交");
      return;
    }
    this.setState({
      loading: true,
    });
    let links = [];
    let list2 = [];
    let num = 0;
    list2 = list2.concat(this.state.list);
    this.state.list.forEach((i) => {
      if (i.isCorrect == 1) {
        num++;
      }
      links.push({
        CorrectAnswer: i.answer,
        IsCorrect: i.isCorrect,
        PersonalAnswer: i.myAnswer,
        Index: i.orderIndex,
        QuestionId: i.ID,
        QuestionType: "0",
        options: i.options,
        question: i.question,
        analysis: i.analysis,
      });
    });
    this.setState({
      testQuestion: list2,
      pageState: 2,
      rightNum: num,
    });
    this.postData(links, num);
  };

  postData = (links, num) => {
    let info = {
      BookId: this.props.navigation.state.params.dictID,
      ClassId: config.classMsg.ClassId,
      StudentName: config.user.userName,
      StudentId: config.user.ID,
      StudentBh: config.user.userBh,
      AllNum: links.length,
      RightNum: num,
      RightRate: num / links.length,
      Type: 1,
      SubmitAnswer: JSON.stringify(links),
    };
    HttpUtil.post("626366", info)
      .then((res) => {
        console.log(res);
        if (res.Code == 1) {
          ToastShort("提交成功");
          this.setState({
            pageState: 2,
            isSubmit: true,
          });
        } else {
          ToastShort(res.Msg);
        }
      })
      .catch((e) => {
        console.log(e);
        ToastShort("提交失败");
      });
  };
  /*************************************************************************** 跳转 ***************************************************************************/

  back = () => {
    this.props.navigation.pop();
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          语法测试
        </Text>
      </View>
    );
  };

  _renderNext = () => {
    return (
      <View>
        <View style={styles.NextBtn}>
          {/* 进度 */}
          <View
            style={{
              height: 40,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 12 }}>{"练习进度"}</Text>
            <Text style={test.schedule_text}>
              {this.state.list && this.state.list.length > 0
                ? this.state.quesIndex + 1 + "/" + this.state.list.length
                : "-- / --"}
            </Text>
          </View>
          {/* 切换题目 */}
          {((this.state.list &&
            this.state.list.length > 0 &&
            this.state.list[this.state.quesIndex].myAnswer != null &&
            this.state.list[this.state.quesIndex].myAnswer !=
              this.state.list[this.state.quesIndex].word) ||
            this.state.end) &&
          !this.state.loading ? (
            <TouchableOpacity
              onPress={() => {
                this.doNext();
              }}
              style={test.btn_noDisabled}
            >
              <Text style={{ fontSize: 15, color: "#fff" }}>
                {this.state.quesIndex + 1 == this.state.list.length
                  ? "提 交"
                  : "下一题"}
              </Text>
            </TouchableOpacity>
          ) : (
            <View style={test.btn_disabled}>
              <Text style={{ fontSize: 15, color: "#fff" }}>
                {this.state.quesIndex + 1 == this.state.list.length
                  ? "提 交"
                  : "下一题"}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  renderColoredText = (text, color, key) => {
    return (
      <Text key={key} style={{ color: color, fontSize: 22, fontWeight: "500" }}>
        {text}
      </Text>
    );
  };

  stemCom = () => {
    let data = this.state.list[this.state.quesIndex];
    return (
      data &&
      data != "" && (
        <View style={test.stem_container}>
          {/* 题目 */}
          <View style={{ marginTop: 20 }}>
            <Text
              style={[
                test.stem_question,
                {
                  fontSize: 22,
                  fontWeight: 500,
                  textAlign: "left",
                  lineHeight: 30,
                },
              ]}
            >
              {data.question.split("________").map((textPart, index, arr) => {
                if (index < arr.length - 1) {
                  return (
                    <React.Fragment key={index}>
                      {textPart}
                      <Text
                        style={{
                          color: "#1c76fc",
                          fontSize: 22,
                          fontWeight: "500",
                        }}
                      >
                        ________
                      </Text>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={index}>{textPart}</React.Fragment>
                  );
                }
              })}
            </Text>
          </View>
        </View>
      )
    );
  };

  optionCom = () => {
    let data = this.state.list[this.state.quesIndex]?.options;
    let answer = this.state.list[this.state.quesIndex];
    return (
      <View style={test.container_space}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  this.doQues(
                    item,
                    index,
                    String.fromCharCode(64 + parseInt(1 + index))
                  );
                }}
                key={index}
                style={[
                  test.option_box,
                  {
                    backgroundColor: ApublicColorFun.Option(
                      "backgroundColor",
                      answer.myAnswer,
                      answer.answer,
                      String.fromCharCode(64 + parseInt(1 + index))
                    ),
                    borderColor: ApublicColorFun.Option(
                      "borderColor",
                      answer.myAnswer,
                      answer.answer,
                      String.fromCharCode(64 + parseInt(1 + index))
                    ),
                  },
                ]}
              >
                <View style={test.option_container}>
                  <Text style={[test.option_index, { display: "flex" }]}>
                    {String.fromCharCode(64 + parseInt(1 + index)) + ". "}
                    {(item.color == 1 || item.color == 2) &&
                    item.color != "" ? (
                      this.wordInfo(item)
                    ) : (
                      <Text style={test.option_value_E}>{item.word}</Text>
                    )}
                  </Text>
                  {item.color != "" && item.color == 1 && (
                    <Image
                      source={require("../../../images/word/correct.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                  {item.color != "" && item.color == 2 && (
                    <Image
                      source={require("../../../images/word/erro.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        {/* 解析 */}
        {this.state.list &&
          this.state.list.length > 0 &&
          this.state.list[this.state.quesIndex].isCorrect == 0 &&
          this.analysisCom()}
      </View>
    );
  };

  analysisCom = () => {
    let data = this.state.list[this.state.quesIndex];
    return (
      <View style={{ width: "100%", marginTop: 10 }}>
        <Text style={[test.option_value_E, { color: "#999", lineHeight: 24 }]}>
          {data.analysis}
        </Text>
      </View>
    );
  };

  wordInfo = (item) => {
    return (
      <View style={{ flexDirection: "column", width: "95%", marginLeft: 5 }}>
        <Text style={test.option_value_E}>{item.word}</Text>
      </View>
    );
  };

  fullLoading = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size={"large"} color={"#666"} animating={true} />
        <Text style={{ color: "#666", fontSize: 18, marginTop: 15 }}>
          正在加载中
        </Text>
      </View>
    );
  };

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }}>
          <View
            style={{ width: "100%", paddingHorizontal: 20, paddingTop: 20 }}
          >
            <Text
              style={{
                color:
                  this.state.rightNum / this.state.testQuestion.length > 0.8
                    ? "#40CC5C"
                    : "#E83333",
                fontSize: 20,
                fontWeight: 600,
                lineHeight: 20,
                textAlign: "center",
              }}
            >
              {(
                (this.state.rightNum / this.state.testQuestion.length) *
                100
              ).toFixed(0)}
              % 正确率
            </Text>
          </View>
          {/* 作答情况 */}
          <View
            style={{
              margin: 20,
              height: 58,
              borderRadius: 10,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}
              >
                {this.state.rightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                正确
              </Text>
            </View>
            <View
              style={{
                width: "34%",
                height: "70%",
                borderLeftWidth: 1,
                borderColor: "#E5E5E5",
                borderRightWidth: 1,
              }}
            >
              <Text
                style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}
              >
                {this.state.testQuestion
                  ? this.state.testQuestion.length - this.state.rightNum
                  : ""}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                错误
              </Text>
            </View>
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ fontSize: 18, color: "#333", textAlign: "center" }}
              >
                {this.state.testQuestion.length}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                总数
              </Text>
            </View>
          </View>
          {this.state.testQuestion &&
            this.state.testQuestion.length > 0 &&
            this.state.testQuestion.map((item, index) => {
              let ques = item.options;
              return (
                <View
                  key={index}
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingHorizontal: 20,
                    marginBottom: 20,
                  }}
                >
                  <Text style={[test.option_index, { marginBottom: 5 }]}>
                    {index + 1 + ". " + item.question}
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#333",
                      lineHeight: 16,
                      marginBottom: 10,
                      marginLeft: 15,
                    }}
                  >
                    {item.meaning}
                  </Text>
                  {ques &&
                    ques.length > 0 &&
                    ques.map((i, key) => {
                      return (
                        <View key={key} style={{ marginBottom: 5 }}>
                          <Text
                            style={[
                              test.option_index,
                              {
                                color: ApublicColorFun.OptionRecord(
                                  String.fromCharCode(64 + parseInt(1 + key)),
                                  item.myAnswer,
                                  item.answer
                                ),
                              },
                            ]}
                          >
                            {String.fromCharCode(64 + parseInt(1 + key)) + ". "}
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  test.option_value_E,
                                  {
                                    color: ApublicColorFun.OptionRecord(
                                      String.fromCharCode(
                                        64 + parseInt(1 + key)
                                      ),
                                      item.myAnswer,
                                      item.answer
                                    ),
                                  },
                                ]}
                              >
                                {i.word.trim()}
                              </Text>
                            </View>
                          </Text>
                        </View>
                      );
                    })}
                  <View style={{ width: "100%", textAlign: "left" }}>
                    {item.analysis != "" && (
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          lineHeight: 25,
                          color: "#333",
                        }}
                      >
                        <Text style={{ fontWeight: "bold" }}>解析：</Text>
                        {item.analysis.trim()}
                      </Text>
                    )}
                  </View>
                </View>
              );
            })}
        </ScrollView>
        {this._renderNextTest()}
      </View>
    );
  };

  _renderNextTest = () => {
    return (
      <View style={styles.NextBtn}>
        {/* 下一轮  */}
        <TouchableOpacity
          onPress={() => {
            this.nextRound();
          }}
          style={{
            height: 40,
            width: "100%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>{"返回"}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  /*************************************************************************** render ***************************************************************************/

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#fff",
          }}
        >
          {this._renderHeader()}
          {this.state.pageState == 1 && (
            <ScrollView style={{ flex: 1 }}>
              <View
                style={{
                  minHeight: deviceHeight - 120,
                  paddingBottom: 20,
                  backgroundColor: "#f8f8f8",
                }}
              >
                {/* 题干 */}
                {this.stemCom()}
                {/* 选项 */}
                {this.optionCom()}
              </View>
            </ScrollView>
          )}
          {this.state.pageState == 1 && this._renderNext()}
          {this.state.pageState == 0 && this.fullLoading()}
          {this.state.pageState == 2 && this.testRecord()}
          <ReturnPHCom
            source={{ curPage: "extracrr" }}
            _setReturnName={this._setReturnName.bind(this)}
          ></ReturnPHCom>
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  NextBtn: {
    paddingHorizontal: 20,
    height: 60,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    width: "35%",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 45 + BaseView.currentHeight,
    right: 20,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    backgroundColor: "#f8f8f8",
    paddingBottom: 10,
  },
  btnSty: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
  },
});
