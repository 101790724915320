import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../Component/BaseView";
import color from "../../Component/Color";
import config from "../../Component/Config";
import { FlatList } from "react-native-gesture-handler";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import moment from "moment";

import ReturnPHCom from "../../Component/returnPHCom";

const Lheight = Dimensions.get("window").height - 50;

export default class practiceRecord extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.state = {
      isLoadMore: false,
      list: [],
      info: this.props.navigation.state.params,
      title: "练习记录",
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    console.log(this.props.navigation.state.params);
    if (this.state.info) {
      this.setState({
        title:
          this.state.info.type == 1
            ? "单词练习记录"
            : this.state.info.type == 2
            ? "语句练习记录"
            : "语法测试记录",
      });
    }
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("练习记录", "KP_TestRecord");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getData();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  _onLoadMore = () => {
    this.page = this.page + 1;
    this.getData();
  };

  getData = () => {
    let api = "626467_1";
    let param = {
      dictID: this.state.info.dictID,
      UnitId: this.state.info.dictID,
      ClassId: config.classMsg.ClassId,
      StudentId: config.user.ID,
      pageStart: this.page,
      pageSize: 20,
    };
    if (this.props.navigation.state.params.type == 3) {
      api = "626366_1";
      param.Type = 1;
    } else {
      param.TestType = this.props.navigation.state.params.type;
    }
    HttpUtil.post(api, param).then((response) => {
      console.info("获取测试记录", response.Data);
      if (response.Code == 1) {
        this.setState({
          list:
            this.page != 0
              ? this.state.list.concat(response.Data)
              : response.Data,
          isLoadMore: response.Data.length != 20 ? false : true,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  _ListEmptyComponent = () => {
    if (this.state.isLoadMore == false) {
      return (
        <View
          style={{
            width: "100%",
            height: 40,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "gray", fontSize: 14 }}>
            {this.state.list.length == 0 ? "" : "没有更多数据"}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.indicatorContainer}>
          <ActivityIndicator
            style={styles.indicator}
            size={"small"}
            color={color.theme}
            animating={true}
          />
          <Text>正在加载更多</Text>
        </View>
      );
    }
  };

  _renderHeaders() {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight,
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottomColor: "#E5E5E5",
        }}
      >
        <Text style={{ fontSize: 18, color: "#333", lineHeight: 40 }}>
          {this.state.title}
        </Text>
      </View>
    );
  }

  _renderHeader() {
    return (
      <View>
        <View
          style={{
            width: "100%",
            height: 42,
            display: "flex",
            flexDirection: "row",
            borderBottomColor: "#CCCCCC",
            borderBottomWidth: 1,
          }}
        >
          <View style={{ width: "10%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"序"}
            </Text>
          </View>
          <View style={{ width: "40%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"练习时间"}
            </Text>
          </View>
          <View style={{ width: "26%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"正确 / 总数"}
            </Text>
          </View>
          <View style={{ width: "24%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"正确率"}
            </Text>
          </View>
        </View>
      </View>
    );
  }

  jumpTestDetail = (item) => {
    let name = "KP_WordsRecordDetail"; // 单词
    if (this.props.navigation.state.params.type == 3) {
      // 测试
      name = "knowledgeExamPracticeRecordDetail";
    }
    this.props.navigation.navigate(name, {
      item: item,
      typeName: "记录详情",
      type: this.props.navigation.state.params.type,
    });
  };

  _renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        style={styles.signinListSty2}
        onPress={() => this.jumpTestDetail(item)}
      >
        <View style={{ width: "10%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {index + 1}
          </Text>
        </View>
        <View style={{ width: "40%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {moment(item.CreateTime).format("YYYY-MM-DD HH:mm")}
          </Text>
        </View>
        <View style={{ width: "26%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {item.RightNum} / {item.AllNum}
          </Text>
        </View>
        <View style={{ width: "24%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {((item.RightNum / item.AllNum) * 100).toFixed(0)}%
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="#fff"
          translucent={true}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Lheight,
            backgroundColor: color.background,
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeaders()}
          <FlatList
            data={this.state.list}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            keyExtractor={this._keyExtractor}
            renderItem={this._renderItem}
            ListEmptyComponent={this._ListEmptyComponent}
            ListHeaderComponent={this._renderHeader}
            onEndReached={() => {
              //当所有的数据都已经渲染过，并且列表被滚动到距离最底部时调用
              if (this.state.isLoadMore) {
                this._onLoadMore(); //加载数据（不带参数）
              }
            }}
            onEndReachedThreshold={0.3}
          />
          <ReturnPHCom
            source={{ curPage: "extracrr" }}
            _setReturnName={this._setReturnName.bind(this)}
          ></ReturnPHCom>
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flex_row1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: "100%",
    flex: 1,
    backgroundColor: "#fff",
  },
  courseContentCon: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
  },
  courseContentItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  courseContentItem1: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15,
  },
  courseContentItem1Txt: {
    flex: 1,
    fontSize: 13,
    color: "#666666",
    marginRight: 30,
  },
  btn_detail: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  indicator: {
    margin: 10,
  },
  indicatorContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  modalContent: {
    width: "70%",
    height: "100%",
    backgroundColor: "#ffffff",
  },
  treeLine: {
    width: "100%",
    height: 46,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  treeInLine: {
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textShow: {
    color: "#333",
    flex: 1,
    lineHeight: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  signinListSty2: {
    width: "100%",
    height: 45,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
  },
  btn_sty: {
    justifyContent: "center",
    alignItems: "center",
  },
});
