import moment from "moment";

export default class storage {
  static DailyBrowsingHistory(module, title, type) {
    storage.initDailyBrowsingHistory();
    let storageData = JSON.parse(localStorage.getItem("DailyBrowsingHistory"));
    let today = moment(new Date()).format("yyyy-MM-DD");
    /** 每日存储 模块
     * 分类单词 分类词组 分类例句 时文阅读
     * 语法资源 内容资源 词组例句 例句资源
     * 预科词汇（初中、高中）
     * 考点提升（考点内容，单词短语，考点语句）
     */
    let obj = {
      storageDate: moment(new Date()).format("yyyy-MM-DD HH:mm"),
      module,
      title,
      type,
    };
    storageData[today].unshift(obj);
    localStorage.setItem("DailyBrowsingHistory", JSON.stringify(storageData));
  }
  static initDailyBrowsingHistory() {
    let history =
      JSON.parse(localStorage.getItem("DailyBrowsingHistory")) || {};
    let today = moment(new Date()).format("yyyy-MM-DD");
    if (history[today] != undefined) {
      // console.log("今天已有记录");
      return;
    } else {
      // 创建今天的记录
      history[today] = [];
      localStorage.setItem("DailyBrowsingHistory", JSON.stringify(history));
    }
  }
  static StorageProgress(type, ID, name, page, pageTotal) {
    storage.initStorageProgress();
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"));
    /** type 值
    分类单词  classifyWord
    分类词组  classifyPhrase
    分类例句  classifySentence
    时文阅读  CurrentReading
    语法资源  GrammarResources
    内容资源  ContentResources
    词组资源  PhraseResources
    例句资源  ExampleSentenceResources
    预科单词  PreparatoryVocabulary
    词组例句  PhraseExampleSentence
  */
    let obj = {
      ID: ID,
      name: name,
      page: page,
      pageTotal: pageTotal,
    };
    if (!storageData[type]) {
      storageData[type] = [];
    }
    let isExist = false;
    for (let i = 0; i < storageData[type].length; i++) {
      if (storageData[type][i].ID == obj.ID) {
        isExist = true;
        storageData[type].splice(i, 1, obj);
      }
    }
    if (!isExist) {
      storageData[type].push(obj);
    }
    localStorage.setItem("StorageProgress", JSON.stringify(storageData));
  }
  static initStorageProgress() {
    if (JSON.parse(localStorage.getItem("StorageProgress")) == null) {
      let obj = {
        // 分类单词
        classifyWord: [],
        // 分类词组
        classifyPhrase: [],
        // 分类例句
        classifySentence: [],
        // 时文阅读
        CurrentReading: [],
        // 语法资源
        GrammarResources: [],
        // 内容资源
        ContentResources: [],
        // 词组资源
        PhraseResources: [],
        // 例句资源
        ExampleSentenceResources: [],
        // 预科单词
        PreparatoryVocabulary: [],
        // 词组例句
        PhraseExampleSentence: [],
      };
      localStorage.setItem("StorageProgress", JSON.stringify(obj));
      return;
    }
    if (JSON.parse(localStorage.getItem("StorageProgress")).length > 0) {
      localStorage.removeItem("StorageProgress");
      let obj = {
        // 分类单词
        classifyWord: [],
        // 分类词组
        classifyPhrase: [],
        // 分类例句
        classifySentence: [],
        // 时文阅读
        CurrentReading: [],
        // 语法资源
        GrammarResources: [],
        // 内容资源
        ContentResources: [],
        // 词组资源
        PhraseResources: [],
        // 例句资源
        ExampleSentenceResources: [],
        // 预科单词
        PreparatoryVocabulary: [],
        // 词组例句
        PhraseExampleSentence: [],
      };
      localStorage.setItem("StorageProgress", JSON.stringify(obj));
      return;
    }
  }
  static initFlashCard() {
    let obj = {
      BookId: String, // 课本ID
      UnitId: String, // 单元ID
      LatestGroupNum: Number, // 最新分组
      MaxGroupNum: Number, // 最大分组
      wordArr: Array, // 分组单词
      StepRecored: Array, // 每一组单词的学习记录
    };
  }
  static StudyReport(info, ModuleType, TestType, Score) {
    // 定义
    let storageData = JSON.parse(localStorage.getItem("StudyReport")) || [];
    let today = moment(new Date()).format("yyyy-MM-DD");
    let latest = {
      // 1 单词测评 2 单词背诵 3 单词测试
      ModuleType: ModuleType,
      TestType: TestType,
      Unit: info.Unit,
      Score: Score,
      SubmitDate: new Date(),
    };
    if (ModuleType == 2) {
      latest.GroupIndex = info.GroupIndex;
    }
    // 判断
    let isExist = false;
    for (let i = 0; i < storageData.length; i++) {
      // 本地存储本书ID
      if (info.BookId == storageData[i].BookId) {
        isExist = true;
        // 今天是否有记录 有 push 无 =
        if (storageData[i].RecordDetail[today] != undefined) {
          storageData[i].RecordDetail[today].push(latest);
        } else {
          storageData[i].RecordDetail[today] = [latest];
        }
      }
    }
    // 本地存储不存在本书ID
    if (!isExist) {
      let obj = {
        BookId: info.BookId,
        BookName: info.BookName,
        RecordDetail: {},
      };
      obj.RecordDetail[today] = [latest];
      storageData.push(obj);
    }
    console.log("单词学习报告数据", storageData);
    localStorage.setItem("StudyReport", JSON.stringify(storageData));
  }
  static PrivateWordRecords(data) {
    /**data []
     * word  单词
     * state 对错 1 0
     */
    let storageData =
      JSON.parse(localStorage.getItem("PrivateWordRecords")) || [];
    for (let i = 0; i < data.length; i++) {
      let isExist = false;
      for (let j = 0; j < storageData.length; j++) {
        if (storageData[j].word == data[i].word) {
          isExist = true;
          if (data[i].state == 1) {
            storageData[j].right = storageData[j].right + 1;
          } else {
            storageData[j].wrong = storageData[j].wrong + 1;
          }
          break;
        }
      }
      if (!isExist) {
        let obj = {
          word: data[i].word,
          right: 0,
          wrong: 0,
        };
        if (data[i].state == 1) {
          obj.right = obj.right + 1;
        } else {
          obj.wrong = obj.wrong + 1;
        }
        storageData.push(obj);
      }
    }

    storageData.sort((a, b) => {
      // 不区分大小写比较
      const wordA = a.word.toLowerCase();
      const wordB = b.word.toLowerCase();

      if (wordA < wordB) {
        return -1;
      }
      if (wordA > wordB) {
        return 1;
      }
      return 0;
    });

    console.log("本地单词记录数据", storageData);
    localStorage.setItem("PrivateWordRecords", JSON.stringify(storageData));
  }
}
