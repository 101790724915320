import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import test from "../../ApublicStyle/TestStyle";
import ApublicColorFun from "../../ApublicStyle/TestColorFun";
import ReturnPHCom from "../../Component/returnPHCom";
import dare from "../../ApublicStyle/ChallengeStyle";
import storage from "../../Component/CustomStorage";

var deviceHeight = Dimensions.get("window").height;
var deviceWidth = Dimensions.get("window").width;

export default class W_U_Study_T extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle:
        this.props.navigation.state.params.menuName + " - 单词学习",
      grade: this.props.navigation.state.params.grade,
      parentId: this.props.navigation.state.params.parentId,
      StepKey: "Step1",
      answerList: [],
      questionOptions: [],
      isSwitch: false, // fasle: 英  true: 美
      quesIndex: 0,
      maxIndex: 0,
      pageState: 0,
      // 提交数据之后
      testQuestion: [],
      rightNum: "--",
      SubmitState: false,
      isSubmit: false,
      end: false,
      // 重新测试 ---- 仅做错词
      errorWords: [],
      // 数据
      MaxGroup: 0, // 最高闯关序
      LatestGroupNum: 1,
      GroupNumList: [],
      Words: [],
      StepRecored: [],
      StorageUnit: "",
      LoadState: true,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "gFlashCard/extracurricularStudyTest");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.initData();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }
  /*********************************************************************** 处理单词 ************************************************************************/

  initData = () => {
    let Storage = JSON.parse(localStorage.getItem("WordImprovement"));
    if (Storage == null) {
      this.getData();
      return;
    }
    let noyExist = true;
    for (let i = 0; i < Storage.length; i++) {
      if (
        this.state.parentId == Storage[i].BookId &&
        this.state.grade == Storage[i].UnitId
      ) {
        // console.log(Storage[i]);
        noyExist = false;
        let arr = [];
        for (let j = 0; j < Storage[i].MaxGroupNum; j++) {
          arr.push(j + 1);
        }
        this.setState(
          {
            LatestGroupNum: Storage[i].LatestGroupNum,
            GroupNumList: arr,
            MaxGroup: Storage[i].MaxGroup || 1,
            Words: Storage[i].wordArr[Storage[i].LatestGroupNum - 1],
            StepRecored: Storage[i].StepRecored[Storage[i].LatestGroupNum - 1],
            StorageUnit: Storage[i],
            LoadState: false,
          },
          () => {
            this.initQuestion();
          }
        );
      }
    }
    // 本地存储不存在本书本单元
    if (noyExist) {
      this.getData();
      return;
    }
  };

  getData = () => {
    let info = {
      word: "",
      parentId: this.state.parentId,
      changdu: "",
      grade: this.state.grade,
    };
    let api = "";
    if (
      this.props.navigation.state.params.item &&
      this.props.navigation.state.params.item.type == "heigh"
    ) {
      api = "81010";
    } else {
      api = "81020";
    }
    HttpUtil.post(api, info).then((response) => {
      console.log(api, response);
      if (response.Code == 1) {
        let data = response.Data;
        this.handleStorageWord(data);
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  handleStorageWord = (data) => {
    let Storage = JSON.parse(localStorage.getItem("WordImprovement")) || [];
    let DataMap = data.map((item) => {
      return {
        word: item.word,
        meaning: item.meaning,
      };
    });
    // 判断取成几组单词
    let GroupEach = 10;
    let addNum = data.length % GroupEach > 6 ? 1 : 0;
    let GroupNum = Math.floor(data.length / GroupEach) + addNum;
    console.log("----------", "分成[ " + GroupNum + " ]组");
    // 每一组的单词 未随机数量
    let wordArr = [];
    if (GroupNum > 1) {
      for (let i = 0; i < GroupNum; i++) {
        let GroupEach2 =
          GroupNum == i + 1 ? data.length - GroupEach * i : GroupEach;
        let arr = DataMap.slice(i * GroupEach, i * GroupEach + GroupEach2);
        wordArr.push(arr);
      }
    } else {
      wordArr = [DataMap];
    }
    console.log("----------", "单词分组", wordArr);
    // 初始化 每一组单词的学习记录
    let record = [];
    for (let i = 0; i < GroupNum; i++) {
      record.push({
        Step1: [],
      });
    }
    console.log("----------", "初始化 每一组单词的学习记录", record);
    // 处理存储对象
    let obj = {
      BookId: this.state.parentId,
      UnitId: this.state.grade,
      LatestGroupNum: 1,
      MaxGroup: 1,
      MaxGroupNum: GroupNum,
      wordArr: wordArr,
      StepRecored: record,
    };
    console.log("----------", "初始化 存储值", obj);
    // 最终的存储数据
    Storage.push(obj);
    console.log("----------", "存储数据", Storage);
    // State 赋值
    let GroupNumList = [];
    for (let i = 0; i < GroupNum; i++) {
      GroupNumList.push(i + 1);
    }
    this.setState(
      {
        LatestGroupNum: 1,
        MaxGroup: 1,
        GroupNumList: GroupNumList,
        Words: wordArr[0],
        StepRecored: record[0],
        StorageUnit: obj,
        LoadState: false,
      },
      () => {
        this.initQuestion();
        localStorage.setItem("WordImprovement", JSON.stringify(Storage));
      }
    );
  };

  changeGroup = (item) => {
    if (item > this.state.MaxGroup) {
      ToastShort("您需完成上一组学习才可继续进行哦~");
      return;
    } else if (item == this.state.LatestGroupNum) {
      return;
    } else {
      this.setState(
        {
          LatestGroupNum: item,
          Words: this.state.StorageUnit.wordArr[item - 1],
          quesIndex: 0,
          maxIndex: 0,
          pageState: 0,
          testQuestion: [],
          rightNum: "--",
          SubmitState: false,
          isSubmit: false,
          end: false,
        },
        () => {
          this.initQuestion();
        }
      );
    }
  };

  nextGroup = () => {
    let next = this.state.LatestGroupNum + 1;
    this.changeGroup(next);
  };
  /*********************************************************************** 处理题目 ************************************************************************/
  initQuestion = () => {
    /**使用本地存储单词 */
    let storageData = JSON.parse(localStorage.getItem("WordImprovement"));
    let correctAnswerList = [];
    for (let i = 0; i < storageData.length; i++) {
      if (
        storageData[i].UnitId == this.state.grade &&
        storageData[i].BookId == this.state.parentId
      ) {
        correctAnswerList = this.shuffleArray(
          storageData[i].wordArr[this.state.LatestGroupNum - 1]
        );
      }
    }
    correctAnswerList.forEach((i) => {
      let num = Math.floor(Math.random() * 2);
      i.color = "";
      i.type = num;
    });
    let Questions = [];
    for (let i = 0; i < correctAnswerList.length; i++) {
      let IndexArray = this.getRandomArray(i, correctAnswerList.length);
      let QuestionItem = IndexArray.map((item) => correctAnswerList[item]);
      Questions.push(QuestionItem);
    }
    // console.log("Questions", Questions);
    // console.log("correctAnswerList", correctAnswerList);
    this.setState({
      answerList: correctAnswerList,
      questionOptions: Questions,
      pageState: 1,
    });
  };

  initQuestionErr = () => {
    if (this.state.errorWords.length == 0) {
      this.initQuestion();
      return;
    }
    let correctAnswerList = [];
    correctAnswerList = this.shuffleArray(this.state.errorWords);
    correctAnswerList.forEach((i) => {
      let num = Math.floor(Math.random() * 2);
      i.color = "";
      i.type = num;
    });
    let Questions = [];
    for (let i = 0; i < correctAnswerList.length; i++) {
      for (let j = 0; j < this.state.Words.length; j++) {
        if (correctAnswerList[i].word == this.state.Words[j].word) {
          let IndexArray = this.getRandomArray(j, this.state.Words.length);
          let QuestionItem = IndexArray.map((item) => this.state.Words[item]);
          Questions.push(QuestionItem);
        }
      }
    }
    console.log("QuestionsErr", Questions);
    console.log("correctAnswerListErr", correctAnswerList);
    this.setState({
      answerList: correctAnswerList,
      questionOptions: Questions,
      pageState: 1,
    });
  };

  // Fisher-Yates洗牌算法 打乱一个数组
  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // 随机数 数组【用于构造选项】
  getRandomArray = (i, max) => {
    let resultArray = [i];
    while (resultArray.length < 4) {
      const randomNum = this.getRandomInt(i, max);
      resultArray.push(randomNum);
      resultArray = [...new Set(resultArray)];
    }
    return this.shuffleArray(resultArray);
  };

  // 随机数 不等于 i
  getRandomInt = (i, max) => {
    let num;
    do {
      num = Math.floor(Math.random() * max);
    } while (num === i);
    return num;
  };
  /*************************************************************************** 交互 ***************************************************************************/

  /*************************************************************************** 逻辑 ***************************************************************************/
  // 答题 选择
  doQues = (item, index) => {
    let obj = this.state.answerList[this.state.quesIndex];
    let Arr = [];
    let arr = [];
    Arr = Arr.concat(this.state.questionOptions);
    arr = arr.concat(Arr[this.state.quesIndex]);
    if (obj.myAnswer != undefined) {
      return;
      // 选择之后 禁止修改
    }
    let myAnswer = item.word;
    obj.myAnswer = myAnswer;
    arr.forEach((i) => {
      if (
        (i.word == obj.word && obj.myAnswer == obj.word) ||
        i.word == obj.word
      ) {
        // 正确
        i.color = 1;
      } else if (obj.myAnswer != obj.word && obj.myAnswer == i.word) {
        // 错误
        i.color = 2;
      } else {
        i.color = "";
      }
    });
    let newAnwer = JSON.parse(JSON.stringify(this.state.answerList));
    this.setState({
      answerList: newAnwer,
      questionOptions: Arr,
    });
    if (
      obj.myAnswer == obj.word &&
      this.state.maxIndex + 1 < this.state.answerList.length
    ) {
      setTimeout(
        () => {
          this.doNext();
        },
        localStorage.getItem("delayTime")
          ? localStorage.getItem("delayTime") * 1000
          : 500
      );
    }
    if (
      obj.myAnswer &&
      this.state.maxIndex + 1 == this.state.answerList.length
    ) {
      this.setState({
        end: true,
      });
    }
  };

  // 答题正确 对号
  doCorrect = (item, index) => {
    let doState = this.state.answerList[this.state.quesIndex].myAnswer;
    // let myAnswer = String.fromCharCode(64 + parseInt(1 + index));
    let myAnswer = item.word;
    let correctAnswer = this.state.answerList[this.state.quesIndex].word;
    if (doState != undefined && myAnswer == correctAnswer) {
      return true;
    }
    return false;
  };

  // 答题错误 叉号
  doErro = (item, index) => {
    let doState = this.state.answerList[this.state.quesIndex].myAnswer;
    // let myAnswer = String.fromCharCode(64 + parseInt(1 + index));
    let myAnswer = item.word;
    let correctAnswer = this.state.answerList[this.state.quesIndex].word;
    if (
      doState != undefined &&
      doState != correctAnswer &&
      myAnswer == doState
    ) {
      return true;
    }
    return false;
  };

  // 下一题
  doNext = () => {
    if (this.state.end) {
      this.submit();
      return;
    }
    let num = this.state.maxIndex + 1;
    this.setState({
      quesIndex: num,
      maxIndex: num,
    });
  };

  // 下一轮
  nextRound = () => {
    // this.props.navigation.pop();
    this.setState(
      {
        answerList: [],
        questionOptions: [],
        quesIndex: 0,
        maxIndex: 0,
        pageState: 0,
        testQuestion: [],
        rightNum: "--",
        SubmitState: false,
        isSubmit: false,
        end: false,
      },
      () => {
        this.initQuestionErr();
      }
    );
  };

  submit = () => {
    // console.log(this.state.answerList);
    if (this.state.SubmitState) {
      console.log("禁止重复提交");
      return;
    }
    let links = [];
    let errorWord = [];
    let score = 0;
    for (let i = 0; i < this.state.answerList.length; i++) {
      let item = this.state.answerList[i];
      let isCorrect = item.word == item.myAnswer ? "1" : "0";
      let questions = this.state.questionOptions[i];
      let Arr = [];
      questions.forEach((it) => {
        Arr.push({
          word: it.word,
          Meaning: it.meaning,
        });
      });
      links.push({
        word: item.word,
        Meaning: item.meaning,
        myAnswer: item.myAnswer,
        isCorrect: isCorrect,
        questions: Arr,
      });
      if (isCorrect == "1") {
        score++;
      } else {
        errorWord.push({
          word: item.word,
          meaning: item.meaning,
          Meaning: item.meaning,
        });
      }
    }
    console.log("errorWord", errorWord);
    console.log("score", score);
    if (errorWord.length > 0) {
      console.log("暂不加入 错词本");
      // this.AddErrWords(errorWord);
    }
    this.setState(
      {
        testQuestion: links,
        errorWords: errorWord,
        rightNum: score,
        SubmitState: true,
        pageState: 2,
      },
      () => {
        this.handleStorage();
        this.handlePrivateWordRecords();
      }
    );
  };

  AddErrWords = (words) => {
    let wrongWord = words.map((item) => {
      return {
        Word: item.word,
        Meaning: item.Meaning,
      };
    });
    let info = {
      ClassId: config.classMsg.ClassId,
      BookId: this.state.BookId,
      RecordId: "",
      UnitId: this.state.UnitId,
      StudentId: config.user.ID,
      TestType: 5,
      Ts: config.user.Ts,

      links: JSON.stringify(wrongWord),
    };
    HttpUtil.post("1119_1", info).then((response) => {
      if (response.Code == 1) {
        console.log("加入错词本成功");
      }
    });
  };

  A_S_Click = (i) => {
    if (i > this.state.maxIndex) {
      return;
    }
    this.setState({
      quesIndex: i,
    });
  };

  _returnBtn = (score) => {
    // 1 下一组 2 返回 3 无
    if (score == 100) {
      if (this.state.LatestGroupNum == this.state.GroupNumList.length) {
        return 2;
      }
      return 1;
    }
    return 0;
  };
  /*************************************************************************** 跳转 ***************************************************************************/

  back = () => {
    this.props.navigation.pop();
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.back();
    }
  }

  // 存储记录
  handleStorage = () => {
    let storageData = JSON.parse(localStorage.getItem("WordImprovement"));
    let score = Math.round(
      (Number(this.state.rightNum) / this.state.answerList.length) * 100
    );
    let MaxGroup =
      score == 100 ? this.state.LatestGroupNum + 1 : this.state.LatestGroupNum;
    for (let i = 0; i < storageData.length; i++) {
      if (
        storageData[i].UnitId == this.state.grade &&
        storageData[i].BookId == this.state.parentId
      ) {
        /**最新分组序 + 1 */
        let LatestGroup = this.state.LatestGroupNum;
        if (score == 100) {
          LatestGroup =
            this.state.LatestGroupNum + 1 > storageData[i].MaxGroupNum
              ? storageData[i].MaxGroupNum
              : this.state.LatestGroupNum + 1;
        }
        console.log("最新分组序", LatestGroup);
        /**结束 */
        /**最高分组序 随选中的最新分组变小（最高只能大不能小）*/
        MaxGroup =
          MaxGroup < storageData[i].MaxGroup
            ? storageData[i].MaxGroup
            : MaxGroup;
        /**结束 */
        storageData[i].StepRecored[this.state.LatestGroupNum - 1][
          this.state.StepKey
        ].unshift(score);
        storageData[i].LatestGroupNum = LatestGroup;
        storageData[i].MaxGroup = MaxGroup;
        this.setState({
          MaxGroup: MaxGroup,
        });
      }
    }
    localStorage.setItem("WordImprovement", JSON.stringify(storageData));
  };

  handlePrivateWordRecords = () => {
    let newData = this.state.testQuestion.map((item) => {
      return {
        word: item.word.trim(),
        state: item.isCorrect == "1" ? 1 : 0,
      };
    });
    storage.PrivateWordRecords(newData);
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  _renderNext = () => {
    return (
      <View style={styles.NextBtn}>
        {/* 进度 */}
        <View
          style={{
            height: 40,
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Text style={{ fontSize: 12 }}>{"练习进度"}</Text>
          <Text style={test.schedule_text}>
            {this.state.answerList && this.state.answerList.length > 0
              ? this.state.quesIndex + 1 + "/" + this.state.answerList.length
              : "-- / --"}
          </Text>
        </View>
        {/* 切换题目 */}
        {(this.state.answerList &&
          this.state.answerList.length > 0 &&
          this.state.answerList[this.state.maxIndex].myAnswer != undefined &&
          this.state.answerList[this.state.maxIndex].myAnswer !=
            this.state.answerList[this.state.maxIndex].word) ||
        this.state.end ? (
          <TouchableOpacity
            onPress={() => {
              this.doNext();
            }}
            style={test.btn_noDisabled}
          >
            <Text style={{ fontSize: 15, color: "#fff" }}>
              {this.state.maxIndex + 1 == this.state.answerList.length
                ? "提 交"
                : "下一题"}
            </Text>
          </TouchableOpacity>
        ) : (
          <View style={test.btn_disabled}>
            <Text style={{ fontSize: 15, color: "#fff" }}>
              {this.state.maxIndex + 1 == this.state.answerList.length
                ? "提 交"
                : "下一题"}
            </Text>
          </View>
        )}
      </View>
    );
  };

  stemCom = () => {
    let data = this.state.answerList[this.state.quesIndex];
    return (
      data &&
      data != "" && (
        <View style={test.stem_container}>
          {/* 题目 */}
          <View style={[test.stem_space, styles.RowFlex]}>
            {data.type == 0 && (
              <Text style={test.stem_question}>{data.meaning}</Text>
            )}
            {data.type == 1 && (
              <Text style={test.stem_question_E}>{data.word}</Text>
            )}
          </View>
        </View>
      )
    );
  };

  optionCom = () => {
    let data = this.state.questionOptions[this.state.quesIndex];
    let answer = this.state.answerList[this.state.quesIndex];
    return (
      <View style={test.container_space}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  this.doQues(item, index);
                }}
                key={index}
                style={[
                  test.option_box,
                  {
                    backgroundColor: ApublicColorFun.Option(
                      "backgroundColor",
                      answer.myAnswer,
                      answer.word,
                      item.word
                    ),
                    borderColor: ApublicColorFun.Option(
                      "borderColor",
                      answer.myAnswer,
                      answer.word,
                      item.word
                    ),
                  },
                ]}
              >
                <View style={test.option_container}>
                  <Text style={[test.option_index, { display: "flex" }]}>
                    {String.fromCharCode(64 + parseInt(1 + index)) + ". "}
                    {(this.doErro(item, index) ||
                      this.doCorrect(item, index)) &&
                      this.wordInfo(item)}

                    {answer.type == 0 &&
                      !this.doErro(item, index) &&
                      !this.doCorrect(item, index) && (
                        <Text style={test.option_value_C}>{item.word}</Text>
                      )}
                    {answer.type == 1 &&
                      !this.doErro(item, index) &&
                      !this.doCorrect(item, index) && (
                        <Text style={test.option_value_C}>{item.meaning}</Text>
                      )}
                  </Text>
                  {this.doCorrect(item, index) && (
                    <Image
                      source={require("../../images/word/correct.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                  {this.doErro(item, index) && (
                    <Image
                      source={require("../../images/word/erro.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
      </View>
    );
  };

  wordInfo = (item) => {
    return (
      <View style={{ flexDirection: "column", width: "95%", marginLeft: 5 }}>
        <Text style={test.option_value_E}>{item.word}</Text>
        <Text style={test.option_value_mean}>{item.meaning}</Text>
      </View>
    );
  };

  fullLoading = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size={"large"} color={"#666"} animating={true} />

        <Text style={{ color: "#666", fontSize: 18, marginTop: 15 }}>
          正在加载中
        </Text>
      </View>
    );
  };

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          {/* 作答情况 */}
          <View
            style={{
              margin: 20,
              height: 58,
              borderRadius: 10,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}
              >
                {this.state.rightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                正确
              </Text>
            </View>
            <View
              style={{
                width: "34%",
                height: "70%",
                borderLeftWidth: 1,
                borderColor: "#E5E5E5",
                borderRightWidth: 1,
              }}
            >
              <Text
                style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}
              >
                {this.state.rightNum == "--"
                  ? this.state.testQuestion.length
                  : this.state.testQuestion.length - this.state.rightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                错误
              </Text>
            </View>
            <View
              style={{
                width: "33%",
                height: "70%",
              }}
            >
              <Text
                style={{ fontSize: 18, color: "#333", textAlign: "center" }}
              >
                {this.state.testQuestion.length}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                总数
              </Text>
            </View>
          </View>
          {this.state.testQuestion.map((item, index) => {
            let ques = item.questions;
            return (
              <View
                key={index}
                style={{
                  justifyContent: "center",
                  alignItems: "flex-start",
                  paddingHorizontal: 20,
                  marginBottom: 20,
                }}
              >
                <Text style={[test.option_index, { marginBottom: 10 }]}>
                  {index + 1 + ". " + item.word}
                </Text>
                {ques.map((q, key) => {
                  return (
                    <View key={key} style={{ marginBottom: 5 }}>
                      <Text
                        style={[
                          test.option_index,
                          {
                            color: ApublicColorFun.OptionRecord(
                              q.word,
                              item.myAnswer,
                              item.word
                            ),
                          },
                        ]}
                      >
                        {String.fromCharCode(64 + parseInt(1 + key)) + ". "}
                        <Text
                          style={[
                            test.option_value_E,
                            {
                              color: ApublicColorFun.OptionRecord(
                                q.word,
                                item.myAnswer,
                                item.word
                              ),
                            },
                          ]}
                        >
                          {q.word + "  "}
                        </Text>
                        <Text
                          style={[
                            test.option_value_mean,
                            {
                              color: ApublicColorFun.OptionRecord(
                                q.word,
                                item.myAnswer,
                                item.word
                              ),
                            },
                          ]}
                        >
                          {q.Meaning}
                        </Text>
                      </Text>
                    </View>
                  );
                })}
              </View>
            );
          })}
        </ScrollView>
        {this._renderNextTest()}
      </View>
    );
  };

  _renderNextTest = () => {
    let score = Math.round(
      (Number(this.state.rightNum) / this.state.answerList.length) * 100
    );
    return (
      <View style={styles.NextBtn}>
        {/* 下一轮  */}
        <TouchableOpacity
          onPress={() => {
            this.nextRound();
          }}
          style={{
            height: 40,
            width: score == 100 ? (deviceWidth - 60) / 2 : "100%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>
            {score == 100 ? "重新练习" : "错题修正"}
          </Text>
        </TouchableOpacity>
        {this._returnBtn(score) == 1 && (
          <TouchableOpacity
            onPress={() => this.nextGroup()}
            style={{
              height: 40,
              width: (deviceWidth - 60) / 2,
              borderRadius: 20,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#1c76fc",
            }}
          >
            <Text style={{ fontSize: 15, color: "#fff" }}>{"下一组"}</Text>
          </TouchableOpacity>
        )}
        {this._returnBtn(score) == 2 && (
          <TouchableOpacity
            onPress={() => this.back()}
            style={{
              height: 40,
              width: (deviceWidth - 60) / 2,
              borderRadius: 20,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#1c76fc",
            }}
          >
            <Text style={{ fontSize: 15, color: "#fff" }}>{"返回"}</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  AnswerSheetCom = () => {
    return (
      <View style={test.A_S_container}>
        {this.state.answerList.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[
                test.A_S_Btn_Sty,
                {
                  backgroundColor: ApublicColorFun.A_S_Color(
                    index,
                    this.state.quesIndex,
                    this.state.maxIndex,
                    this.state.answerList[index].myAnswer,
                    this.state.answerList[index].word
                  ),
                  marginLeft: index != 0 && test.A_S_Space,
                },
              ]}
              onPress={() => this.A_S_Click(index)}
            >
              <Text style={test.A_S_Btn_Font_Sty}>{index + 1}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  GroupCom = () => {
    return (
      <View style={styles.GroupContainer}>
        {this.state.GroupNumList.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={dare.Group_Btn}
              onPress={() => this.changeGroup(item)}
            >
              <Text
                style={[
                  dare.Group_Btn_Font,
                  {
                    backgroundColor: ApublicColorFun.Group_Style(
                      "backgroundColor",
                      this.state.LatestGroupNum,
                      this.state.MaxGroup,
                      item
                    ),
                    color: ApublicColorFun.Group_Style(
                      "color",
                      this.state.LatestGroupNum,
                      this.state.MaxGroup,
                      item
                    ),
                    fontWeight: ApublicColorFun.Group_Style(
                      "fontWeight",
                      this.state.LatestGroupNum,
                      this.state.MaxGroup,
                      item
                    ),
                  },
                ]}
              >
                {item}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  /*************************************************************************** render ***************************************************************************/

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#fff",
          }}
        >
          {this._renderHeader()}
          {this.state.pageState == 1 && (
            <ScrollView
              style={test.scroll_container}
              showsVerticalScrollIndicator={false}
            >
              {!this.state.LoadState && this.state.GroupNumList.length > 0 && (
                <View
                  style={{
                    width: "100%",
                    paddingVertical: 15,
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottomWidth: 1,
                    borderBottomColor: "#eaeaea",
                    marginBottom: 20,
                    backgroundColor: "#eaeaea",
                  }}
                >
                  <Text style={{ fontWeight: "bold", fontSize: 15 }}>
                    分组学习：
                  </Text>
                  {this.GroupCom()}
                </View>
              )}
              {/* 答题卡 */}
              {this.AnswerSheetCom()}
              {/* 题干 */}
              {this.stemCom()}
              {/* 选项 */}
              {this.optionCom()}
            </ScrollView>
          )}
          {this.state.pageState == 1 && this._renderNext()}
          {this.state.pageState == 0 && this.fullLoading()}
          {this.state.pageState == 2 && this.testRecord()}
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  NextBtn: {
    paddingHorizontal: 20,
    height: 60,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  RowFlex: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  GroupContainer: {
    // marginTop: 10,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "center",
  },
});
