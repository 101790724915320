import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../../Component/BaseView";
import config from "../../../Component/Config";
import { FlatList } from "react-native-gesture-handler";
import { ToastShort, consolePage } from "../../../Component/ToastUtlis";
import HttpUtil from "../../../Component/HttpUtil";
import ReturnPHCom from "../../../Component/returnPHCom";
import moment from "moment";
import * as _ from "lodash";
import Empty from "../../../Component/Empty";
let screenW = Dimensions.get("window").width;

export default class KP_SentenceTestRecord extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.handleClickDebounce = _.debounce(this._onLoadMore, 500, {
      maxWait: 1000,
    });
    this.state = {
      documentTitle: "语句练习记录",
      recordList: [],
      isLoadMore: true,
      typeNametypeName: "",
      currentOn: "2",
      typeName: "语句补充",
      info: this.props.navigation.state.params,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.getRecord();
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("KP_SentenceTestRecord", "语句练习记录");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getRecord = () => {
    let param = {
      dictID: this.state.info.dictID,
      ClassId: config.classMsg.ClassId,
      StudentId: config.user.ID,
      pageStart: this.page,
      pageSize: 20,
      Type: this.state.currentOn,
    };
    HttpUtil.post("626366_1", param).then((response) => {
      console.info("获取测试记录", response.Data);
      if (response.Code == 1) {
        this.setState({
          recordList:
            this.page != 0
              ? this.state.recordList.concat(response.Data)
              : response.Data,
          isLoadMore: response.Data.length != 20 ? false : true,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  _onLoadMore = () => {
    this.page = this.page + 1;
    this.getRecord();
  };
  /*************************************************************************** 交互 ***************************************************************************/

  /*************************************************************************** 跳转 ***************************************************************************/
  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  jumpTestDetail = (data) => {
    this.props.navigation.navigate("KP_SentenceTestRecordDetail", {
      item: data,
      typeName: this.state.typeName,
      type: this.state.currentOn,
    });
  };

  // 选择某类型
  chooseQuetype = (item, name) => {
    this.setState(
      {
        currentOn: item,
        typeName: name,
      },
      () => {
        this.getRecord();
      }
    );
  };

  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  _renderHeader2() {
    return (
      <View>
        <View
          style={{
            width: "100%",
            height: 42,
            display: "flex",
            flexDirection: "row",
            borderBottomColor: "#CCCCCC",
            borderBottomWidth: 1,
          }}
        >
          <View style={{ width: "10%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"序"}
            </Text>
          </View>
          <View style={{ width: "40%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"练习时间"}
            </Text>
          </View>
          <View style={{ width: "26%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"正确 / 总数"}
            </Text>
          </View>
          <View style={{ width: "24%", justifyContent: "center" }}>
            <Text
              allowFontScaling={true}
              style={{
                fontSize: 13,
                color: "#333333",
                textAlign: "center",
                top: 11,
              }}
            >
              {"正确率"}
            </Text>
          </View>
        </View>
      </View>
    );
  }

  // 加载更多
  _ListFooterComponent = () => {
    if (this.state.isLoadMore == false) {
      return this.state.recordList.length == 0 ? <Empty /> : <View />;
    } else {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 40,
            marginVertical: 10,
          }}
        >
          <ActivityIndicator size={"small"} color={"#666"} animating={true} />
          <Text
            style={{
              lineHeight: 40,
              height: "100%",
              color: "#666",
              marginLeft: 10,
            }}
          >
            正在加载更多
          </Text>
        </View>
      );
    }
  };

  _renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        style={styles.signinListSty2}
        onPress={() => this.jumpTestDetail(item)}
      >
        <View style={{ width: "10%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {index + 1}
          </Text>
        </View>
        <View style={{ width: "40%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {moment(item.CreateTime).format("YYYY-MM-DD HH:mm")}
          </Text>
        </View>
        <View style={{ width: "26%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {item.RightNum} / {item.AllNum}
          </Text>
        </View>
        <View style={{ width: "24%" }}>
          <Text
            allowFontScaling={true}
            style={{
              fontSize: 13,
              color: "#333333",
              textAlign: "center",
              top: 22,
            }}
          >
            {((item.RightNum / item.AllNum) * 100).toFixed(0)}%
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}
          <View
            style={{
              width: "100%",
              height: 60,
              backgroundColor: "#f5f5f5",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: "#fff",
                borderRadius: 5,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {this.state.currentOn == "2" ? (
                <TouchableOpacity style={{ flex: 1, marginTop: 12 }}>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#1c76fc",
                      textAlign: "center",
                    }}
                  >
                    语句补充
                  </Text>
                  <View
                    style={{
                      width: "50%",
                      height: 2,
                      backgroundColor: "#1c76fc",
                      borderRadius: 5,
                      marginLeft: "25%",
                      marginTop: 6,
                    }}
                  ></View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => this.chooseQuetype("2", "记录详情")}
                  style={{ flex: 1, marginTop: 12 }}
                >
                  <Text
                    style={{ fontSize: 13, color: "#666", textAlign: "center" }}
                  >
                    语句补充
                  </Text>
                </TouchableOpacity>
              )}
              {this.state.currentOn == "3" ? (
                <TouchableOpacity style={{ flex: 1, marginTop: 12 }}>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#1c76fc",
                      textAlign: "center",
                    }}
                  >
                    语句组装
                  </Text>
                  <View
                    style={{
                      width: "50%",
                      height: 2,
                      backgroundColor: "#1c76fc",
                      borderRadius: 5,
                      marginLeft: "25%",
                      marginTop: 6,
                    }}
                  ></View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => this.chooseQuetype("3", "记录详情")}
                  style={{ flex: 1, marginTop: 12 }}
                >
                  <Text
                    style={{ fontSize: 13, color: "#666", textAlign: "center" }}
                  >
                    语句组装
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={{ flex: 1, backgroundColor: "#fff" }}>
            <FlatList
              data={this.state.recordList}
              extraData={this.state}
              showsVerticalScrollIndicator={false}
              keyExtractor={this._keyExtractor}
              renderItem={this._renderItem}
              ListFooterComponent={this._ListFooterComponent}
              ListHeaderComponent={this._renderHeader2}
              onEndReached={() => {
                if (this.state.isLoadMore) {
                  this.handleClickDebounce();
                }
              }}
              onEndReachedThreshold={0.3}
            />
          </View>
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  bottom: {
    width: "100%",
    height: 45,
    backgroundColor: "#fff",
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  signinListSty2: {
    width: "100%",
    height: 45,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
  },
});
