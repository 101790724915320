import { Dimensions } from "react-native";

var deviceWidth = Dimensions.get("window").width;

let A_S_Num = 10;
let A_S_Space = 10;
let A_S_Btn = (deviceWidth - 40 - A_S_Space * (A_S_Num - 1)) / A_S_Num;

if (deviceWidth > 400) {
  let default_w = 30;
  A_S_Num = Math.ceil((deviceWidth - 40 - A_S_Space) / (A_S_Space + default_w));
  A_S_Btn = (deviceWidth - 40 - A_S_Space * (A_S_Num - 1)) / A_S_Num;
}

const test = {
  scroll_container: {
    flex: 1,
    backgroundColor: "#f8f8f8",
    paddingBottom: 20,
  },
  stem_container: {
    paddingHorizontal: 20,
    paddingBottom: 25,
  },
  stem_container_2: {
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 5,
    marginBottom: 5,
  },
  row_center_worp: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  A_S_container: {
    paddingHorizontal: 20,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  A_S_container_2: {
    marginHorizontal: 20,
    paddingBottom: 10,
  },
  A_S_Num: A_S_Num,
  A_S_Space: A_S_Space,
  A_S_Btn_Sty: {
    width: A_S_Btn,
    height: A_S_Btn,
    borderRadius: A_S_Btn / 2,
    marginTop: 10,
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
  },
  A_S_Btn_Font_Sty: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
  },
  stem_row: {
    flexDirection: "row",
    marginTop: 15,
  },
  stem_type: {
    fontSize: 12,
    paddingHorizontal: 8,
    paddingVertical: 3,
    color: "#1c76fc",
    backgroundColor: "#fff",
    borderRadius: 5,
  },
  stem_space: {
    alignItems: "center",
    marginTop: 20,
  },
  stem_space_2: {
    alignItems: "fles-start",
    marginTop: 20,
  },
  stem_prompt: {
    color: "#666",
    fontSize: 16,
  },
  stem_question: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    color: "#000",
  },
  stem_question_E: {
    textAlign: "center",
    fontSize: 32,
    fontWeight: "bold",
    color: "#000",
  },
  container_space: {
    paddingHorizontal: 20,
  },
  option_box: {
    marginBottom: 10,
    borderRadius: 5,
    borderWidth: 1,
  },
  option_container: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  option_index: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 24,
    height: "100%",
  },
  option_index2: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 24,
  },
  option_value_E: {
    fontSize: 16,
    fontWeight: "normal",
  },
  option_value_C: {
    fontSize: 14,
    fontWeight: "normal",
  },
  option_value_mean: {
    fontSize: 12,
    color: "#666",
    fontWeight: "normal",
    width: "100%",
  },
  schedule_text: {
    fontSize: 20,
    color: "#1c76fc",
    fontWeight: "bold",
  },
  btn_disabled: {
    height: 40,
    width: 100,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a7abaf",
  },
  btn_noDisabled: {
    height: 40,
    width: 100,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1c76fc",
  },
  spell_E: {
    width: deviceWidth - 40,
    fontSize: 34,
    paddingTop: 3,
    paddingBottom: 3,
    outlineWidth: 0,
    marginBottom: 15,
    textAlign: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  spell_E_Img_Box: {
    maxWidth: deviceWidth - 40,
    flexDirection: "row",
    alignItems: "flex-end",
  },
  spell_E_Img_Sty: {
    width: 28,
    height: 28,
    marginLeft: 10,
    marginBottom: 25,
  },
  spell_E_Img_Sty2: {
    width: 28,
    height: 28,
    marginLeft: 5,
    marginTop: 5,
  },
  spell_E_Item: {
    fontSize: 34,
    paddingTop: 3,
    paddingBottom: 3,
    outlineWidth: 0,
    marginBottom: 15,
    textAlign: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  spell_S: {
    fontWeight: "bold",
    fontSize: 34,
  },
  spell_S_B: {
    width: "100%",
    height: 3,
    borderRadius: 3,
    backgroundColor: "#1c76fc",
    marginTop: 3,
  },
  OriginalSty: {
    lineHeight: 32,
    fontSize: 18,
    color: "#333",
    fontFamily: "Open Sans",
  },
  OriginalSty2: {
    lineHeight: 24,
    fontSize: 16,
    color: "#333",
  },
  Selection_Position: {
    width: "100%",
    backgroundColor: "#f8f8f8",
    paddingHorizontal: 20,
    paddingBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  Selection_Btn_Sty: {
    margin: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
    borderWidth: 1,
  },
  prompt_text: {
    fontSize: 13,
    color: "#003179",
    textAlign: "right",
    lineHeight: 24,
    marginTop: 10,
  },
  stem_Sentence_Select_item: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
    color: "#000",
    lineHeight: 25,
    marginRight: 5,
    paddingBottom: 5,
  },
  sentence_R_Img: {
    width: 25,
    height: 25,
  },
};
export default test;
