import { createAppContainer } from "react-navigation";
import { createStackNavigator } from "react-navigation-stack";

import Tab from "./tab";
import ViewPager from "./viewpager";
import Login from "./login/login";
import ResigiterSelf from "./login/resigiterSelf";

/** 考研英语 */

// 分类单词
import classifyWordHome from "./PostEnglish/Words/classifyWordHome";
import classifyWordDetail from "./PostEnglish/Words/classifyWordDetail";
// 内容资源
import InternetWordsHome from "./PostEnglish/Words/InternetWordsHome";
import InternetWordsDetail from "./PostEnglish/Words/InternetWordsDetail";

// 分类词组
import classifyPhraseHome from "./PostEnglish/Phrase/classifyPhraseHome";
import classifyPhraseDetail from "./PostEnglish/Phrase/classifyPhraseDetail";
// 词组资源
import phraseLook2 from "./PostEnglish/Phrase/phraseLook2";

// 分类例句
import classifySentenceHome from "./PostEnglish/Sentence/classifySentenceHome";
import classifySentenceDetail from "./PostEnglish/Sentence/classifySentenceDetail";
// 词组例句
import PhraseExampleSentenceHome from "./PostEnglish/Sentence/PhraseExampleSentenceHome";
import PhraseExampleSentenceDetail from "./PostEnglish/Sentence/PhraseExampleSentenceDetail";
// 例句资源
import ExampleSentenceHome from "./PostEnglish/Sentence/ExampleSentenceHome";
import ExampleSentenceDetail from "./PostEnglish/Sentence/ExampleSentenceDetail";

// 语法资源
import syntaxHome from "./PostEnglish/Grammar/syntaxHome";
import syntaxTagList from "./PostEnglish/Grammar/syntaxTagList";
import syntaxDetail from "./PostEnglish/Grammar/syntaxDetail";

// 知识点
import KP_Home from "./PostEnglish/KnowledgePoints/KP_Home";
import KP_ContentIntegration from "./PostEnglish/KnowledgePoints/KP_ContentIntegration";
// 知识点测试记录
import KP_TestRecord from "./PostEnglish/KnowledgePoints/KP_TestRecord";
// 知识点单词测试
import KP_WordsTest from "./PostEnglish/KnowledgePoints/TestWords/KP_WordsTest";
import KP_WordsTestDetail from "./PostEnglish/KnowledgePoints/TestWords/KP_WordsTestDetail";
import KP_WordsRecordDetail from "./PostEnglish/KnowledgePoints/TestWords/KP_WordsRecordDetail";
// 知识点句子测试
import KP_SentenceTest from "./PostEnglish/KnowledgePoints/TestSentence/KP_SentenceTest";
import KP_SentenceTestDetail_Ass from "./PostEnglish/KnowledgePoints/TestSentence/KP_SentenceTestDetail_Ass";
import KP_SentenceTestDetail_Sup from "./PostEnglish/KnowledgePoints/TestSentence/KP_SentenceTestDetail_Sup";
import KP_SentenceTestRecord from "./PostEnglish/KnowledgePoints/TestSentence/KP_SentenceTestRecord";
import KP_SentenceTestRecordDetail from "./PostEnglish/KnowledgePoints/TestSentence/KP_SentenceTestRecordDetail";
// 知识点相似词辨析测试
import KP_SA_SentenceTest from "./PostEnglish/KnowledgePoints/TestSentence/KP_SA_SentenceTest";
import KP_SA_SentenceTestDetail from "./PostEnglish/KnowledgePoints/TestSentence/KP_SA_SentenceTestDetail";
import KP_SA_SentenceTestRecord from "./PostEnglish/KnowledgePoints/TestSentence/KP_SA_SentenceTestRecord";
// 知识点语法测试
import KP_GrammarTest from "./PostEnglish/KnowledgePoints/TestGrammar/KP_GrammarTest";
import KP_GrammarTestDetail from "./PostEnglish/KnowledgePoints/TestGrammar/KP_GrammarTestDetail";
import KP_GrammarRecordDetail from "./PostEnglish/KnowledgePoints/TestGrammar/KP_GrammarRecordDetail";

// 单词提升
import W_U_Home from "./PostEnglish/WordsUpUp/W_U_Home";
import W_U_Detail_Home from "./PostEnglish/WordsUpUp/W_U_Detail_Home";
import W_U_Browsing from "./PostEnglish/WordsUpUp/W_U_Browsing";
import W_U_Study_T from "./PostEnglish/WordsUpUp/W_U_Study_T";
import W_U_GoGo from "./PostEnglish/WordsUpUp/W_U_GoGo";
import W_U_Record from "./PostEnglish/WordsUpUp/W_U_Record";
import W_U_RecordDetail from "./PostEnglish/WordsUpUp/W_U_RecordDetail";

// 研词闯关
import Post_CL_Home from "./PostEnglish/ChallengeLevels/PostCLHome";
import Post_CL_Browsing from "./PostEnglish/ChallengeLevels/Post_CL_Browsing";
import Post_CL_Detail from "./PostEnglish/ChallengeLevels/Post_CL_Detail";
import Post_CL_Record from "./PostEnglish/ChallengeLevels/Post_CL_Record";
import Post_CL_RecordDetail from "./PostEnglish/ChallengeLevels/Post_CL_RecordDetail";

var StackNavigates = createStackNavigator(
  {
    Login: { screen: Login },
    ResigiterSelf: { screen: ResigiterSelf },
    Tab: {
      screen: Tab,
      navigationOptions: {
        header: null,
      },
    },
    ViewPager: { screen: ViewPager },

    /** 考研英语 */
    // 分类单词
    classifyWordHome: { screen: classifyWordHome },
    classifyWordDetail: { screen: classifyWordDetail },
    // 内容资源
    InternetWordsHome: { screen: InternetWordsHome },
    InternetWordsDetail: { screen: InternetWordsDetail },
    // 分类词组
    classifyPhraseHome: { screen: classifyPhraseHome },
    classifyPhraseDetail: { screen: classifyPhraseDetail },
    // 词组资源
    phraseLook2: { screen: phraseLook2 },
    // 分类例句
    classifySentenceHome: { screen: classifySentenceHome },
    classifySentenceDetail: { screen: classifySentenceDetail },
    // 词组例句
    PhraseExampleSentenceHome: { screen: PhraseExampleSentenceHome },
    PhraseExampleSentenceDetail: { screen: PhraseExampleSentenceDetail },
    // 例句资源
    ExampleSentenceHome: { screen: ExampleSentenceHome },
    ExampleSentenceDetail: { screen: ExampleSentenceDetail },
    // 语法资源
    syntaxHome: { screen: syntaxHome },
    syntaxTagList: { screen: syntaxTagList },
    syntaxDetail: { screen: syntaxDetail },
    // 知识点
    KP_Home: { screen: KP_Home },
    KP_ContentIntegration: { screen: KP_ContentIntegration },
    // 知识点测试记录
    KP_TestRecord: { screen: KP_TestRecord },
    // 知识点单词测试
    KP_WordsTest: { screen: KP_WordsTest },
    KP_WordsTestDetail: { screen: KP_WordsTestDetail },
    KP_WordsRecordDetail: { screen: KP_WordsRecordDetail },
    // 知识点句子测试
    KP_SentenceTest: { screen: KP_SentenceTest },
    KP_SentenceTestDetail_Ass: { screen: KP_SentenceTestDetail_Ass },
    KP_SentenceTestDetail_Sup: { screen: KP_SentenceTestDetail_Sup },
    KP_SentenceTestRecord: { screen: KP_SentenceTestRecord },
    KP_SentenceTestRecordDetail: { screen: KP_SentenceTestRecordDetail },
    // 知识点相似词辨析测试
    KP_SA_SentenceTest: { screen: KP_SA_SentenceTest },
    KP_SA_SentenceTestDetail: { screen: KP_SA_SentenceTestDetail },
    KP_SA_SentenceTestRecord: { screen: KP_SA_SentenceTestRecord },
    // 知识点语法测试
    KP_GrammarTest: { screen: KP_GrammarTest },
    KP_GrammarTestDetail: { screen: KP_GrammarTestDetail },
    KP_GrammarRecordDetail: { screen: KP_GrammarRecordDetail },
    // 单词提升
    W_U_Home: { screen: W_U_Home },
    W_U_Detail_Home: { screen: W_U_Detail_Home },
    W_U_Browsing: { screen: W_U_Browsing },
    W_U_Study_T: { screen: W_U_Study_T },
    W_U_GoGo: { screen: W_U_GoGo },
    W_U_Record: { screen: W_U_Record },
    W_U_RecordDetail: { screen: W_U_RecordDetail },
    // 研词闯关
    Post_CL_Home: { screen: Post_CL_Home },
    Post_CL_Browsing: { screen: Post_CL_Browsing },
    Post_CL_Detail: { screen: Post_CL_Detail },
    Post_CL_Record: { screen: Post_CL_Record },
    Post_CL_RecordDetail: { screen: Post_CL_RecordDetail },
  },
  {
    initialRouteName: "ViewPager",
  }
);
export default createAppContainer(StackNavigates);
