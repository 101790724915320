import React from "react";
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  Dimensions,
  ScrollView,
} from "react-native";
import BaseView from "../../../Component/BaseView";
import color from "../../../Component/Color";
import { ToastShort, consolePage } from "../../../Component/ToastUtlis";
import HttpUtil from "../../../Component/HttpUtil";
import ReturnPHCom from "../../../Component/returnPHCom";
import { Record } from "./RecordContent";

const Dwidth = Dimensions.get("window").width;
const Lheight = Dimensions.get("window").height - 50;

export default class knowledgeWordPracticeRecordDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.state = {
      info: "",
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    if (this.props.navigation.state.params.item) {
      let obj = this.props.navigation.state.params;
      obj.item.SubmitList = JSON.parse(obj.item.SubmitAnswer);
      this.setState({
        info: obj.item,
        type: obj.TestType,
        typeName: obj.typeName,
      });
    }

    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "KP_WordsRecordDetail");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  // 获取详情/进度
  getData = () => {
    let param = {
      ID: this.props.navigation.state.params.item.ID,
    };
    HttpUtil.post("1128", param).then((res) => {
      console.log(res.Data);
      if (res.Code == 1) {
        this.setState({
          info: res.Data,
        });
      } else {
        ToastShort(res.Msg);
      }
    });
  };

  _renderHeader() {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight,
          backgroundColor: "#fff",
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text style={{ fontSize: 18, color: "#333", lineHeight: 40 }}>
          {this.state.typeName}
        </Text>
      </View>
    );
  }

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="#fff"
          translucent={true}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: color.background,
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}
          <View style={{ flex: 1, backgroundColor: "#fff" }}>
            <ScrollView
              style={{ flex: 1 }}
              showsVerticalScrollIndicator={false}
            >
              {this.state.info.SubmitList &&
                Record(
                  this.state.info.RightNum,
                  this.state.info.AllNum,
                  this.state.info.SubmitList
                )}
            </ScrollView>
          </View>
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flex_box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingTop: 30,
  },
  box_item: {
    width: "45%",
    height: "30%",
    margin: 5,
    marginTop: 10,
    textAlign: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#f4f1f4",
    backgroundColor: "#f4f1f4",
    borderRadius: 5,
  },
  item_detail: {
    height: "100%",
    borderRadius: 5,
    textAlign: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    flex: 1,
    backgroundColor: "#fff",
  },
});
