import { Audio } from 'expo-av';

class AudioManager {
  constructor() {
    this.sound = null;
  }

  async playAudio(audioUrl) {
    if (!this.sound) {
      this.sound = new Audio.Sound();
      await this.sound.loadAsync({ uri: audioUrl });
    } else {
      await this.sound.unloadAsync();
      await this.sound.loadAsync({ uri: audioUrl });
    }

    await this.sound.playAsync();
  }

  async stopAudio() {
    if (this.sound) {
      await this.sound.stopAsync();
    }
  }
}

export default new AudioManager();