import React from "react";
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  Dimensions,
  ScrollView,
} from "react-native";
import BaseView from "../../../Component/BaseView";
import color from "../../../Component/Color";
import { consolePage } from "../../../Component/ToastUtlis";
import ReturnPHCom from "../../../Component/returnPHCom";
import test from "../../../ApublicStyle/TestStyle";
import ApublicColorFun from "../../../ApublicStyle/TestColorFun";

const Dwidth = Dimensions.get("window").width;
const Lheight = Dimensions.get("window").height - 50;

export default class KP_SentenceTestRecordDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.state = {
      info: "",
      type: "",
      typeName: "",
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    if (this.props.navigation.state.params.item) {
      let obj = this.props.navigation.state.params;
      obj.item.SubmitList = JSON.parse(obj.item.SubmitAnswer);
      this.setState({
        info: obj.item,
        type: obj.type,
        typeName: obj.typeName,
      });
    }
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage(
        "刷新标志",
        "knowledgeWord/knowledgeSentencePracticeRecordDetail"
      );
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  _renderHeader() {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight,
          backgroundColor: "#fff",
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text style={{ fontSize: 18, color: "#333", lineHeight: 40 }}>
          {this.state.typeName}
        </Text>
      </View>
    );
  }

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          {/* 作答情况 */}
          <View
            style={{
              margin: 20,
              height: 58,
              borderRadius: 10,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}
              >
                {this.state.info.RightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                正确
              </Text>
            </View>
            <View
              style={{
                width: "34%",
                height: "70%",
                borderLeftWidth: 1,
                borderColor: "#E5E5E5",
                borderRightWidth: 1,
              }}
            >
              <Text
                style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}
              >
                {this.state.info.AllNum
                  ? this.state.info.AllNum - this.state.info.RightNum
                  : ""}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                错误
              </Text>
            </View>
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ fontSize: 18, color: "#333", textAlign: "center" }}
              >
                {this.state.info.AllNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                总数
              </Text>
            </View>
          </View>
          {this.state.info.SubmitList &&
            this.state.info.SubmitList.length > 0 &&
            this.state.info.SubmitList.map((item, index) => {
              let ques = item.options;
              return (
                <View
                  key={index}
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth:
                      this.state.info.SubmitList.length - 1 == index ? 0 : 1,
                    borderBottomColor: "#e5e5e5",
                  }}
                >
                  <Text style={[test.option_index, { marginBottom: 10 }]}>
                    {index + 1 + ". " + item.question}
                  </Text>
                  {ques &&
                    ques.length > 0 &&
                    ques.map((i, key) => {
                      return (
                        <View
                          key={key}
                          style={{ width: "100%", marginBottom: 5 }}
                        >
                          <Text
                            style={[
                              test.option_index,
                              {
                                color: ApublicColorFun.OptionRecord(
                                  i.word,
                                  item.PersonalAnswer,
                                  item.CorrectAnswer
                                ),
                              },
                            ]}
                          >
                            {String.fromCharCode(64 + parseInt(1 + key)) + ". "}
                            <View
                              style={{ width: "90%", flexDirection: "column" }}
                            >
                              <Text
                                style={[
                                  test.option_value_E,
                                  {
                                    color: ApublicColorFun.OptionRecord(
                                      i.word,
                                      item.PersonalAnswer,
                                      item.CorrectAnswer
                                    ),
                                  },
                                ]}
                              >
                                {i.word}
                              </Text>
                            </View>
                          </Text>
                        </View>
                      );
                    })}
                  <View style={{ width: "100%", textAlign: "left" }}>
                    <Text
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        lineHeight: 25,
                        color: "#333",
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>解析：</Text>
                      {item.Meaning ? item.Meaning : "暂无"}
                    </Text>
                  </View>
                </View>
              );
            })}
        </ScrollView>
      </View>
    );
  };

  testRecord2 = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          {/* 作答情况 */}
          <View
            style={{
              margin: 20,
              height: 58,
              borderRadius: 10,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}
              >
                {this.state.info.RightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                正确
              </Text>
            </View>
            <View
              style={{
                width: "34%",
                height: "70%",
                borderLeftWidth: 1,
                borderColor: "#E5E5E5",
                borderRightWidth: 1,
              }}
            >
              <Text
                style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}
              >
                {this.state.info.AllNum
                  ? this.state.info.AllNum - this.state.info.RightNum
                  : ""}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                错误
              </Text>
            </View>
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ fontSize: 18, color: "#333", textAlign: "center" }}
              >
                {this.state.info.AllNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                总数
              </Text>
            </View>
          </View>
          {this.state.info.SubmitList &&
            this.state.info.SubmitList.length > 0 &&
            this.state.info.SubmitList.map((item, index) => {
              return (
                <View
                  key={index}
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth:
                      this.state.info.SubmitList.length - 1 == index ? 0 : 1,
                    borderBottomColor: "#e5e5e5",
                  }}
                >
                  <Text style={[test.option_index, { marginBottom: 10 }]}>
                    {index + 1 + ". " + item.sentence}
                  </Text>
                  <View>
                    <Text style={{ color: "#909399", marginBottom: 10 }}>
                      我的答案：
                      <Text
                        style={{
                          color: item.isCorrect == "1" ? "#05c08b" : "#F56C6C",
                        }}
                      >
                        {item.myAnswer}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ width: "100%", textAlign: "left" }}>
                    <Text
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        lineHeight: 25,
                        color: "#333",
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>翻译：</Text>
                      {item.senMeaning ? item.senMeaning : "暂无"}
                    </Text>
                  </View>
                </View>
              );
            })}
        </ScrollView>
      </View>
    );
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="#fff"
          translucent={true}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: color.background,
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}
          {this.state.type && this.state.type == "2" && this.testRecord()}
          {this.state.type && this.state.type == "3" && this.testRecord2()}
          <ReturnPHCom
            source={{ curPage: "extracrr" }}
            _setReturnName={this._setReturnName.bind(this)}
          ></ReturnPHCom>
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flex_box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingTop: 30,
  },
  box_item: {
    width: "45%",
    height: "30%",
    margin: 5,
    marginTop: 10,
    textAlign: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#f4f1f4",
    backgroundColor: "#f4f1f4",
    borderRadius: 5,
  },
  item_detail: {
    height: "100%",
    borderRadius: 5,
    textAlign: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    flex: 1,
    backgroundColor: "#fff",
  },
});
