import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  DeviceEventEmitter,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Alert,
  Platform,
  Linking,
  ImageBackground,
  NativeModules,
  ScrollView,
  Modal,
  Image,
  Dimensions,
  ActivityIndicator,
  TextInput,
} from "react-native";
import BaseView from "../Component/BaseView";
import config from "../Component/Config";
import { FlatList } from "react-native-gesture-handler";
import { ToastShort, consolePage } from "../Component/ToastUtlis";
import HttpUtil from "../Component/HttpUtil";
import Color from "../Component/Color";
import ReturnPHCom from "../Component/returnPHCom";
import moment from "moment";
import Modal2, {
  ModalTitle,
  ModalFooter,
  ModalButton,
  ModalContent,
  ScaleAnimation,
  SlideAnimation,
  FadeAnimation,
} from "react-native-modals";
let screenW = Dimensions.get("window").width;

export default class resigiterSelf extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nickname: "",
      account: "",
      modalVisible2: false,
      type: 2,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("login/resigiterSelf", "自主注册");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/

  /*************************************************************************** 交互 ***************************************************************************/
  setNickName = (text) => {
    this.setState({
      nickname: text,
    });
  };

  setAccount = (text) => {
    this.setState({
      account: text,
    });
  };

  register = () => {
    if (this.state.account == "") {
      ToastShort("请输入账号！");
      return;
    }
    if (this.state.nickname == "") {
      ToastShort("请输入昵称！");
      return;
    }
    this.setState({
      modalVisible2: true,
    });
  };

  close2 = () => {
    this.setState({
      modalVisible2: false,
    });
  };

  confirm = () => {
    this.setState({
      modalVisible2: false,
    });
    // 50985
    let info = {
      userName: this.state.nickname,
      login: this.state.account,
      pwd: "123",
      TS: this.state.type,
    };
    HttpUtil.post("50985_1", info).then((response) => {
      console.log("50985_1", response);
      if (response.Code == 1) {
        this.login();
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  login = () => {
    var info = {
      login: this.state.account,
      pwd: "123",
    };
    console.info("登录信息", info);
    HttpUtil.post("40008", info).then((response) => {
      console.info("个人信息response", response);
      if (response.Code == 1) {
        if (response.token) {
          storage.save({
            key: "userToken",
            data: response.token,
          });
          storage.save({
            key: "user",
            data: response.Data,
          });
          config.userToken = response.token;
          config.user = response.Data;
          // this.GetInfo();
          if (
            response.Data.wxId != null &&
            response.Data.wxId != undefined &&
            response.Data.wxId != ""
          ) {
            this.props.navigation.navigate("Tab");
          } else {
            this.bindWexin();
          }
        } else {
          storage.save({
            key: "userToken",
            data: "",
          });
          config.userToken = "";
        }
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  bindWexin = () => {
    var appid = "wx7f217e7eeca63426";
    var local = "http://math.math110.com/?type=wxBind";
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
      local
    )}&response_type=code&scope=snsapi_base#wechat_redirect`;
  };

  changeItem = (type) => {
    this.setState({
      type: type,
    });
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  /*************************************************************************** 组件 ***************************************************************************/

  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          <ImageBackground
            style={{ width: "100%", height: "100%" }}
            source={require("../images/login/bg2.jpeg")}
          >
            <View
              style={{
                width: "100%",
                height: 60,
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45%",
              }}
            >
              <Text
                style={{ color: "#1c76fc", fontSize: 31, fontWeight: "bold" }}
              >
                学习帮
              </Text>
            </View>
            <View
              style={{
                marginTop: 15,
                width: "78%",
                height: 40,
                marginLeft: "12%",
                flexDirection: "row",
                alignItems: "center",
                borderBottomColor: "gray",
                borderBottomWidth: 1,
              }}
            >
              <Image
                source={require("../images/login/username.png")}
                style={{ width: 20, height: 20 }}
              />
              <View
                style={{
                  flex: 1,
                  height: 40,
                  justifyContent: "center",
                  marginLeft: 15,
                }}
              >
                <TextInput
                  style={styles.password}
                  value={this.state.account}
                  placeholder="请输入账号"
                  placeholderTextColor={"gray"}
                  selectionColor={"gray"}
                  keyboardType="default"
                  underlineColorAndroid="transparent"
                  secureTextEntry={false}
                  clearButtonMode="while-editing"
                  onChangeText={(text) => this.setAccount(text)}
                />
              </View>
            </View>
            <View
              style={{
                marginTop: 20,
                width: "78%",
                height: 40,
                marginLeft: "12%",
                flexDirection: "row",
                alignItems: "center",
                borderBottomColor: "gray",
                borderBottomWidth: 1,
              }}
            >
              <Image
                source={require("../images/login/nickname.png")}
                style={{ width: 20, height: 20 }}
              />
              <View
                style={{
                  flex: 1,
                  height: 40,
                  justifyContent: "center",
                  marginLeft: 15,
                }}
              >
                <TextInput
                  value={this.state.nickname}
                  style={styles.password}
                  placeholder="请输入昵称"
                  placeholderTextColor={"gray"}
                  selectionColor={"gray"}
                  keyboardType="default"
                  underlineColorAndroid="transparent"
                  secureTextEntry={false}
                  clearButtonMode="while-editing"
                  onChangeText={(text) => this.setNickName(text)}
                />
              </View>
            </View>
            {/* <View
              style={{
                marginTop: 20,
                width: "78%",
                height: 40,
                marginLeft: "12%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TouchableOpacity onPress={() => this.changeItem(2)} style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
                {this.state.type == 2 ? (<Image
                  source={require('../images/public/checked.png')}
                  style={{ width: 20, height: 20 }}
                />) : (<Image
                  source={require('../images/public/nocheck.png')}
                  style={{ width: 20, height: 20 }}
                />)}
                <Text allowFontScaling={false} style={{ fontSize: 15, color: '#808080', marginLeft: 13, fontWeight: 'bold' }}>学生</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.changeItem(1)} style={{ display: 'flex', flexDirection: 'row' }}>
                {this.state.type == 1 ? (<Image
                  source={require('../images/public/checked.png')}
                  style={{ width: 20, height: 20 }}
                />) : (<Image
                  source={require('../images/public/nocheck.png')}
                  style={{ width: 20, height: 20 }}
                />)}
                <Text allowFontScaling={false} style={{ fontSize: 15, color: '#808080', marginLeft: 13, fontWeight: 'bold' }}>家长</Text>
              </TouchableOpacity>
            </View> */}
            <View
              style={{
                width: "90%",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 60,
                marginLeft: "6%",
              }}
            >
              <TouchableOpacity
                onPress={() => this.register()}
                style={{
                  width: "90%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#1c76fc",
                  borderRadius: 20,
                }}
              >
                <Text style={{ color: "white", fontSize: 16 }}>
                  {"注 册 并 登 录"}
                </Text>
              </TouchableOpacity>
            </View>
          </ImageBackground>
        </View>
        <Modal2
          style={{ position: "relative" }}
          modalAnimation={
            new ScaleAnimation({
              initialValue: 0, // optional
              useNativeDriver: true, // optional
            })
          }
          transparent={true}
          modalTitle={
            <ModalTitle
              textStyle={{ color: "#333", fontSize: 13 }}
              title="友情提示"
            />
          }
          footer={
            <ModalFooter>
              <ModalButton
                text="取 消"
                disabled={false}
                textStyle={{ color: "#333333", fontSize: 14 }}
                onPress={() => this.close2()}
              />
              <ModalButton
                text="确 定"
                disabled={false}
                textStyle={{ color: "#1c76fc", fontSize: 14 }}
                onPress={() => this.confirm()}
              />
            </ModalFooter>
          }
          visible={this.state.modalVisible2}
          onTouchOutside={() => {
            this.setState({ modalVisible2: false });
          }}
          onRequestClose={() => this.close2()}
        >
          <ModalContent>
            <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
              <Text style={{ fontSize: 14, color: "#999", lineHeight: 22 }}>
                注：您的初始密码是
                <Text style={{ color: "#1c76fc" }}>{" 123 "}</Text>！
              </Text>
            </View>
          </ModalContent>
        </Modal2>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  password: {
    width: "100%",
    height: 40,
    outlineWidth: 0,
  },
});
