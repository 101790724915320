import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../../Component/BaseView";
import config from "../../../Component/Config";
import { ToastShort, consolePage } from "../../../Component/ToastUtlis";
import HttpUtil from "../../../Component/HttpUtil";
import test from "../../../ApublicStyle/TestStyle";
import ApublicColorFun from "../../../ApublicStyle/TestColorFun";
import ReturnPHCom from "../../../Component/returnPHCom";
import storage from "../../../Component/CustomStorage";
import Loading from "../../../Component/Loading";
import { Record } from "./RecordContent";

export default class KP_WordsTestDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "单词练习",
      classId: "",
      courseId: "",
      unitId: "",
      unitName: "",
      list: [],
      quesIndex: 0,
      pageState: 0,
      isSwitch: false, // fasle: 英  true: 美
      isSwitch2: false, // fasle: 关闭  true: 开启
      modalVisible: false,
      modalVisible2: false,
      isClick: false,
      isClick2: false,
      typeName: "美",
      // 提交数据之后
      testQuestion: [],
      rightNum: "--",
      isSubmit: false,
      end: false,
      loading: false,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "KP_WordsTestDetail");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getQuestions();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  getQuestions = () => {
    let info = {
      ID: this.props.navigation.state.params.dictID,
    };
    HttpUtil.post("626466_1 ", info).then((res) => {
      let data = res.Data;
      if (data && data.length == 0) {
        this.setState({
          pageState: 1,
        });
        return;
      }
      data.forEach((i) => {
        i.questions = JSON.parse(i.choice);
        let num = Math.floor(Math.random() * 2);
        i.questions.forEach((j) => {
          j.color = "";
          j.type = num;
        });
        // 0: 看英选中 1：看中选英
        i.type = num;
      });
      console.log("获取题目", data);
      this.setState({
        list: data,
        pageState: 1,
      });
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/

  /*************************************************************************** 逻辑 ***************************************************************************/
  // 答题 选择
  doQues = (item, index) => {
    let obj = this.state.list[this.state.quesIndex];
    if (obj.myAnswer != "" && obj.myAnswer != null) {
      // 选择之后 禁止修改
      return;
    }
    obj.myAnswer = item.word;
    if (
      (item.word == obj.word && obj.myAnswer == obj.word) ||
      item.word == obj.word
    ) {
      // 正确
      item.color = 1;
      obj.isCorrect = "1";
    } else if (obj.myAnswer != obj.word && obj.myAnswer == item.word) {
      // 错误
      item.color = 2;
      obj.isCorrect = "0";
    } else {
      item.color = "";
    }
    let newAnwer = JSON.parse(JSON.stringify(this.state.list));
    this.setState({
      list: newAnwer,
    });
    if (
      obj.myAnswer == obj.word &&
      this.state.quesIndex + 1 < this.state.list.length
    ) {
      setTimeout(
        () => {
          this.doNext();
        },
        localStorage.getItem("delayTime")
          ? localStorage.getItem("delayTime") * 1000
          : 500
      );
    }
    if (
      obj.myAnswer != "" &&
      this.state.quesIndex + 1 == this.state.list.length
    ) {
      this.setState({
        end: true,
      });
    }
  };

  // 下一题
  doNext = () => {
    if (this.state.end) {
      this.submit();
      return;
    }
    let num = this.state.quesIndex + 1;
    this.setState({
      quesIndex: num,
    });
  };

  // 下一轮
  nextRound = () => {
    this.setState(
      {
        pageState: 1,
        quesIndex: 0,
        list: [],
        testQuestion: [],
        rightNum: "--",
        isSubmit: false,
        end: false,
        loading: false,
      },
      () => {
        this.getQuestions();
      }
    );
  };

  // 提交
  submit = () => {
    if (this.state.loading) {
      ToastShort("请勿重复提交");
      return;
    }
    this.setState({
      loading: true,
    });
    let links = [];
    let num = 0;
    links = links.concat(this.state.list);
    links.forEach((item) => {
      if (item.isCorrect == "1") {
        num++;
      }
    });
    this.setState(
      {
        rightNum: num,
        testQuestion: links,
      },
      () => {
        this.handlePrivateWordRecords();
      }
    );
    this.postData(links);
  };

  postData = (links) => {
    let info = {
      UnitId: this.props.navigation.state.params.dictID,
      ClassId: config.classMsg.ClassId,
      StudentName: config.user.userName,
      StudentId: config.user.ID,
      StudentBh: config.user.userBh,
      Ts: config.user.TS,
      TestType: 1,
      submitList: links,
    };
    HttpUtil.post("626467", info)
      .then((res) => {
        console.log(res);
        if (res.Code == 1) {
          ToastShort("提交成功");
          this.setState({
            pageState: 2,
            isSubmit: true,
          });
          // this.props.navigation.pop();
        } else {
          this.setState({
            loading: false,
          });
          ToastShort(res.Msg);
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        console.log(e);
        ToastShort("提交失败");
      });
  };

  handlePrivateWordRecords = () => {
    return;
    let newData = this.state.testQuestion.map((item) => {
      return {
        word: item.word.trim(),
        state: item.isCorrect == "1" ? 1 : 0,
      };
    });
    storage.PrivateWordRecords(newData);
  };
  /*************************************************************************** 跳转 ***************************************************************************/

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  gotoRecord = () => {
    this.props.navigation.navigate(
      "practiceRecord",
      this.props.navigation.state.params
    );
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
      </View>
    );
  };

  _renderNext = () => {
    return (
      <View>
        <View style={styles.NextBtn}>
          {/* 进度 */}
          <View
            style={{
              height: 40,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 12 }}>{"测评进度"}</Text>
            <Text style={test.schedule_text}>
              {this.state.list && this.state.list.length > 0
                ? this.state.quesIndex + 1 + "/" + this.state.list.length
                : "-- / --"}
            </Text>
          </View>
          {/* 切换题目 */}
          {(this.state.list &&
            this.state.list.length > 0 &&
            this.state.list[this.state.quesIndex].myAnswer != null &&
            this.state.list[this.state.quesIndex].myAnswer !=
              this.state.list[this.state.quesIndex].word) ||
          (this.state.end && !this.state.loading) ? (
            <TouchableOpacity
              onPress={() => {
                this.doNext();
              }}
              style={test.btn_noDisabled}
            >
              <Text style={{ fontSize: 15, color: "#fff" }}>
                {this.state.quesIndex + 1 == this.state.list.length
                  ? "提 交"
                  : "下一题"}
              </Text>
            </TouchableOpacity>
          ) : (
            <View style={test.btn_disabled}>
              <Text style={{ fontSize: 15, color: "#fff" }}>
                {this.state.quesIndex + 1 == this.state.list.length
                  ? "提 交"
                  : "下一题"}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  stemCom = () => {
    let data = this.state.list[this.state.quesIndex];
    return (
      data &&
      data != "" && (
        <View
          style={[
            test.stem_container,
            { justifyContent: "center", alignItems: "center" },
          ]}
        >
          {/* 题目 */}
          <View
            style={[
              test.stem_space,
              data.type == 0 || data.type == 1
                ? {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }
                : "",
            ]}
          >
            {(data.type == 0 || data.type == 1) && ( // 看英选中 和 看中选英
              <Text style={test.stem_question}>
                {data.type == 0 ? data.word : data.meaning}
              </Text>
            )}
          </View>
        </View>
      )
    );
  };

  optionCom = () => {
    let data = this.state.list[this.state.quesIndex]?.questions;
    let answer = this.state.list[this.state.quesIndex];
    return (
      <View style={test.container_space}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  this.doQues(item, index);
                }}
                key={index}
                style={[
                  test.option_box,
                  {
                    backgroundColor: ApublicColorFun.Option(
                      "backgroundColor",
                      answer.myAnswer,
                      answer.word,
                      item.word
                    ),
                    borderColor: ApublicColorFun.Option(
                      "borderColor",
                      answer.myAnswer,
                      answer.word,
                      item.word
                    ),
                  },
                ]}
              >
                <View style={test.option_container}>
                  <Text style={[test.option_index, { display: "flex" }]}>
                    {String.fromCharCode(64 + parseInt(1 + index)) + ". "}
                    {(item.color == 1 || item.color == 2) &&
                    item.color != "" ? (
                      this.wordInfo(item)
                    ) : (
                      <Text style={test.option_value_E}>
                        {item.type == 1 || item.type == 2
                          ? item.word
                          : item.meaning}
                      </Text>
                    )}
                  </Text>
                  {item.color != "" && item.color == 1 && (
                    <Image
                      source={require("../../../images/word/correct.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                  {item.color != "" && item.color == 2 && (
                    <Image
                      source={require("../../../images/word/erro.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
      </View>
    );
  };

  wordInfo = (item) => {
    return (
      <View style={{ flexDirection: "column", width: "95%", marginLeft: 5 }}>
        <Text style={test.option_value_E}>{item.word}</Text>
        <Text style={test.option_value_mean}>{item.meaning}</Text>
      </View>
    );
  };

  fullLoading = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size={"large"} color={"#666"} animating={true} />
        <Text style={{ color: "#666", fontSize: 18, marginTop: 15 }}>
          正在加载中
        </Text>
      </View>
    );
  };

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          {Record(
            this.state.rightNum,
            this.state.testQuestion.length,
            this.state.testQuestion
          )}
        </ScrollView>
        {this._renderNextTest()}
      </View>
    );
  };

  _renderNextTest = () => {
    return (
      <View style={styles.NextBtn}>
        {/* 下一轮  */}
        <TouchableOpacity
          onPress={() => {
            this.nextRound();
          }}
          style={{
            height: 40,
            width: "48%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>{"再来一次"}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            this.gotoRecord();
          }}
          style={{
            height: 40,
            width: "48%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>{"查看记录"}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  /*************************************************************************** render ***************************************************************************/

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#fff",
          }}
        >
          {this._renderHeader()}
          {this.state.pageState == 1 && (
            <ScrollView
              style={{ flex: 1, backgroundColor: "#f8f8f8" }}
              showsVerticalScrollIndicator={false}
            >
              {/* 题干 */}
              {this.stemCom()}
              {/* 选项 */}
              {this.optionCom()}
            </ScrollView>
          )}
          {this.state.pageState == 1 && this._renderNext()}
          {this.state.pageState == 0 && <Loading />}
          {this.state.pageState == 2 && this.testRecord()}
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  NextBtn: {
    paddingHorizontal: 20,
    height: 60,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    width: "38%",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 45 + BaseView.currentHeight,
    right: 20,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    backgroundColor: "#f8f8f8",
    paddingBottom: 10,
  },
  btnSty: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
  },
});
