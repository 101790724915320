import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";

import ReturnPHCom from "../../Component/returnPHCom";
import Loading from "../../Component/Loading";
import BottomBlock from "../../AmoduleCollection/BottomBlock";

import BtnTree from "../../AmoduleCollection/ButtonNavigation";

import resource from "../ApublicResourceSty";
export default class PhraseExampleSentenceHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "词组例句",
      type: "MID",
      TypeList: [],
      TypeList1: [],
      pageState: 0,
      defaultProps: {
        Title: "FenLeiName",
        children: "Children",
        childName: "detail",
      },
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("gSyntax/PhraseExampleSentenceHome", "词组例句");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getClassify();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getClassify = () => {
    // 0 返回按字符分类的，1 返回单词数量  2 返回单词长度
    let info = { type: this.state.type };
    HttpUtil.post("54011_4", info).then((response) => {
      console.log("54011_4", response);
      if (response.Code == 1) {
        let data = response.Data;
        for (let i = 0; i < data.length; i++) {
          data[i].FenLeiName = this.returnTitle(data[i].leixing);
        }
        this.setState({
          TypeList: data,
          pageState: 1,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  /*************************************************************************** 交互 ***************************************************************************/
  handleProgressShow = (Array) => {
    if (JSON.parse(localStorage.getItem("StorageProgress")) == null) {
      return Array;
    }
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"))[
      "classifyWord"
    ];
    for (let i = 0; i < Array.length; i++) {
      for (let j = 0; j < storageData.length; j++) {
        if (storageData[j].ID == Array[i].ID) {
          Array[i].FenLeiName2 =
            Array[i].FenLeiName +
            " ( " +
            (storageData[j].page + 1) +
            "/" +
            storageData[j].pageTotal +
            " )";
        }
      }
    }
    return Array;
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };

  gotoTagSyntax = (item, index, leixing) => {
    let query = {
      basis: item.detail,
      type: this.state.type,
      leixing: leixing,
    };
    if (leixing == "0") {
      query.Title = "字符分类 - " + item.detail;
    }
    if (leixing == "1") {
      query.Title = "单词数量 - " + item.detail;
    }
    this.props.navigation.navigate("PhraseExampleSentenceDetail", query);
  };

  returnTitle = (leixing) => {
    if (leixing == "0") {
      return "按字符分类";
    }
    if (leixing == "1") {
      return "按单词数量";
    }
    return "其他";
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}

          {this.state.pageState == 0 && <Loading />}

          {this.state.pageState == 1 && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ flex: 1, backgroundColor: "#fff" }}
            >
              {this.state.TypeList.length > 0 && (
                <BtnTree
                  TreeData={this.state.TypeList}
                  Props={this.state.defaultProps}
                  onClickBtn={this.gotoTagSyntax}
                />
              )}
              <BottomBlock />
            </ScrollView>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
});
