import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  ImageBackground,
  Dimensions,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import pub from "../../Component/PublicSty";

import ReturnPHCom from "../../Component/returnPHCom";
import dare from "../../ApublicStyle/ChallengeStyle";

const Dwidth = Dimensions.get("window").width;
const Lheight = Dimensions.get("window").height - 50;

export default class Post_CL_Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.myRef = React.createRef();
    this.state = {
      isLoadMore: false,
      loading: true,
      list: [],
      passNum: "1",
      passBookId: "",
      courseList: [],
      studyVocNum: 0,
      bookID: "a6031bc7d5044f62bb0375dd3d52b9f2",
      data: "",
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "Post_CL_Home");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getData();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  // 获取详情/进度
  getData = () => {
    let param = {
      ClassId: config.classMsg.ClassId,
      ts: config.user.TS,
      UserId: config.user.ID,
    };
    HttpUtil.post("1107", param).then((res) => {
      if (res.Code == 1) {
        console.log("1107 获取详情/进度", res.Data);
        this.setState(
          {
            passNum: String(res.Data.PassNumber),
            passBookId: this.state.bookID,
            data: res.Data,
          },
          () => {
            this.getNum();
          }
        );
      } else {
        ToastShort(res.Msg);
      }
    });
  };

  getNum = () => {
    let arr = [];
    let passNum = Number(this.state.passNum);
    let param = {
      BookId: this.state.bookID,
      classId: config.classMsg.ClassId,
      studentId: config.user.ID,
    };
    HttpUtil.post("1120_2", param).then((res) => {
      if (res.Code == 1) {
        console.log(res.Data);
        for (let i = 0; i < res.Data[0].grade; i++) {
          arr.push({
            name: String(i + 1),
            isClearance:
              i + 1 == passNum &&
              (this.state.data.PassState == 2 ||
                this.state.data.PassState == "")
                ? 1
                : i + 1 == passNum && this.state.data.PassState == 1
                ? 3
                : i + 1 < passNum ||
                  (i + 1 == passNum && this.state.data.PassState == 3)
                ? 2
                : 0,
            // 1: 当前 2：已过 0：未通
          });
        }
        var tempStudyNum = "";
        if (res.Data && res.Data.length > 0) {
          tempStudyNum = res.Data[0].allNum;
        }
        this.setState({
          num: res.Data[0].grade,
          list: arr,
          loading: false,
          studyVocNum: tempStudyNum,
        });
      } else {
        ToastShort(res.Msg);
      }
    });
  };

  // 详情
  gotoDetail = (item) => {
    console.log(item);
    if (item.isClearance == 0) {
      return;
    }
    this.props.navigation.navigate("Post_CL_Detail", {
      ID: this.state.passBookId,
      PassNumber: this.state.passNum,
      PassState: this.state.data.PassState,
      currentNumber: item.name,
      totalNum: this.state.num,
    });
  };

  goRecord = () => {
    this.props.navigation.navigate("Post_CL_Record", {
      item: { name: "闯关", type: 0 },
    });
  };

  goBrowse = () => {
    let query = {
      grade: this.state.passNum,
      total: this.state.list.length,
    };
    this.props.navigation.navigate("Post_CL_Browsing", query);
  };

  fullLoading = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size={"large"} color={"#666"} animating={true} />
        <Text style={{ color: "#666", fontSize: 18, marginTop: 15 }}>
          正在加载中
        </Text>
      </View>
    );
  };

  _renderHeader() {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => this.goRecord()}
          style={{
            width: 40,
            heigh: 40,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight + 10,
            right: 10,
          }}
        >
          <Text style={{ fontSize: 14, color: "#1c76fc" }}>记录</Text>
        </TouchableOpacity>
      </View>
    );
  }

  _renderItem = (item, index) => {
    return (
      <TouchableOpacity
        onPress={() => this.gotoDetail(item)}
        key={index}
        style={[
          dare.Btn_Sty,
          {
            backgroundColor:
              item.isClearance == 3
                ? "#ed9b19"
                : item.isClearance == 2
                ? "#05e944"
                : item.isClearance == 1
                ? "#1c76fc"
                : "#909399",
            marginLeft: index % dare.Row_Num != 0 && dare.Margin_Space,
          },
        ]}
      >
        <Text style={dare.Btn_Font_Sty}>{item.name}</Text>

        {/* <Text
          style={{
            fontSize: 18,
            color:
              item.isClearance == 1 || item.isClearance == 2 ? "#fff" : "#333",
            fontWeight: 600,
          }}
        >
          {item.name}
        </Text> */}
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="#fff"
          translucent={true}
          barStyle={"dark-content"}
        />
        {/* backgroundColor: '#6495ED', background: 'linear-gradient(to bottom, #B0C4DE, #6495ED)' */}
        <ImageBackground
          imageStyle={styles.backImg}
          source={require("../../images/class/studyBackground01.png")}
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            position: "relative",
          }}
        >
          {this.state.loading && this.fullLoading()}
          {!this.state.loading && (
            <ScrollView
              style={{ flex: 1 }}
              showsVerticalScrollIndicator={false}
            >
              <View style={styles.studyRecordCon}>
                <Text
                  style={[
                    styles.studyRecordTxt,
                    { marginBottom: 30, fontSize: 20 },
                  ]}
                >
                  考研大纲词闯关
                </Text>
                <Text style={styles.studyRecordTxt}>
                  您当前已掌握词汇量：{this.state.studyVocNum}
                </Text>
              </View>
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <View
                  style={{
                    paddingVertical: 10,
                    paddingHorizontal: 20,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.list &&
                    this.state.list.length > 0 &&
                    this.state.list.map((item, index) => {
                      return this._renderItem(item, index);
                    })}
                </View>
              </View>
              <View style={styles.studyCon}>
                <TouchableOpacity
                  onPress={() => this.goBrowse()}
                  style={[
                    pub.btn_sty,
                    { backgroundColor: "rgb(255 255 255 / 46%)" },
                  ]}
                >
                  <Text style={[pub.btn_text_sty]}>单词浏览</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.goRecord()}
                  style={[
                    pub.btn_sty,
                    { backgroundColor: "rgb(255 255 255 / 46%)" },
                  ]}
                >
                  <Text style={[pub.btn_text_sty]}>闯关记录</Text>
                </TouchableOpacity>
              </View>
              <Text style={pub.note_txt_sty}>
                <View style={{ width: "100%" }}>
                  <Text style={pub.note_prompt_txt_sty}>提示：</Text>
                </View>
                闯关单词为中考大纲词汇，每关随机抽取10个单词，正确率等于100%时可挑战下一关。（绿色代表已通关，灰色代表未通关，蓝色代表当前闯关关卡，橙色代表关卡已做但未通过。）你是最棒的！用你的智慧和勇气，去征服每一个关卡吧！
              </Text>
            </ScrollView>
          )}
          <ReturnPHCom
            source={{ curPage: "extracrr" }}
            _setReturnName={this._setReturnName.bind(this)}
          ></ReturnPHCom>
        </ImageBackground>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flex_box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingTop: 30,
  },
  container: {
    width: "100%",
    flex: 1,
    backgroundColor: "#fff",
  },
  right_box: {
    width: (Dwidth - 40) / 10,
    height: (Dwidth - 40) / 10,
    // width: 35,
    // height: 35,
    borderRadius: "50%",
    position: "relative",
    // margin: 7,
    marginBottom: 10,
  },
  box_item: {
    // width: (Dwidth - 199) / 5,
    // height: (Dwidth - 199) / 5,
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    position: "absolute",
    bottom: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  backImg: {
    height: "100%",
    width: "100%",
  },
  studyRecordCon: {
    width: "100%",
    marginTop: "20%",
    paddingHorizontal: 20,
    marginVertical: 10,
  },
  studyRecordTxt: {
    width: "100%",
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 20,
  },
  studyCon: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    marginTop: 10,
  },
});
