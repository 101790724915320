import React from "react";
import { Text, View, Dimensions } from "react-native";
import test from "../../../ApublicStyle/TestStyle";
import ApublicColorFun from "../../../ApublicStyle/TestColorFun";

var deviceWidth = Dimensions.get("window").width;

export function Record(RightNum, TotalNum, Links) {
  return (
    <View>
      {/* 作答情况 */}
      <View
        style={{
          margin: 20,
          height: 58,
          borderRadius: 10,
          borderColor: "#E5E5E5",
          borderWidth: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View style={{ width: "33%", height: "70%" }}>
          <Text style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}>
            {RightNum}
          </Text>
          <Text
            style={{
              color: "#999",
              fontSize: 10,
              textAlign: "center",
              marginTop: 3,
            }}
          >
            正确
          </Text>
        </View>
        <View
          style={{
            width: "34%",
            height: "70%",
            borderLeftWidth: 1,
            borderColor: "#E5E5E5",
            borderRightWidth: 1,
          }}
        >
          <Text style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}>
            {TotalNum - RightNum}
          </Text>
          <Text
            style={{
              color: "#999",
              fontSize: 10,
              textAlign: "center",
              marginTop: 3,
            }}
          >
            错误
          </Text>
        </View>
        <View
          style={{
            width: "33%",
            height: "70%",
          }}
        >
          <Text style={{ fontSize: 18, color: "#333", textAlign: "center" }}>
            {TotalNum}
          </Text>
          <Text
            style={{
              color: "#999",
              fontSize: 10,
              textAlign: "center",
              marginTop: 3,
            }}
          >
            总数
          </Text>
        </View>
      </View>
      {Links.map((item, index) => {
        let ques = item.questions;
        return (
          <View
            key={index}
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
              paddingHorizontal: 20,
              marginBottom: 20,
            }}
          >
            <Text style={[test.option_index, { marginBottom: 10 }]}>
              {index + 1 + ". "}
              {item.meaning || item.Meaning}
            </Text>
            {ques.map((q, key) => {
              return (
                <View
                  key={key}
                  style={{
                    marginBottom: 5,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      test.option_index,
                      {
                        color: ApublicColorFun.OptionRecord(
                          q.word,
                          item.myAnswer,
                          item.word
                        ),
                      },
                    ]}
                  >
                    {String.fromCharCode(64 + parseInt(1 + key)) + ". "}
                  </Text>
                  <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        style={[
                          test.option_value_E,
                          {
                            color: ApublicColorFun.OptionRecord(
                              q.word,
                              item.myAnswer,
                              item.word
                            ),
                          },
                        ]}
                      >
                        {q.word}
                      </Text>
                      <Text
                        style={[
                          test.option_value_mean,
                          {
                            color: ApublicColorFun.OptionRecord(
                              q.word,
                              item.myAnswer,
                              item.word
                            ),
                          },
                        ]}
                      >
                        {q.meaning || q.Meaning}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}
