import React from "react";
import {
  StyleSheet,
  Text,
  View,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import resource from "../ApublicResourceSty";
import ReturnPHCom from "../../Component/returnPHCom";
import CustomStorage from "../../Component/CustomStorage";
import Paginator from "../../Component/Paginator";
let screenW = Dimensions.get("window").width;

import RenderHtml from "react-native-render-html";
import WebView from "react-native-webview";
import tagsStyles from "../../ApublicStyle/TagsStyles";

export default class classifyWordDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      documentTitle: this.props.navigation.state.params.Title,
      Bh: this.props.navigation.state.params.Bh,
      TypeBH: this.props.navigation.state.params.TypeBH,
      pageState: 0,
      allData: [],
      data: [],
      // 显示相关 前端分页
      Index: 0,
      pageTotal: 0,
      pageData: [],
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.getData();
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("gSyntax/classifyWordDetail", "单词分类详情");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getData = () => {
    let BH = this.state.Bh.slice(0, 2);
    let info = { Bh: BH, TypeBH: this.state.TypeBH };
    HttpUtil.post("7715_1", info).then((response) => {
      console.log(response);
      if (response.Code == 1) {
        let allData = response.Data[0].Children;
        this.setState(
          {
            allData: allData,
          },
          () => {
            this.handleData();
          }
        );
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  handleData = () => {
    let allData = this.state.allData;
    let data = [];
    let dataChildArr = [];
    let total = 0;
    for (let i = 0; i < allData.length; i++) {
      if (allData[i].Bh == this.state.Bh) {
        data.push(allData[i]);
      }
    }
    for (let i = 0; i < data[0].Children.length; i++) {
      if (!data[0].Children[i].wdMemo.startsWith("<")) {
        data[0].Children[i].wdMemo =
          "<p>" + data[0].Children[i].wdMemo + "</p>";
      }
    }
    total = Math.ceil(data[0].Children.length / 5);
    for (let i = 0; i < total; i++) {
      let Array = data[0].Children.slice(i * 5, i * 5 + 5);
      dataChildArr.push(Array);
    }
    this.setState(
      {
        data: data,
        Index: this.handleIndex(data[0].ID),
        pageTotal: total,
        pageData: dataChildArr,
        pageState: 1,
      },
      () => {
        this.StorageProgress();
      }
    );
  };

  handlePageChange = (page) => {
    let i = page - 1;
    this.myRef.current.scrollTo({ x: 0, y: 0, animated: true });
    this.setState(
      {
        Index: i,
      },
      () => {
        this.StorageProgress();
      }
    );
  };

  handleIndex = (ID) => {
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"));
    if (storageData == null) {
      return 0;
    }
    let isExist = false;
    for (let i = 0; i < storageData["classifyWord"].length; i++) {
      if (storageData["classifyWord"][i].ID == ID) {
        isExist = true;
        return storageData["classifyWord"][i].page;
      }
    }
    if (!isExist) {
      return 0;
    }
  };
  /*************************************************************************** 交互 ***************************************************************************/
  StorageProgress = () => {
    let total = this.state.pageTotal;
    if (total == 0 && this.state.data[0].wdMemo) {
      total = 1;
    }
    console.log("处理后的总页数", total);
    CustomStorage.StorageProgress(
      "classifyWord",
      this.state.data[0].ID,
      this.state.data[0].FenLeiName,
      this.state.Index,
      total
    );
  };
  /*************************************************************************** 跳转 ***************************************************************************/

  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  treeClassify = (item, key, i) => {
    return (
      <View
        style={[
          resource.BookConSty,
          { marginHorizontal: 0, borderWidth: i == 1 ? 2 : 0 },
        ]}
        key={key}
      >
        {i == 1 && (
          <View style={resource.BookTopBgSty}>
            <Text style={resource.DetailTitle}>{item.FenLeiName}</Text>
          </View>
        )}
        {i != 1 && (
          <View style={[styles.titleCon, { paddingHorizontal: 10 }]}>
            <View style={resource.HomeTitleBefore}></View>
            <Text style={resource.HomeTitleSty}>{item.FenLeiName}</Text>
          </View>
        )}
        <View style={{ padding: 10 }}>
          {item.wdMemo && (
            <RenderHtml
              contentWidth={screenW - 30 * 2}
              source={{ html: item.wdMemo }}
              WebView={WebView}
              ignoredStyles={["fontSize", "height", "width", "margin"]}
              tagsStyles={tagsStyles}
            />
          )}
        </View>
        {item.Children.length > 0 &&
          item.Children.map((child, key2) => {
            return this.treeClassify(child, key2, i + 1);
          })}
      </View>
    );
  };

  treeClassifyTitle = (item) => {
    return (
      <View style={[resource.BookConSty, { marginHorizontal: 0 }]}>
        <View style={resource.BookTopBgSty}>
          <Text style={resource.DetailTitle}>{item.FenLeiName}</Text>
        </View>
        <View style={{ padding: 10 }}>
          <RenderHtml
            contentWidth={screenW - 30 * 2}
            source={{ html: item.wdMemo }}
            WebView={WebView}
            ignoredStyles={["fontSize", "height", "width", "margin"]}
            tagsStyles={tagsStyles}
          />
        </View>
      </View>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          <ScrollView
            showsVerticalScrollIndicator={false}
            ref={this.myRef}
            style={{ flex: 1, backgroundColor: "#fff" }}
          >
            {this._renderHeader()}

            {this.state.data.length > 0 &&
              this.state.data.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{ paddingHorizontal: 20, marginTop: 20 }}
                  >
                    {item.wdMemo &&
                      this.state.Index == 0 &&
                      this.treeClassifyTitle(item)}
                    {this.state.pageData.length > 0 &&
                      this.state.pageData[this.state.Index].map(
                        (child, key) => {
                          return this.treeClassify(child, key, 1);
                        }
                      )}
                  </View>
                );
              })}
          </ScrollView>
          {this.state.pageState == 1 && this.state.pageTotal > 0 && (
            <View style={resource.BottomChangeCon}>
              {/* 分页器 */}
              <Paginator
                totalPages={this.state.pageTotal}
                pageStart={this.state.Index}
                onPageChange={this.handlePageChange}
              />
            </View>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  titleCon: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  titleLine: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  titleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
  },
});
