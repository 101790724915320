import React from "react";
import {
  Dimensions,
  Image,
  PermissionsAndroid,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Modal2, {
  ModalButton,
  ModalContent,
  ModalFooter,
  ModalTitle,
  ScaleAnimation,
} from "react-native-modals";
import BaseView from "../../../Component/BaseView";
import HttpUtil from "../../../Component/HttpUtil";
import Loading from "../../../Component/Loading";
import { ToastShort, consolePage } from "../../../Component/ToastUtlis";
import ReturnPHCom from "../../../Component/returnPHCom";
import config from "../../../Component/Config";

import char from "../../../ApublicStyle/CharacterStyle";
import test from "../../../ApublicStyle/TestStyle";

import { initValue } from "../../../Component/WordHighLight";

import { Record } from "./KP_SA_RecordContent";

let screenW = Dimensions.get("window").width;

export default class KP_SA_SentenceTestDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "辨析练习",
      dictID: this.props.navigation.state.params.dictID,
      pageState: 0,
      modalVisible2: false,
      isSubmit: false,

      // 做题
      quesIndex: 0,
      Options: [],
      questions: [],
      // 记录
      rightNum: 0,
      totalNum: 0,
      testQuestion: [],
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    // console.log(this.props.navigation.state.params);
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("KP_SA_SentenceTestDetail", "辨析练习");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getQues();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getQues = () => {
    let info = {
      dictID: this.state.dictID,
    };
    HttpUtil.post("626561_4", info).then((response) => {
      console.log("626561_4 获取辨析练习题目", response);
      if (response.Code == 1) {
        let data = JSON.parse(JSON.stringify(response.Data));

        this.handleData(data);

        /* 分组 直接截取 */
        // let randomData = this.shuffleArray(data);
        // let GroupNum = Math.ceil(data.length / 2);
        // let arr = randomData.slice(0, GroupNum);
        // randomData.splice(0, GroupNum);
        // let Options = [this.handleOptions(arr), this.handleOptions(randomData)];
        // console.log(arr, randomData);
        // this.setState({
        //   questions: [this.shuffleArray(arr), this.shuffleArray(randomData)],
        //   Options: Options,
        //   pageState: 1,
        // });

        /* end */

        /* 分组 按序奇偶分 */

        let odd = [];
        let even = [];
        for (let i = 0; i < data.length; i++) {
          if (i % 2 == 0) {
            even = even.concat(data[i]);
          } else {
            odd = odd.concat(data[i]);
          }
        }
        let Options = [this.handleOptions(odd), this.handleOptions(even)];
        this.setState({
          questions: [this.shuffleArray(odd), this.shuffleArray(even)],
          Options: Options,
          pageState: 1,
        });

        /* end */

        /* 数组去重 defeated */
        // // 创建一个空对象来保存唯一的对象
        // let uniqueObjects = {};

        // // 遍历数组，将每个对象按照 `word` 属性存储在 uniqueObjects 中
        // data.forEach((item) => {
        //   uniqueObjects[item.word] = item;
        // });

        // // 使用 Object.values() 将 uniqueObjects 转换为数组
        // let uniqueArr = Object.values(uniqueObjects);
        /* end */
      } else {
        this.setState(
          {
            pageState: 3,
          },
          () => {
            ToastShort(response.Msg);
          }
        );
      }
    });
  };

  // Fisher-Yates洗牌算法 打乱一个数组
  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  handleData = (data) => {
    for (let i = 0; i < data.length; i++) {
      data[i].sentence = data[i].sentence.split("&&&").join("&&& ").trim();
      data[i].sentenceArr = data[i].sentence
        .split(" ")
        .filter((item) => item != "");
      data[i].spaceIndex = data[i].sentenceArr.indexOf("&&&");
      data[i].mySelect = "";
      data[i].myAnswer = "";
    }
  };

  handleOptions = (arr) => {
    let Op_arr = arr.map((it, key) => {
      return {
        word: it.word,
        key: key,
        isCheck: false,
      };
    });
    return this.shuffleArray(Op_arr);
  };
  /*************************************************************************** 返回值 ***************************************************************************/
  ColorConfig = (type, mySelect, myAnswer, correctAnswer) => {
    if (type == "backgroundColor") {
      if (mySelect == "") {
        return "#fff";
      }
      if (mySelect != "" && myAnswer == "") {
        return "#1c76fc1a";
      }
      if (myAnswer == correctAnswer) {
        return "#05c08b1a";
      }
      if (myAnswer != correctAnswer) {
        return "#F56C6C1A";
      }
    }
    if (type == "color") {
      if (myAnswer == "") {
        return "#1c76fc";
      }
      if (myAnswer == correctAnswer) {
        return "#05c08b";
      }
      if (myAnswer != correctAnswer) {
        return "#F56C6C";
      }
    }
  };

  isCorrect = (item) => {
    if (item.myAnswer == "") {
      return -1;
    }
    if (item.myAnswer == item.word) {
      return 1;
    }
    if (item.myAnswer != item.word) {
      return 0;
    }
  };

  isSureAnswer = () => {
    let Question = this.state.questions[this.state.quesIndex];
    let isAllAnswer = true;
    for (let i = 0; i < Question.length; i++) {
      if (Question[i].myAnswer == "") {
        isAllAnswer = false;
        break;
      }
    }
    return isAllAnswer;
  };

  nextText = () => {
    let Question = this.state.questions[this.state.quesIndex];
    let isAllAnswer = true;
    for (let i = 0; i < Question.length; i++) {
      if (Question[i].myAnswer == "") {
        isAllAnswer = false;
        break;
      }
    }

    if (!isAllAnswer) {
      return "确 认";
    }
    if (
      isAllAnswer &&
      this.state.quesIndex + 1 != this.state.questions.length
    ) {
      return "下一组";
    }
    if (
      isAllAnswer &&
      this.state.quesIndex + 1 == this.state.questions.length
    ) {
      return "提 交";
    }
  };

  deepCopy = (Question, Option) => {
    // console.log(Question, Option);
    if (Question != undefined) {
      let deep_Q = JSON.parse(JSON.stringify(this.state.questions));
      deep_Q.splice(this.state.quesIndex, 1, Question);
      this.setState({
        questions: deep_Q,
      });
    }
    if (Option != undefined) {
      let deep_O = JSON.parse(JSON.stringify(this.state.Options));
      deep_O.splice(this.state.quesIndex, 1, Option);
      this.setState({
        Options: deep_O,
      });
    }
  };
  /*************************************************************************** 交互 ***************************************************************************/
  clickItem = (item, flag) => {
    if (this.isSureAnswer()) {
      return;
    }
    let Question = this.state.questions[this.state.quesIndex];
    let Option = this.state.Options[this.state.quesIndex];

    item.isCheck = !flag;
    if (!flag) {
      for (let i = 0; i < Question.length; i++) {
        if (Question[i].mySelect == "") {
          Question[i].mySelect = item;
          Question[i].sentenceArr.splice(Question[i].spaceIndex, 1, item.word);
          break;
        }
      }
    } else {
      for (let i = 0; i < Question.length; i++) {
        if (Question[i].mySelect.key == item.key) {
          Question[i].mySelect = "";
          Question[i].sentenceArr.splice(Question[i].spaceIndex, 1, "");
          break;
        }
      }
    }
    this.deepCopy(Question, Option);
  };

  cancelCheck = (item, index) => {
    if (this.isSureAnswer()) {
      return;
    }
    if (item.mySelect == "") {
      return;
    }
    let Question = this.state.questions[this.state.quesIndex];
    let Option = this.state.Options[this.state.quesIndex];

    for (let i = 0; i < Option.length; i++) {
      if (Option[i].key == item.mySelect.key) {
        Option[i].isCheck = false;
      }
    }

    item.mySelect = "";
    item.sentenceArr.splice(item.spaceIndex, 1, "");

    Question.splice(index, 1, item);

    this.deepCopy(Question, Option);
  };

  doNext = () => {
    let Option = this.state.Options[this.state.quesIndex];

    let isAllCheck = true;
    for (let i = 0; i < Option.length; i++) {
      if (Option[i].isCheck == false) {
        isAllCheck = false;
        break;
      }
    }

    let Question = this.state.questions[this.state.quesIndex];
    let isAllAnswer = true;
    for (let i = 0; i < Question.length; i++) {
      if (Question[i].myAnswer == "") {
        isAllAnswer = false;
        break;
      }
    }

    /* 并未全部选择 */
    if (!isAllCheck) {
      ToastShort("请再检查检查");
      return;
    }
    /* 全部选择 未确认给答案赋值 去赋值 */
    if (isAllCheck && !isAllAnswer) {
      this.confirmMyAnswer();
      return;
    }
    /* 全部选择 答案已赋值 还有下一题 */
    if (
      isAllCheck &&
      isAllAnswer &&
      this.state.quesIndex + 1 != this.state.questions.length
    ) {
      this.setState({
        quesIndex: this.state.quesIndex + 1,
      });
      return;
    }
    if (
      isAllCheck &&
      isAllAnswer &&
      this.state.quesIndex + 1 == this.state.questions.length
    ) {
      this.submit();
      return;
    }
  };

  confirmMyAnswer = () => {
    let Question = this.state.questions[this.state.quesIndex];
    for (let i = 0; i < Question.length; i++) {
      Question[i].myAnswer = Question[i].mySelect.word;
    }
    this.deepCopy(Question);
  };

  submit = () => {
    if (this.state.isSubmit) {
      console.log("禁止重复提交");
      return;
    }
    let links = [];
    let score = 0;
    let testNum = 0;
    for (let i = 0; i < this.state.questions.length; i++) {
      testNum = testNum + this.state.questions[i].length;
      let arr = this.state.questions[i].map((item) => {
        if (item.word == item.myAnswer) {
          score++;
        }
        return {
          ID: item.ID,
          dictId: item.dictId,
          sentenceId: item.sentenceId,
          word: item.word,
          source: item.source,
          senMeaning: item.senMeaning,
          myAnswer: item.myAnswer,
          isCorrect: item.word == item.myAnswer ? 1 : 0,
        };
      });
      links.push(arr);
    }
    this.setState(
      {
        isSubmit: true,
        rightNum: score,
        totalNum: testNum,
        testQuestion: links,
      },
      () => {
        this.postData(links);
      }
    );
  };

  postData = (links) => {
    let info = {
      BookId: this.props.navigation.state.params.dictID,
      ClassId: config.classMsg.ClassId,
      StudentName: config.user.userName,
      StudentId: config.user.ID,
      StudentBh: config.user.userBh,
      AllNum: this.state.totalNum,
      RightNum: this.state.rightNum,
      RightRate: this.state.rightNum / this.state.totalNum,
      Type: 4,
      SubmitAnswer: JSON.stringify(links),
    };
    HttpUtil.post("626366", info)
      .then((res) => {
        console.log(res);
        if (res.Code == 1) {
          ToastShort("提交成功");
          this.setState({
            pageState: 2,
          });
        } else {
          ToastShort(res.Msg);
        }
      })
      .catch((e) => {
        console.log(e);
        ToastShort("提交失败");
      });
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  _setReturnName(obj) {
    if (obj == "back") {
      // this.props.navigation.pop();
      if (this.state.quesIndex == 0 || this.state.isSubmit) {
        this.props.navigation.pop();
        return;
      } else {
        this.setState({
          modalVisible2: true,
        });
      }
    }
  }

  confirm = () => {
    this.setState({
      modalVisible2: false,
    });
    this.props.navigation.pop();
  };

  close2 = () => {
    this.setState({
      modalVisible2: false,
    });
  };

  nextRound = (num) => {
    if (num == 1) {
      this.props.navigation.pop();
      return;
    }
    if (num == 2) {
      this.setState(
        {
          pageState: 0,
          modalVisible2: false,
          isSubmit: false,
          quesIndex: 0,
          Options: [],
          questions: [],
          rightNum: 0,
          totalNum: 0,
          testQuestion: [],
        },
        () => {
          this.getQues();
        }
      );
    }
  };

  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
      </View>
    );
  };

  stemCom = () => {
    let ques = this.state.questions[this.state.quesIndex];
    return (
      <View style={{ paddingBottom: 25, width: "100%" }}>
        <View style={test.A_S_container}>
          <Text style={{ ...test.prompt_text, width: "100%" }}>
            提示：点击高亮可以清除选择
          </Text>
        </View>
        <View style={{ marginTop: 5 }}>
          {ques.length > 0 &&
            ques.map((item, index) => {
              return this.StemItem(item, index);
            })}
        </View>
        {this.isSureAnswer() && (
          <View style={{ marginTop: 15 }}>
            {ques.length > 0 &&
              ques.map((item, index) => {
                return (
                  <View
                    style={[test.stem_container_2, { backgroundColor: "#fff" }]}
                    key={index}
                  >
                    <Text style={char.SENTENCE_STY}>
                      {"Q" + (index + 1) + "、"}
                      {initValue(item.source.trim(), item.word)}
                    </Text>
                    <Text style={{ ...char.SENTENCE_MEAN_STY, marginTop: 8 }}>
                      {item.senMeaning}
                    </Text>
                  </View>
                );
              })}
          </View>
        )}
        {!this.isSureAnswer() && this.SelectionBox()}
      </View>
    );
  };

  StemItem = (item, index) => {
    return (
      <TouchableOpacity
        key={index}
        style={[
          test.stem_container_2,
          {
            backgroundColor: this.ColorConfig(
              "backgroundColor",
              item.mySelect,
              item.myAnswer,
              item.word
            ),
          },
        ]}
        onPress={() => this.cancelCheck(item, index)}
      >
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <Text style={[test.stem_Sentence_Select_item]}>
            {"Q" + (index + 1) + "、"}
          </Text>
          {item.sentenceArr.length > 0 &&
            item.sentenceArr.map((row, key) => {
              if (key == item.spaceIndex) {
                return (
                  <Text
                    key={key}
                    style={[
                      test.stem_Sentence_Select_item,
                      {
                        minWidth: 80,
                        borderColor: "#1c76fc",
                        borderBottomWidth: 1,
                        color: this.ColorConfig(
                          "color",
                          item.mySelect,
                          item.myAnswer,
                          item.word
                        ),
                      },
                    ]}
                  >
                    {row == "&&&" ? "" : row}
                  </Text>
                );
              } else {
                return (
                  <Text key={key} style={[test.stem_Sentence_Select_item]}>
                    {row}
                  </Text>
                );
              }
            })}
          {this.isCorrect(item) === 0 && (
            <Image
              source={require("../../../images/public/noComplete.png")}
              style={test.sentence_R_Img}
            />
          )}
          {this.isCorrect(item) === 1 && (
            <Image
              source={require("../../../images/public/complete.png")}
              style={test.sentence_R_Img}
            />
          )}
        </View>
      </TouchableOpacity>
    );
  };

  SelectionBox = () => {
    let Option = this.state.Options[this.state.quesIndex];
    return (
      <View style={{ ...test.Selection_Position, marginTop: 20 }}>
        {Option != null &&
          Option.map((item, index) => {
            return (
              <TouchableOpacity
                style={[
                  test.Selection_Btn_Sty,
                  {
                    backgroundColor: item.isCheck ? "#ecf5ff" : "#fff",
                    borderColor: item.isCheck ? "#b3d8ff" : "#dcdfe6",
                  },
                ]}
                key={index}
                onPress={() => this.clickItem(item, item.isCheck)}
              >
                <Text
                  style={[
                    test.option_value_E,
                    { color: item.isCheck ? "#409eff" : "#606266" },
                  ]}
                >
                  {item.word}
                </Text>
              </TouchableOpacity>
            );
          })}
      </View>
    );
  };

  _renderNext = () => {
    return (
      <View style={[styles.NextBtn, { paddingHorizontal: 20 }]}>
        {/* 进度 */}
        <View
          style={{
            height: 40,
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Text style={{ fontSize: 12 }}>{"练习进度"}</Text>
          <Text style={test.schedule_text}>
            {this.state.questions.length > 0
              ? this.state.quesIndex + 1 + "/" + this.state.questions.length
              : "-- / --"}
          </Text>
        </View>
        {/* 切换题目 */}
        {
          <TouchableOpacity
            onPress={() => {
              this.doNext();
            }}
            style={test.btn_noDisabled}
          >
            <Text style={{ fontSize: 15, color: "#fff" }}>
              {this.nextText()}
            </Text>
          </TouchableOpacity>
        }
      </View>
    );
  };

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          <View
            style={{ width: "100%", paddingHorizontal: 20, paddingTop: 20 }}
          >
            <Text
              style={{
                color:
                  this.state.rightNum / this.state.totalNum > 0.8
                    ? "#40CC5C"
                    : "#E83333",
                fontSize: 20,
                fontWeight: 600,
                lineHeight: 20,
                textAlign: "center",
              }}
            >
              {((this.state.rightNum / this.state.totalNum) * 100).toFixed(0)}%
              正确率
            </Text>
          </View>
          {Record(
            this.state.rightNum,
            this.state.totalNum,
            this.state.testQuestion
          )}
        </ScrollView>
        {this._renderNextTest()}
      </View>
    );
  };

  _renderNextTest = () => {
    return (
      <View style={styles.NextBtn}>
        <TouchableOpacity
          onPress={() => {
            this.nextRound(1);
          }}
          style={{
            height: 40,
            width: "45%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>{"返回"}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            this.nextRound(2);
          }}
          style={{
            height: 40,
            width: "45%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>{"再来一次"}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}
          {this.state.pageState == 1 && (
            <ScrollView
              style={test.scroll_container}
              showsVerticalScrollIndicator={false}
            >
              <Text style={{ flex: 1 }}>
                {/* 题干 */}
                {this.stemCom()}
              </Text>
            </ScrollView>
          )}
          {this.state.pageState == 1 && this._renderNext()}
          {this.state.pageState == 2 && this.testRecord()}
          {this.state.pageState == 0 && <Loading />}
        </View>
        <Modal2
          style={{ position: "relative" }}
          modalAnimation={
            new ScaleAnimation({
              initialValue: 0, // optional
              useNativeDriver: true, // optional
            })
          }
          transparent={true}
          modalTitle={
            <ModalTitle
              textStyle={{ color: "#333", fontSize: 13 }}
              title="友情提示"
            />
          }
          footer={
            <ModalFooter>
              <ModalButton
                text="取 消"
                disabled={false}
                textStyle={{ color: "#333333", fontSize: 14 }}
                onPress={() => this.close2()}
              />
              <ModalButton
                text="退 出"
                disabled={false}
                textStyle={{ color: "#1c76fc", fontSize: 14 }}
                onPress={() => this.confirm()}
              />
            </ModalFooter>
          }
          visible={this.state.modalVisible2}
          onTouchOutside={() => {
            this.setState({ modalVisible2: false });
          }}
          onRequestClose={() => this.close2()}
        >
          <ModalContent>
            <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
              <Text style={{ fontSize: 14, color: "#999", lineHeight: 22 }}>
                注：点击退出即返回上一页，此次闯关数据不做记录，您之后将重新做题！
              </Text>
            </View>
          </ModalContent>
        </Modal2>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  NextBtn: {
    height: 60,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
  },
});
