function list2treeBh(list, code) {
  let set = new Set(list.map((item) => item[code].length));
  let setOrder = Array.from(set).sort((a, b) => a - b);
  let arr = list.filter((item) => item[code].length == setOrder[0]);
  arr.forEach((item) => {
    recursion(item, list, setOrder, 1, code);
  });
  arr.push(...redundancy(list, setOrder[0], code));
  return arr;
}
function recursion(obj, list, setOrder, curIndex, code) {
  let arr = list.filter(
    (item) =>
      item[code].indexOf(obj[code]) == 0 &&
      item[code].length == setOrder[curIndex]
  );
  if (arr.length) obj.children = arr;
  arr.forEach((element) => {
    recursion(element, list, setOrder, curIndex + 1, code);
  });
  return arr;
}

// 冗余数据（找不到一级节点的都是冗余数据）
// todo 可以使用map进行优化
function redundancy(list, first, code) {
  let codes = list
    .filter((item) => item[code].length == first)
    .map((t) => t[code]);
  let result = [];
  list.forEach((t) => {
    let flag = true;
    codes.forEach((tt) => {
      if (t[code].startsWith(tt)) flag = false;
    });
    if (flag) result.push(t);
  });
  return result;
}

export default list2treeBh;
