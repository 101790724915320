/**
 * loading效果
 */
import React, { Component } from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";

export default class Loading extends Component {
  // 构造
  constructor(props) {
    super(props);
    // 初始状态
    this.state = {};
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <ActivityIndicator size={"large"} color={"#666"} animating={true} />

        <Text style={{ color: "#666", fontSize: 18, marginTop: 15 }}>
          正在加载中
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({});
