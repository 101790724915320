/**
 * Empty 空状态
 */
import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";

export default class Empty extends Component {
  // 构造
  constructor(props) {
    super(props);
    // 初始状态
    this.state = {};
  }

  render() {
    return (
      <View
        style={{
          paddingVertical: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{ color: "#666", height: 40, lineHeight: 40 }}>
          {"暂无数据"}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({});
