import React from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Dimensions,
  ScrollView,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import ReturnPHCom from "../../Component/returnPHCom";

import Navigation from "../../AmoduleCollection/IconNavigation";
import BottomBlock from "../../AmoduleCollection/BottomBlock";

import Loading from "../../Component/Loading";

var deviceWidth = Dimensions.get("window").width;
var deviceHeight = Dimensions.get("window").height;
let iconWH = deviceWidth * 0.9 * 0.1;

export default class W_U_Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      WordType: {},
      Props: {
        Title: "Title",
        list: "IconList",
        list_name: "DText",
        list_imgUrl: "imgUrl",
      },
      pageState: 0,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "W_U_Home");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getWordType();
      if (config.wordSlogan && config.wordSlogan.length > 0) {
        config.wordSlogan = config.wordSlogan;
      } else {
        this.getWordSlogan();
      }
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  // 获取口号标语
  getWordSlogan = () => {
    var info = {
      Word: "",
      TypeBH: "MID",
      Source: "f0220d94fc354b6ebccdf89435241380",
      pageStart: 0,
      pageSize: 20,
    };
    HttpUtil.post("7703_4", info).then((response) => {
      if (response.Code == 1) {
        config.wordSlogan = response.Data;
      }
    });
  };

  // 获取分类单词
  getWordType = () => {
    let param = {
      Code: "midWord",
    };
    HttpUtil.post("76110", param).then((res) => {
      console.log("76110 midWord", res);
      if (res.Code == 1) {
        let data = res.Data;
        for (let i = 0; i < data.length; i++) {
          data[i].imgUrl = require(`../../images/library/Word_${
            (i % 7) + 1
          }.png`);
        }
        let nav = {
          Title: "考点提升",
          IconList: data,
        };
        this.setState({
          WordType: nav,
          pageState: 1,
        });
      }
    });
  };

  handleIconClick = (item) => {
    let obj = {
      menuName: item.DText ? item.DText : "",
      id: item.ID,
      type: item.Code,
    };
    this.props.navigation.navigate("W_U_Detail_Home", { item: obj });
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="rgba(0,0,0,0)"
          translucent={true}
          barStyle={"dark-content"}
        />
        <View style={{ height: deviceHeight }}>
          {this.state.pageState == 1 && (
            <ScrollView
              style={{ flex: 1 }}
              showsVerticalScrollIndicator={false}
            >
              {this.state.WordType.Title && (
                <Navigation
                  nav={this.state.WordType}
                  Props={this.state.Props}
                  onIconClick={this.handleIconClick}
                />
              )}
              <BottomBlock />
            </ScrollView>
          )}
          {this.state.pageState == 0 && <Loading />}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    width: "100%",
    flex: 1,
  },
  classInfoCon: {
    width: "100%",
    marginTop: 20,
  },
  info: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: "#E6E6E6",
  },
  info_book: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleCon: {
    marginHorizontal: 20,
    marginTop: 12,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  titleLine: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  titleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
  },
  flexRow: {
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    borderBottomColor: "#e5e5e5",
    flexWrap: "wrap",
  },
  btnSty: {
    marginBottom: 10,
    flexDirection: "column",
    alignItems: "center",
  },
  iconOutSty: {
    padding: 8,
    borderRadius: 10,
    backgroundColor: "#fff",
  },
  btn_sty: {
    width: "48%",
    height: 49,
    marginTop: 30,
    backgroundColor: "#1c76fc",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  modalContent: {
    width: "70%",
    height: "100%",
    backgroundColor: "#ffffff",
  },
  treeLine: {
    width: "100%",
    height: 46,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  treeInLine: {
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textShow: {
    color: "#333",
    flex: 1,
    lineHeight: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  btn_detail: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
