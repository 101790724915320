import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Image,
} from "react-native";

var deviceWidth = Dimensions.get("window").width;
let iconWH = deviceWidth * 0.9 * 0.1;
let marginNum = (deviceWidth - 40 - (deviceWidth - 40) * 0.2 * 4) / 3;

const Navigation = ({ nav, Props, onIconClick }) => {
  const defaultProps = {
    Title: "Title",
    list: "IconList",
    list_name: "name",
    list_imgUrl: "imgUrl",
  };
  Props = Props === undefined ? defaultProps : Props;

  return (
    <View style={{ paddingTop: 15 }}>
      <View style={styles.titleCon}>
        <View style={styles.titleLine}></View>
        <Text style={styles.titleSty}>{nav[Props.Title]}</Text>
      </View>
      <View style={styles.flexRow}>
        {nav[Props.list].map((item, index) => {
          return (
            <TouchableOpacity
              onPress={() => onIconClick(item)}
              key={index}
              style={[
                styles.btnSty,
                {
                  marginLeft: (index + 1) % 4 != 1 ? marginNum : 0,
                },
              ]}
            >
              <View style={styles.iconOutSty}>
                <Image
                  source={`${item[Props.list_imgUrl]}`}
                  style={{
                    width: iconWH,
                    height: iconWH,
                  }}
                />
              </View>
              <Text
                style={{
                  width: (deviceWidth - 40) * 0.2,
                  color: "#333",
                  fontSize: 12,
                  paddingVertical: 5,
                  marginTop: 3,
                  textAlign: "center",
                }}
                // numberOfLines={1}
                // ellipsizeMode="tail"
              >
                {item[Props.list_name]}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

var styles = StyleSheet.create({
  titleCon: {
    marginHorizontal: 20,
    marginTop: 12,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  titleLine: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  titleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
  },
  flexRow: {
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    borderBottomColor: "#e5e5e5",
    flexWrap: "wrap",
  },
  btnSty: {
    marginBottom: 10,
    flexDirection: "column",
    alignItems: "center",
  },
  iconOutSty: {
    padding: 8,
    borderRadius: 10,
    backgroundColor: "#fff",
  },
});

export default Navigation;
