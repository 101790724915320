import React from "react";
import {
  ActivityIndicator,
  Dimensions,
  PermissionsAndroid,
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { FlatList } from "react-native-gesture-handler";
import BaseView from "../../Component/BaseView";
import HttpUtil from "../../Component/HttpUtil";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";

import RenderHtml from "react-native-render-html";
import WebView from "react-native-webview";
import tagsStyles from "../../ApublicStyle/TagsStyles";
import Paginator from "../../Component/Paginator";
import ReturnPHCom from "../../Component/returnPHCom";
import resource from "../ApublicResourceSty";
import Empty from "../../Component/Empty";
const Dwidth = Dimensions.get("window").width; //414
const Lheight = Dimensions.get("window").height;

export default class syntaxDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.state = {
      // 传参
      documentTitle: this.props.navigation.state.params.Title,
      TypeID: this.props.navigation.state.params.ID,
      pageTotal: 0,
      // 列表
      list: [],
      isLoadMore: true,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("gSyntax/syntaxDetail", "语法详情");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getData();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getData = () => {
    let info = {
      DText: this.state.TypeID,
      pageStart: this.page,
      pageSize: 3,
    };
    HttpUtil.post("76114", info).then((response) => {
      console.log("获取语法详情列表 76114", this.page, response);
      if (response.Code == 1) {
        let data = response.Data;
        let Num = Math.ceil(response.Num / 3);
        this.setState({
          list: data,
          pageTotal: Num,
          isLoadMore: false,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/

  /*************************************************************************** 跳转 ***************************************************************************/
  handlePageChange = (page) => {
    this.page = page - 1;
    this.setState(
      {
        list: [],
        isLoadMore: true,
      },
      () => {
        this.getData();
      }
    );
  };

  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  // 加载更多
  _ListFooterComponent = () => {
    if (this.state.isLoadMore == false) {
      return this.state.list.length == 0 ? <Empty /> : <View />;
    } else {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 40,
            marginVertical: 10,
          }}
        >
          <ActivityIndicator size={"small"} color={"#666"} animating={true} />
          <Text
            style={{
              color: "#666",
              lineHeight: 40,
              height: "100%",
              marginLeft: 10,
            }}
          >
            正在加载更多
          </Text>
        </View>
      );
    }
  };

  _renderItem = ({ item, index }) => {
    return (
      <View style={[resource.BookConSty, { marginTop: index == 0 && 20 }]}>
        <View style={resource.BookTopBgSty}>
          <Text style={resource.DetailTitle}>
            {index + 1 + ". " + item.Title}
          </Text>
        </View>
        <View style={{ padding: 10 }}>
          <RenderHtml
            contentWidth={Dwidth - 30 * 2}
            source={{ html: item.Memo }}
            WebView={WebView}
            ignoredStyles={["fontSize", "height", "width", "margin"]}
            tagsStyles={tagsStyles}
          />
        </View>
      </View>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          <FlatList
            style={{ flex: 1, backgroundColor: "#fff" }}
            data={this.state.list}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            keyExtractor={this._keyExtractor}
            renderItem={this._renderItem}
            ListFooterComponent={this._ListFooterComponent}
            ListHeaderComponent={this._renderHeader}
            onEndReachedThreshold={0.3}
          />
          {this.state.pageTotal > 1 && (
            <View style={resource.BottomChangeCon}>
              <Paginator
                totalPages={this.state.pageTotal}
                pageStart={this.page}
                onPageChange={this.handlePageChange}
              />
            </View>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
});
