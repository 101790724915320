import Toast from 'react-native-root-toast';

/**
    * 冒一个时间比较短的Toast
    * @param content
    */
export function ToastShort(content) {
    let toast;
    if (toast !== undefined) {
        Toast.hide(toast);
    }
    toast = Toast.show(content.toString(), {
        duration: Toast.durations.SHORT,
        position: Toast.positions.CENTER,
        shadow: true,
        animation: false,//有动画会有延迟显示
        hideOnPress: true,
        delay: 0
    });
};
export function consolePage(msg, obj, msg2) {
    console.info("来自页面：", msg, obj == undefined ? '' : obj, msg2 == undefined ? '' : msg2)
};


