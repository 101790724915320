import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";

import resource from "../ApublicResourceSty";
import ReturnPHCom from "../../Component/returnPHCom";
import CustomStorage from "../../Component/CustomStorage";
import Loading from "../../Component/Loading";

import BottomBlock from "../../AmoduleCollection/BottomBlock";

import BtnTree from "../../AmoduleCollection/ButtonNavigation";

export default class syntaxHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "语法资源",
      syntaxType: [],
      pageState: 0,
      defaultProps: {
        Title: "DText",
        children: "Children",
        childName: "DText",
      },
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.getType();
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("syntaxHome", "语法类型");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getType = () => {
    let info = {
      Code: "grammarType",
    };
    HttpUtil.post("76112_1", info).then((response) => {
      console.log("76112_1", response);
      let data = response.Data || [];
      this.setState({
        syntaxType: data,
        pageState: 1,
      });
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/

  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };

  gotoTagSyntax = (item, index, data) => {
    if (item.Children && item.Children.length > 0) {
      let query = {
        Title: item.DText,
        Code: item.Code,
        DValue: item.DValue,
      };
      this.props.navigation.navigate("syntaxTagList", query);
    } else {
      let query = {
        Title: item.DText,
        ID: item.ID,
      };
      this.props.navigation.navigate("syntaxDetail", query);
    }
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}

          {this.state.pageState == 0 && <Loading></Loading>}

          {this.state.pageState == 1 && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ flex: 1, backgroundColor: "#fff" }}
            >
              {this.state.syntaxType.length > 0 && (
                <BtnTree
                  TreeData={this.state.syntaxType}
                  Props={this.state.defaultProps}
                  onClickBtn={this.gotoTagSyntax}
                />
              )}
              <BottomBlock />
            </ScrollView>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  SubtagCon: {
    padding: 10,
    marginRight: 10,
    backgroundColor: "rgba(28, 118, 252, 0.1)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    borderRadius: 6,
  },
});
