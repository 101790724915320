import { Dimensions } from "react-native";

var deviceWidth = Dimensions.get("window").width;

var resource = {
  RowCenter: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  HomeTitleCon: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  HomeTitleBefore: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  HomeTitleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
  },
  SubtagCon: {
    padding: 10,
    marginRight: 10,
    backgroundColor: "rgba(28, 118, 252, 0.1)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    borderRadius: 6,
  },
  SubtagSty: {
    color: "#333",
    fontSize: 12,
    paddingHorizontal: 5,
    textAlign: "center",
  },
  BookConSty: {
    marginHorizontal: 20,
    marginBottom: 20,
    borderColor: "#e8f1ff",
    borderWidth: 2,
    borderRadius: 10,
  },
  BookTopBgSty: {
    paddingHorizontal: 10,
    backgroundColor: "#e8f1ff",
    paddingVertical: 5,
  },
  DetailTitle: {
    fontSize: 18,
    color: "#1c76fc",
    fontWeight: "bold",
    lineHeight: 25,
  },
  DetailText: {
    fontSize: 15,
    color: "#333",
    lineHeight: 20,
  },

  EnglishText: {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: 500,
  },
  ChineseText: {
    fontSize: 13,
    color: "#666666",
    lineHeight: 20,
    marginTop: 8,
  },
  BottomChangeCon: {
    width: "100%",
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 40,
    backgroundColor: "#fff",
    borderColor: "#e5e5e5",
    borderTopWidth: 1,
  },
  BottomChangeText: {
    color: "#333",
    lineHeight: 20,
  },
};
export default resource;
