import { Dimensions } from "react-native";
const Dwidth = Dimensions.get("window").width; //414
import char from "./CharacterStyle";

var PatSty = {
  ContentCon: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    alignItems: "flex-start",
  },
  ContentCon2: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  ContentItem: {
    flex: 1,
  },
  flexDirection: {
    flexDirection: "row",
    alignItems: "center",
    // 'flex-start'
  },
  flexDirection1: {
    flexDirection: "row",
    alignItems: 'flex-start',
  },
  Text_Word_Index: {
    ...char.WORD_STY,
  },
  Text_Chinese: {
    ...char.WORD_MEAN_STY,
    marginLeft: 15,
  },
};

export default PatSty;
