import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Image,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import config from "../../Component/Config";
import list2treeBh from "../../Component/list2treeBh";
import ReturnPHCom from "../../Component/returnPHCom";
import Loading from "../../Component/Loading";

export default class KnowledgePointsHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: this.props.navigation.state.params.DText,
      TypeList: [],
      pageState: 0,
      foldState: false,
      ParentId: this.props.navigation.state.params.ParentId,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("KP_Home", "知识点分组列表");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      if (this.state.pageState == 0) {
        this.getPoints();
      }
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getPoints = () => {
    let info = {
      Code: "knowledgePoint",
      ParentId: this.state.ParentId,
      userId: config.user.ID,
    };
    HttpUtil.post("76111_1", info).then((response) => {
      console.log("76111_1", response);
      if (response.Code == 1) {
        this.handleIndex3(response.Data);
        let data = list2treeBh(response.Data, "DValue");
        console.log("76111_1 处理后data", data);
        this.setState({
          TypeList: data,
          pageState: 1,
        });
      } else {
        this.setState({
          pageState: 1,
        });
        ToastShort(response.Msg);
      }
    });
  };

  // 没有子集的数据补序 1 开始
  handleIndex = (data) => {
    let index = 1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].children && data[i].children.length > 0) {
        this.handleIndex(data[i].children);
      } else {
        data[i].Index = index;
        index++;
      }
    }
  };

  // 全部补序（仅限制两层） 超出子集限制 1-10接11-20
  handleIndex2 = (data) => {
    let index = 1;
    let index2 = 1;
    for (let i = 0; i < data.length; i++) {
      data[i].Index = index;
      index++;
      if (data[i].children && data[i].children.length > 0) {
        for (let j = 0; j < data[i].children.length; j++) {
          data[i].children[j].Index = index2;
          index2++;
        }
      }
    }
  };

  handleIndex3 = (data) => {
    // 排序 取最长编号
    data.sort((a, b) => a.DValue - b.DValue);
    let maxBH = data[data.length - 1].DValue.length;
    // console.log("最长编号长度", maxBH);
    for (let i = 2; i <= maxBH; i += 2) {
      let tempArr = data.filter((item) => {
        return item.DValue.length == i;
      });
      tempArr.sort((a, b) => a.DValue - b.DValue);
      // console.log('编号长度为' + i + '的数组', tempArr);
      for (let j = 0; j < tempArr.length; j++) {
        let newIndex = String(j + 1);
        newIndex = newIndex;
        if (i == 2) {
          // tempArr[j].Index = this.intToRoman(j + 1);
          tempArr[j].Index = newIndex + "、";
        } else {
          tempArr[j].Index = newIndex + ". ";
        }
      }
    }
  };

  // 数字转罗马数字
  intToRoman = (num) => {
    let map = {
      1: "I",
      5: "V",
      10: "X",
      50: "L",
      100: "C",
      500: "D",
      1000: "M",
    };
    // 标识位数
    let digits = 1;
    // 结果
    let result = "";
    while (num) {
      let current = num % 10;
      if (current < 4) {
        result = map[digits].repeat(current) + result;
      } else if (current === 4) {
        result = map[digits] + map[digits * 5] + result;
      } else if (current > 4 && current < 9) {
        result = map[digits * 5] + map[digits].repeat(current - 5) + result;
      } else {
        result = map[digits] + map[digits * 10] + result;
      }
      digits *= 10;
      num = Math.trunc(num / 10);
    }
    return result;
  };
  /*************************************************************************** 交互 ***************************************************************************/
  showTreeFun = (item, flag) => {
    item.showFlag = flag;
    var arr = [];
    arr = arr.concat(this.state.TypeList);
    this.setState(
      {
        TypeList: arr,
      },
      () => {
        this.inspectFold();
      }
    );
  };

  // 递归折叠
  handleFold = (data, type) => {
    if (data.length == 0 || data == null) {
      return;
    }
    data.forEach((item) => {
      this.showTreeFun(item, type);
      if (item.children && item.children.length > 0) {
        this.handleFold(item.children, type);
      }
    });
  };

  inspectFold = () => {
    let state = true;
    for (let i = 0; i < this.state.TypeList.length; i++) {
      if (this.state.TypeList[i].showFlag === 0) {
        state = true;
      } else {
        state = false;
        break;
      }
    }
    this.setState({
      foldState: state,
    });
  };

  handleNodeClick = (item, flag) => {
    // 1 收起 0/undefined 展开
    if (item.DValue.length == 2) {
      flag = flag === undefined ? 1 : flag;
      let state = Number(!flag);
      this.showTreeFun(item, state);
      return;
    }
    if (item.disabledFlag != 1) {
      this.gotoPointsInfo(item);
    }
  };

  collectOperate = (item, flag, parent) => {
    // flag 0 未收藏 1 已收藏
    switch (flag) {
      case 0:
        this.addCollect(item, parent);
        break;
      case 1:
        this.delCollect(item, parent);
        break;
    }
  };

  addCollect = (item, parent) => {
    let info = {
      dictId: item.ID,
      bh: item.DValue,
      name: parent + "/" + item.DText,
      parentId: item.ParentId,
      userId: config.user.ID,
    };
    HttpUtil.post("909092", info).then((response) => {
      console.log("909092 加入收藏", response);
      if (response.Code == 1) {
        // ToastShort(" " + item.DText + " 已加入我的考点收藏");
        item.isCollect = 1;
        var arr = [];
        arr = arr.concat(this.state.TypeList);
        this.setState({
          TypeList: arr,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  delCollect = (item) => {
    let info = {
      ID: item.ID,
      userId: config.user.ID,
    };
    HttpUtil.post("909091_1", info).then((response) => {
      if (response.Code == 1) {
        // ToastShort("取消收藏成功");
        item.isCollect = 0;
        var arr = [];
        arr = arr.concat(this.state.TypeList);
        this.setState({
          TypeList: arr,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };

  gotoPointsInfo = (item) => {
    let query = {
      Title: item.DText,
      dictID: item.ID,
      value: item.DValue,
      GramTestQuesNum: item.RellNum,
      pageValue: 1,
    };
    this.props.navigation.navigate("KP_ContentIntegration", query);
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2 * 1.5,
            right: 0,
            textAlign: "right",
          }}
        >
          <TouchableOpacity
            style={[styles.btnSty, styles.primary]}
            onPress={() => {
              this.handleFold(
                this.state.TypeList,
                this.state.foldState ? 1 : 0
              );
            }}
          >
            <Text style={styles.primary}>
              {this.state.foldState ? "展开" : "折叠"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  // 树形切换
  treeComponent = (item, key, num, parent) => {
    return (
      <View
        key={key}
        style={{
          width: "100%",
          backgroundColor:
            item.DValue.length == 2 &&
            item.showFlag != 0 &&
            item.children &&
            item.children.length > 0
              ? "#c7e8fe"
              : "#fff",
        }}
      >
        <View style={styles.treeLine}>
          <View style={[styles.treeInLine, { width: 100 - num * 8 + "%" }]}>
            <TouchableOpacity
              style={styles.textShow}
              onPress={() => this.handleNodeClick(item, item.showFlag)}
            >
              <Text
                numberOfLines={1}
                style={[
                  styles.IndexSty,
                  {
                    fontWeight: item.DValue.length == 2 && "bold",
                    color: item.disabledFlag == 1 ? "#999999" : "#333333",
                  },
                ]}
              >
                {item.Index}
              </Text>
              <Text
                style={[
                  styles.textSty,
                  {
                    fontWeight: item.DValue.length == 2 && "bold",
                    color: item.disabledFlag == 1 ? "#999999" : "#333333",
                  },
                ]}
              >
                {item.DText}
              </Text>
            </TouchableOpacity>
            {item.children &&
              item.children.length > 0 &&
              item.showFlag === 0 && (
                <TouchableOpacity onPress={() => this.showTreeFun(item, 1)}>
                  <Image
                    source={require("../../images/public/FoldingStatus.png")}
                    style={styles.iconSty}
                  />
                </TouchableOpacity>
              )}
            {item.children &&
              item.children.length > 0 &&
              (item.showFlag === 1 || item.showFlag === undefined) && (
                <TouchableOpacity onPress={() => this.showTreeFun(item, 0)}>
                  <Image
                    source={require("../../images/public/ExpandStatus.png")}
                    style={styles.iconSty}
                  />
                </TouchableOpacity>
              )}
            {(!item.children || item.children.length == 0) &&
              item.isCollect == 0 && (
                <TouchableOpacity
                  onPress={() => this.collectOperate(item, 0, parent)}
                >
                  <Image
                    source={require("../../images/class/NotCollected.png")}
                    style={styles.iconSty}
                  />
                </TouchableOpacity>
              )}
            {(!item.children || item.children.length == 0) &&
              item.isCollect == 1 && (
                <TouchableOpacity
                  onPress={() => this.collectOperate(item, 1, parent)}
                >
                  <Image
                    source={require("../../images/class/Favorite.png")}
                    style={styles.iconSty}
                  />
                </TouchableOpacity>
              )}
          </View>
        </View>
        {item.children &&
          item.children.length > 0 &&
          (item.showFlag === 1 || item.showFlag === undefined) &&
          item.children.map((row, key) => {
            return this.treeComponent(row, key, num + 1, item.DText);
          })}
      </View>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}
          <Text
            style={{
              width: "100%",
              paddingVertical: 8,
              paddingHorizontal: 20,
              fontSize: 13,
              textAlign: "left",
              justifyContent: "center",
              lineHeight: 20,
            }}
          >
            提示：
            <Image
              source={require("../../images/class/Favorite.png")}
              style={{ width: 18, height: 18 }}
            />
            <Image
              source={require("../../images/class/NotCollected.png")}
              style={{ width: 18, height: 18, marginLeft: 5 }}
            />
            <Text
              style={{
                color: "#666",
                marginLeft: 5,
                lineHeight: 20,
              }}
            >
              {"是否收藏"}
            </Text>
          </Text>
          {this.state.pageState == 0 && <Loading></Loading>}

          {this.state.pageState == 1 && (
            <ScrollView style={{ flex: 1, backgroundColor: "#fff" }}>
              {this.state.TypeList.length > 0 &&
                this.state.TypeList.map((item, index) => {
                  return this.treeComponent(item, index, 0);
                })}
              <View style={{ width: "100%", height: config.WhiteSpace }} />
            </ScrollView>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  treeLine: {
    // height: 46,
    paddingVertical: 5,
    marginHorizontal: 20,
    borderBottomColor: "#dedede",
    borderBottomWidth: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  treeInLine: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  textShow: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    minHeight: 30,
  },
  textSty: {
    flex: 1,
    lineHeight: 25,
    fontSize: 14,
    color: "#606266",
    textAlign: "left",
  },
  IndexSty: {
    color: "#606266",
    textAlign: "center",
    lineHeight: 20,
    marginRight: 5,
  },
  iconSty: { width: 20, height: 20, marginRight: 5 },
  iconEmpSty: { width: 20, height: 20, marginRight: 5 },
  btnSty: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 5,
    marginLeft: 15,
  },
  primary: {
    backgroundColor: "#ecf5ff",
    color: "#409eff",
    fontSize: 12,
  },
});
