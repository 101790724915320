import { Dimensions } from "react-native";
const Dwidth = Dimensions.get("window").width; //414

const char = {
  // 单词
  WORD_STY: {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: 500,
    color: "#333",
  },
  WORD_MEAN_STY: {
    fontSize: 13,
    color: "#666666",
    lineHeight: 20,
  },
  // 句子
  SENTENCE_STY: {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: 500,
  },
  SENTENCE_MEAN_STY: {
    fontSize: 14,
    color: "#666666",
    lineHeight: 20,
  },
  // 段落
  PARAGRAPH_STY: {
    fontSize: 15,
    lineHeight: 24,
    color: "#000",
    fontWeight: "500",
  },
  PARAGRAPH_SPACE_STY: {
    height: 24,
    width: 80,
    borderColor: "#1c76fc",
    borderBottomWidth: 1,
  },
  PARAGRAPH_SPACE_STY_2: {
    height: 24,
    minWidth: 80,
    borderColor: "#1c76fc",
    borderBottomWidth: 1,
  },
  PARAGRAPH_MEAN_STY: {
    fontSize: 14,
    lineHeight: 20,
    color: "#666",
  },
};

export default char;
