import React from "react";
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  Dimensions,
  ScrollView,
} from "react-native";
import BaseView from "../../Component/BaseView";
import color from "../../Component/Color";
import { consolePage } from "../../Component/ToastUtlis";
import test from "../../ApublicStyle/TestStyle";
import ApublicColorFun from "../../ApublicStyle/TestColorFun";
import ReturnPHCom from "../../Component/returnPHCom";

const Dwidth = Dimensions.get("window").width;
const Lheight = Dimensions.get("window").height - 50;

export default class W_U_RecordDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.state = {
      info: "",
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    if (this.props.navigation.state.params.item) {
      let obj = this.props.navigation.state.params.item;
      obj.SubmitAnswer = JSON.parse(obj.SubmitAnswer);
      this.setState(
        {
          info: obj,
        },
        () => {
          console.log(this.state.info);
        }
      );
    }
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "W_U_RecordDetail");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  back = () => {
    this.props.navigation.pop();
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  _renderHeader() {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight,
          backgroundColor: "#fff",
          justifyContent: "flex-end",
          alignItems: "center",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        {/* <TouchableOpacity
          onPress={this.back}
          style={{
            width: 40,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight,
            left: 0,
          }}
        >
          <Image
            source={require("../../images/public/back3.png")}
            style={{ width: 21, height: 15, marginLeft: 21 }}
          />
        </TouchableOpacity> */}
        <Text style={{ fontSize: 18, color: "#333", lineHeight: 40 }}>
          等级{this.state.info.grade}
        </Text>
        {/* <TouchableOpacity onPress={() => this.open()} style={{ width: 40, heigh: 40, justifyContent: 'center', alignItems: 'center', position: 'absolute', top: BaseView.currentHeight + 8, right: 10 }}>
          <Image
            source={require('../../images/word/more.png')}
            style={{ width: 23, height: 23 }}
          />
        </TouchableOpacity> */}
      </View>
    );
  }

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          {this._renderHeader()}
          {/* 作答情况 */}
          <View
            style={{
              margin: 20,
              height: 58,
              borderRadius: 10,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}
              >
                {this.state.info.RightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                正确
              </Text>
            </View>
            <View
              style={{
                width: "34%",
                height: "70%",
                borderLeftWidth: 1,
                borderColor: "#E5E5E5",
                borderRightWidth: 1,
              }}
            >
              <Text
                style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}
              >
                {this.state.info.AllNum
                  ? this.state.info.AllNum - this.state.info.RightNum
                  : ""}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                错误
              </Text>
            </View>
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ fontSize: 18, color: "#333", textAlign: "center" }}
              >
                {this.state.info.AllNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                总数
              </Text>
            </View>
          </View>
          {this.state.info.SubmitAnswer &&
            this.state.info.SubmitAnswer.length > 0 &&
            this.state.info.SubmitAnswer.map((item, index) => {
              let ques = item.questions;
              return (
                <View
                  key={index}
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderBottomWidth:
                      this.state.info.SubmitAnswer.length - 1 == index ? 0 : 1,
                    borderBottomColor: "#e5e5e5",
                  }}
                >
                  <Text style={[test.option_index, { marginBottom: 10 }]}>
                    {index + 1 + ". " + item.meaning}
                  </Text>
                  {ques &&
                    ques.length > 0 &&
                    ques.map((i, key) => {
                      return (
                        <View key={key} style={{ marginBottom: 5 }}>
                          <Text
                            style={[
                              test.option_index,
                              {
                                color: ApublicColorFun.OptionRecord(
                                  i.word,
                                  item.myAnswer,
                                  item.word
                                ),
                              },
                            ]}
                          >
                            {String.fromCharCode(64 + parseInt(1 + key)) + ". "}
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  test.option_value_E,
                                  {
                                    color: ApublicColorFun.OptionRecord(
                                      i.word,
                                      item.myAnswer,
                                      item.word
                                    ),
                                  },
                                ]}
                              >
                                {i.word}
                              </Text>
                              <Text
                                style={[
                                  test.option_value_mean,
                                  {
                                    color: ApublicColorFun.OptionRecord(
                                      i.word,
                                      item.myAnswer,
                                      item.word
                                    ),
                                  },
                                ]}
                              >
                                {i.meaning}
                              </Text>
                            </View>
                          </Text>
                        </View>
                      );
                    })}
                  {(ques == null || ques == "") && (
                    <View>
                      <Text style={{ color: "#909399", marginBottom: 10 }}>
                        我的答案：
                        <Text
                          style={{
                            color: item.isCorrect == 1 ? "#05c08b" : "#F56C6C",
                          }}
                        >
                          {item.myAnswer}
                        </Text>
                      </Text>
                      <Text style={{ color: "#909399" }}>
                        正确答案：
                        <Text style={{ color: "#05c08b" }}>{item.word}</Text>
                      </Text>
                    </View>
                  )}
                </View>
              );
            })}
        </ScrollView>
      </View>
    );
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="#fff"
          translucent={true}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: color.background,
          }}
        >
          {/* 页面顶部 */}
          {this.testRecord()}
          <ReturnPHCom
            source={{ curPage: "extracrr" }}
            _setReturnName={this._setReturnName.bind(this)}
          ></ReturnPHCom>
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flex_box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingTop: 30,
  },
  box_item: {
    width: "45%",
    height: "30%",
    margin: 5,
    marginTop: 10,
    textAlign: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#f4f1f4",
    backgroundColor: "#f4f1f4",
    borderRadius: 5,
  },
  item_detail: {
    height: "100%",
    borderRadius: 5,
    textAlign: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    flex: 1,
    backgroundColor: "#fff",
  },
});
