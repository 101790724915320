import React from "react";
import {
  StyleSheet,
  Text,
  View,
  PermissionsAndroid,
  StatusBar,
  Platform,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../Component/BaseView";
import { FlatList } from "react-native-gesture-handler";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";

import resource from "../ApublicResourceSty";
import ReturnPHCom from "../../Component/returnPHCom";
import CustomStorage from "../../Component/CustomStorage";
import Paginator from "../../Component/Paginator";
import Empty from "../../Component/Empty";
import char from "../../ApublicStyle/CharacterStyle";
let screenW = Dimensions.get("window").width;

export default class ExampleSentenceDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.state = {
      documentTitle: this.props.navigation.state.params.Title,
      TypeID: this.props.navigation.state.params.TypeID,
      TypeBh: this.props.navigation.state.params.TypeBh,
      Word: "",
      pageTotal: 0,
      SentenceList: [],
      isLoadMore: true,
      pageState: 0,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {
    this.ValuePage();
  }

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("gSyntax/ExampleSentenceDetail", "班级学生");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };

  ValuePage = () => {
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"));
    if (storageData == null) {
      this.getData();
      return;
    }
    for (let i = 0; i < storageData["ExampleSentenceResources"].length; i++) {
      if (storageData["ExampleSentenceResources"][i].ID == this.state.TypeID) {
        this.page = storageData["ExampleSentenceResources"][i].page;
        this.getData();
        return;
      }
    }
    this.getData();
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getData = () => {
    let info = {
      Word: this.state.Word,
      TypeBH: this.state.TypeBh,
      Source: this.state.TypeID,
      pageSize: 5,
      pageStart: this.page,
    };
    if (this.state.isGlobal) {
      info.Source = "";
    }
    HttpUtil.post("7703_4", info).then((response) => {
      console.log("7703_4", response);
      if (response.Code == 1) {
        let data = response.Data;
        let total = Math.ceil(response.Num / 5);

        this.setState(
          {
            SentenceList: data,
            pageTotal: total,
            isLoadMore: false,
            pageState: 1,
          },
          () => {
            this.StorageProgress();
          }
        );
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  StorageProgress = () => {
    CustomStorage.StorageProgress(
      "ExampleSentenceResources",
      this.state.TypeID,
      this.state.documentTitle,
      this.page,
      this.state.pageTotal
    );
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  handlePageChange = (page) => {
    this.page = page - 1;
    this.getData();
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  // 加载更多
  _ListFooterComponent = () => {
    if (this.state.isLoadMore == false) {
      return this.state.SentenceList.length == 0 ? <Empty /> : <View />;
    } else {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 40,
            marginTop: 20,
            paddingBottom: 20,
          }}
        >
          <ActivityIndicator size={"small"} color={"#666"} animating={true} />
          <Text style={{ color: "#666", marginLeft: 10 }}>正在加载更多</Text>
        </View>
      );
    }
  };

  _renderItem = ({ item, index }) => {
    return (
      <View style={[styles.courseContentCon]} key={index}>
        <View style={styles.courseContentCon1}>
          <View style={styles.courseContentItem1}>
            <View style={[styles.flex_row, { alignItems: "baseline" }]}>
              <Text style={char.SENTENCE_STY}>
                {index + 1 + ".  " + item.Sentence}
              </Text>
            </View>
            <Text style={{ ...char.SENTENCE_MEAN_STY, marginTop: 8 }}>
              {item.SyntacticMeaning}
            </Text>
          </View>
        </View>
      </View>
    );
  };
  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          {/* {this._renderHeader()} */}
          <FlatList
            style={{ flex: 1, backgroundColor: "#fff" }}
            data={this.state.SentenceList}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            keyExtractor={this._keyExtractor}
            ListHeaderComponent={this._renderHeader}
            renderItem={this._renderItem}
            ListFooterComponent={this._ListFooterComponent}
            onEndReachedThreshold={0.3}
          />
          {this.state.pageState == 1 && this.state.pageTotal > 0 && (
            <View style={resource.BottomChangeCon}>
              {/* 分页器 */}
              <Paginator
                pageStart={this.page}
                totalPages={this.state.pageTotal}
                onPageChange={this.handlePageChange}
              />
            </View>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  courseContentCon: {
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    alignItems: "flex-start",
    borderColor: "#e5e5e5",
    borderBottomWidth: 1,
  },
  courseContentCon1: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  courseContentItem1: {
    flex: 1,
    marginRight: 20,
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
  },
  courseContentItem2Txt: {
    fontSize: 14,
    color: "#666666",
    marginTop: 8,
    lineHeight: 20,
  },
});
