import { Dimensions } from "react-native";

var deviceWidth = Dimensions.get("window").width;

var pub = {
  btn_sty: {
    width: "48%",
    height: 45,
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    borderColor: "#1c76fc",
    borderWidth: 1,
  },
  btn_text_sty: {
    color: "#1c76fc",
    fontSize: 16,
    textAlign: "center",
  },
  btn_sty_2: {
    width: "100%",
    height: 45,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    borderColor: "#1c76fc",
    borderWidth: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  interval_line: {
    width: 1,
    height: "100%",
    backgroundColor: "#1c76fc",
    marginHorizontal: 15,
  },
  note_txt_sty: {
    marginTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: "bold",
    textAlign: "left",
    fontSize: 14,
    color: "#0851bd",
    zIndex: 99,
  },
  note_prompt_txt_sty: {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: 16,
    color: "#B22222",
    zIndex: 99,
    marginBottom: 8,
  },
  note_extxt_sty: {
    marginTop: 40,
    paddingBottom: 20,
    fontWeight: "bold",
    textAlign: "justify",
    fontSize: 14,
    color: "#0851bd",
    zIndex: 99,
  },
  levels_title_con: {
    width: "100%",
    marginTop: "20%",
    paddingHorizontal: 20,
    marginVertical: 10,
  },
  levels_title_sty: {
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 30,
    fontSize: 20,
  },
  block_top_bottom_con: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  block_top_bottom_sty: {
    fontSize: 15,
    color: "#333333",
  },
};
export default pub;
