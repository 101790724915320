export default class ApublicColorFun {
  /**
   * @param {*} #05e944 绿
   * @param {*} #ed9b19 黄
   * @param {*} #1c76fc 蓝
   * @param {*} #909399 灰
   * @returns
   */

  // 用于选项选择之后正确错误判断
  static Option(type, doState, correctAnswer, OptionValue) {
    // 赋值对象 我的答案 正确答案 选项值
    if (type == "backgroundColor") {
      if (
        doState != undefined &&
        doState != "" &&
        doState != correctAnswer &&
        OptionValue == doState
      ) {
        return "#fef0f0";
      }
      if (doState != undefined && OptionValue == correctAnswer) {
        return "#f0f9eb";
      }
      if (doState == undefined || OptionValue != doState) {
        return "#fff";
      }
    }
    if (type == "borderColor") {
      if (
        doState != undefined &&
        doState != "" &&
        doState != correctAnswer &&
        OptionValue == doState
      ) {
        // 答错
        return "#fbc4c4";
      }
      if (doState != undefined && OptionValue == correctAnswer) {
        // 答对
        return "#c2e7b0";
      }
      if (doState == undefined || OptionValue != doState) {
        // 未答题
        return "#fff";
      }
    }
  }

  // 用于测试记录选项对错颜色
  static OptionRecord(option, myAnswer, correctAnswer) {
    // 选项 我的答案 正确答案
    if (
      (option == correctAnswer && myAnswer == "") ||
      (myAnswer != correctAnswer && option == correctAnswer)
    ) {
      return "#05c08b";
    }
    if (option == correctAnswer) {
      return "#1c76fc";
    }
    if (myAnswer != correctAnswer && myAnswer == option) {
      return "#F56C6C";
    }
    return "";
  }

  // 用于单词背诵、单词提升 分组状态
  static Group_Style(type, LatestNum, MaxNum, index) {
    // 赋值对象 最新序/当前序 最高序 序
    if (type == "backgroundColor") {
      if (LatestNum == index) {
        return "#1c76fc";
      }
      if (index == MaxNum && index != LatestNum) {
        return "#ed9b19";
      }
      if (index >= MaxNum) {
        return "#909399";
      }
      return "#00956b";
    }
    if (type == "color") {
      return "#ffffff";
    }
    if (type == "fontWeight") {
      return "normal";
    }
  }

  // 用于练习类 类答题卡的序状态
  static A_S_Color(index, quesIndex, maxIndex, doState, correctAnswer) {
    // 序 题目序 最大序 我的答案 正确答案
    // 当前正在看的题目序 ==> 蓝
    if (quesIndex == index) {
      return "#409EFF";
    }
    // 答案正确 ==> 绿
    if (doState == correctAnswer) {
      return "#67C23A";
    }
    // 答案错误 ==> 红
    if (doState != undefined && doState != "" && doState != correctAnswer) {
      return "#F56C6C";
    }
    // 当前做得最新的序 ==> 蓝
    if (maxIndex == index) {
      return "#409EFF";
    }
    // 未做 ==> 灰
    if (doState == undefined || doState == "") {
      return "#bbbbbb";
    }
  }

  // 闯关的状态
  static DareColor(type, index, Selected) {
    // 类型值 序 选中序
    if (index == Selected) {
      return "#1c76fc";
    }
    if (type == 1) {
      return "#ed9b19";
    }
    if (type == 2) {
      return "#05e944";
    }
    return "#909399";
  }
}
