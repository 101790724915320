import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";

import list2treeBh from "../../Component/list2treeBh";
import ReturnPHCom from "../../Component/returnPHCom";
import Loading from "../../Component/Loading";
import BottomBlock from "../../AmoduleCollection/BottomBlock";

import BtnTree from "../../AmoduleCollection/ButtonNavigation";

let screenW = Dimensions.get("window").width;

import resource from "../ApublicResourceSty";
export default class classifySentenceHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "分类例句",
      typeBh: "POST",
      TypeList: [],
      pageState: 0,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("gSyntax/classifySentenceHome", "分类例句");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getClassify();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getClassify = () => {
    let info = { TypeBH: this.state.typeBh };
    HttpUtil.post("77211_9", info).then((response) => {
      // console.log("77211_9", response);
      if (response.Code == 1) {
        let data = this.handleProgressShow(response.Data);
        data = list2treeBh(data, "Bh");
        console.log("77211_9 处理后data", data);
        this.setState({
          TypeList: data,
          pageState: 1,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  handleProgressShow = (Array) => {
    if (JSON.parse(localStorage.getItem("StorageProgress")) == null) {
      return Array;
    }
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"))[
      "classifySentence"
    ];
    for (let i = 0; i < Array.length; i++) {
      for (let j = 0; j < storageData.length; j++) {
        if (storageData[j].ID == Array[i].ID) {
          Array[i].FenLeiName2 =
            Array[i].FenLeiName +
            " ( " +
            (storageData[j].page + 1) +
            "/" +
            storageData[j].pageTotal +
            " )";
        }
      }
    }
    return Array;
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };

  gotoTagSyntax = (item, index, data) => {
    let query = {
      Title: item.FenLeiName,
      Bh: item.Bh,
      TypeBH: item.TypeBH,
    };
    this.props.navigation.navigate("classifySentenceDetail", query);
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  syntaxTitle = (item, index) => {
    if (item.children != null) {
      return (
        <View style={{ marginTop: 20, paddingHorizontal: 20 }} key={index}>
          <View style={resource.HomeTitleCon}>
            <View style={resource.HomeTitleBefore}></View>
            <Text style={resource.HomeTitleSty}>{item.FenLeiName}</Text>
          </View>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {item.children.length > 0 &&
              item.children.map((val, key) => {
                return this.syntaxTitle2(val, key, item);
              })}
          </View>
        </View>
      );
    } else {
      return <View key={index}></View>;
    }
  };

  syntaxTitle2 = (item, index, data) => {
    return (
      <TouchableOpacity
        onPress={() => this.gotoTagSyntax(item, index, data)}
        key={index}
        style={[resource.SubtagCon]}
      >
        <Text style={resource.SubtagSty}>
          {item.FenLeiName2 || item.FenLeiName}
        </Text>
      </TouchableOpacity>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}

          {this.state.pageState == 0 && <Loading></Loading>}

          {this.state.pageState == 1 && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ flex: 1, backgroundColor: "#fff" }}
            >
              {this.state.TypeList.length > 0 && (
                <BtnTree
                  TreeData={this.state.TypeList}
                  onClickBtn={this.gotoTagSyntax}
                />
              )}
              <BottomBlock />
            </ScrollView>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
});
