/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-01 11:12:06
 * @LastEditTime: 2019-06-01 11:12:06
 * @LastEditors: your name
 */
var color = {
  theme: "#55118a", //'#481593',//'#E60000',//'#2A88FD',//（通用颜色）
  viceTheme: "#fff4f6", //（副主题颜色）
  gray: "gray", //灰色
  white: "white", //白色
  block: "#000", //黑色
  atrovirens: "#336666", //墨绿色
  blue: "#1296db", //蓝色
  earthy_yellow: "#FFE4C4", //土黄色
  background2: "#f4f1f4", //app背景颜色
  background: "#FFF", //app背景颜色
  tabColor: "#1c76fc",

  tabTextColor: "white", //上方tab中字体的颜色

  /** 斑马纹表格颜色 */
  ZebraPattern: "#f5f5f5",
  /**
   * ScrollableTabView中的颜色
   */
  textSize: 18,
  /**
   * 字号
   */
  largeBig: 22,
  big: 18,
  above: 16,
  general: 18,
  small: 10,
  /* 主体宽度 */
  container: 20,
  primary: {
    backgroundColor: "#ecf5ff",
    color: "#409eff",
    fontSize: 12,
  },
  warning: {
    backgroundColor: "#fdf6ec",
    color: "#e6a23c",
    fontSize: 12,
  },
};
export default color;
