import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

const BtnTree = ({ TreeData, Props, onClickBtn }) => {
  const defaultProps = {
    Title: "FenLeiName",
    children: "children",
    childName: "FenLeiName2",
    childName_2: "FenLeiName",
  };

  Props = Props === undefined ? defaultProps : Props;
  const syntaxTitle = (item, index) => {
    if (item[Props.children] != null) {
      return (
        <View style={{ marginTop: 20, paddingHorizontal: 20 }} key={index}>
          <View style={styles.HomeTitleCon}>
            <View style={styles.HomeTitleBefore}></View>
            <Text style={styles.HomeTitleSty}>{item[Props.Title]}</Text>
          </View>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {item[Props.children].length > 0 &&
              item[Props.children].map((val, key) => {
                return syntaxTitle2(val, key, item);
              })}
          </View>
        </View>
      );
    } else {
      return <View key={index}></View>;
    }
  };

  const syntaxTitle2 = (item, index, data) => {
    return (
      <TouchableOpacity
        onPress={() => onClickBtn(item, index, data)}
        key={index}
        style={[styles.SubtagCon]}
      >
        <Text style={styles.SubtagSty}>
          {item[Props.childName] || item[Props.childName_2]}
        </Text>
      </TouchableOpacity>
    );
  };

  return TreeData.map((item, index) => {
    return syntaxTitle(item, index);
  });
};

var styles = StyleSheet.create({
  HomeTitleCon: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  HomeTitleBefore: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  HomeTitleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
  },
  SubtagCon: {
    padding: 10,
    marginRight: 10,
    backgroundColor: "rgba(28, 118, 252, 0.1)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    borderRadius: 6,
  },
  SubtagSty: {
    color: "#333",
    fontSize: 12,
    paddingHorizontal: 5,
    textAlign: "center",
  },
});

export default BtnTree;
