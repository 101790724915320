export default class storage {
    static load(obj) {
        return new Promise(function(resolve, reject) {
            var returnMsg=JSON.parse(localStorage.getItem(obj.key))
            console.info('返回其那信息+++++++++++++++++',obj.key,returnMsg)
            if(returnMsg!=null&&returnMsg.rawData){
                resolve(returnMsg.rawData)
            }else{
                resolve(returnMsg)
            }
            
        })
    };
    static save(obj) {
        return Promise.resolve().then(function () {
            obj.data=JSON.stringify(obj.data);
            localStorage.setItem(obj.key, obj.data);
        })
    };
}


