import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ImageBackground,
  Dimensions,
} from "react-native";
import BaseView from "../../../Component/BaseView";
import config from "../../../Component/Config";
import { consolePage } from "../../../Component/ToastUtlis";
import ReturnPHCom from "../../../Component/returnPHCom";
import pub from "../../../Component/PublicSty";
let screenW = Dimensions.get("window").width;

export default class knowledgeWordPractice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "单词练习",
      // src\PostEnglish\KnowledgePoints\KP_TestRecord.js
      PracticeBtnList1: [
        {
          name: "学习",
          router: "KnowledgePointsWords",
        },
        {
          name: "练习",
          router: "KP_WordsTestDetail",
        },
        {
          name: "记录",
          router: "KP_TestRecord",
        },
      ],
      wordSlogan: {},
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {
    if (config.wordSlogan && config.wordSlogan.length > 0) {
      var randomItem =
        config.wordSlogan[Math.floor(Math.random() * config.wordSlogan.length)];
      this.setState({
        wordSlogan: randomItem,
      });
    } else {
      this.setState({
        wordSlogan: {
          Sentence: "Knowledge is power.",
          SyntacticMeaning: "知识就是力量。",
        },
      });
    }
  }

  componentDidMount() {
    console.log(this.props.navigation.state.params);
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("class/knowledgeWordPractice", "单词练习");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/

  /*************************************************************************** 交互 ***************************************************************************/

  /*************************************************************************** 跳转 ***************************************************************************/
  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  gotoTest = (row) => {
    if (row.name == "记录") {
      let obj = this.props.navigation.state.params;
      obj.type = 1;
      this.props.navigation.navigate(
        row.router,
        this.props.navigation.state.params
      );
      return;
    }
    this.props.navigation.navigate(
      row.router,
      this.props.navigation.state.params
    );
  };

  /*************************************************************************** 组件 ***************************************************************************/

  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="#fff"
          translucent={true}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <ImageBackground
          source={require("../../../images/class/studyBackground02.png")}
          style={{ flex: 1 }}
          resizeMode="cover"
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <View style={styles.studyCon}>
              <View style={styles.studyRecordCon}>
                {this.props.navigation.state.params.documentTitle != "" &&
                  this.props.navigation.state.params.documentTitle != null &&
                  this.props.navigation.state.params.documentTitle !=
                    undefined &&
                  this.props.navigation.state.params.documentTitle !=
                    "undefined" && (
                    <Text
                      style={[
                        styles.studyRecordTxt,
                        { marginBottom: 10, fontSize: 20 },
                      ]}
                    >
                      {this.props.navigation.state.params.documentTitle}
                    </Text>
                  )}
                <Text
                  style={[
                    styles.studyRecordTxt,
                    { marginBottom: 30, fontSize: 20 },
                  ]}
                >
                  单词练习
                </Text>
                <Text style={styles.studyRecordTxt1}>
                  {this.state.wordSlogan.Sentence}
                </Text>
                <Text style={styles.studyRecordTxt1}>
                  {this.state.wordSlogan.SyntacticMeaning}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {this.state.PracticeBtnList1.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => this.gotoTest(item)}
                      style={[
                        pub.btn_sty,
                        {
                          width: "30%",
                          marginBottom: 10,
                          backgroundColor: "rgb(255 255 255 / 46%)",
                        },
                      ]}
                    >
                      <Text style={[pub.btn_text_sty]}>{item.name}</Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </View>
          </View>
        </ImageBackground>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  btnSty: {
    width: "40%",
    height: 45,
    backgroundColor: "#1c76fc",
    borderRadius: 5,
    justifyContent: "center",
  },
  studyRecordCon: {
    width: "100%",
    marginTop: "50%",
    marginBottom: 80,
    paddingHorizontal: 20,
  },
  studyRecordTxt: {
    width: "100%",
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  studyRecordTxt1: {
    width: "100%",
    textAlign: "left",
    fontSize: 16,
    marginBottom: 12,
  },
  studyCon: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  studyRecordCon: {
    width: "100%",
    paddingHorizontal: 20,
    marginVertical: 10,
  },
  studyRecordTxt: {
    width: "100%",
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 20,
  },

  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // paddingTop: 50,
    position: "relative",
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 8,
  },
  cardButton: {
    width: 160,
    height: 150,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 90,
    // backgroundColor: '#ffffff8c',
    backgroundColor: "#1c76fc1c",
    // shadowColor: 'rgba(50, 50, 93, 0.25)',
    // shadowOffset: { width: 0, height: 2 },
    // shadowRadius: 5,
    // shadowOpacity: 1,
    // elevation: 3,
    marginHorizontal: 4,
    position: "relative",
  },
  cardButton2: {
    width: 100,
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#1c76fc1c",
    // shadowColor: 'rgba(50, 50, 93, 0.25)',
    // shadowOffset: { width: 0, height: 2 },
    // shadowRadius: 5,
    // shadowOpacity: 1,
    // elevation: 3,
    position: "absolute",
    top: 260,
    zIndex: 1,
  },
  card1: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  card2: {
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  card3: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  card4: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
  },

  arc: {
    position: "absolute",
    width: 53,
    height: 53,
    zIndex: 1,
    borderRadius: 0,
    backgroundColor: "#fff",
  },
  arc1: {
    bottom: -1,
    right: -1,
    borderTopColor: "#fff",
    borderTopWidth: 1,
    borderLeftColor: "#fff",
    borderLeftWidth: 1,
    borderTopLeftRadius: 50,
  },
  arc2: {
    bottom: -1,
    left: -1,
    borderTopColor: "#fff",
    borderTopWidth: 1,
    borderRightColor: "#fff",
    borderRightWidth: 1,
    borderTopRightRadius: 50,
  },
  arc3: {
    top: -1,
    right: -1,
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
    borderLeftColor: "#fff",
    borderLeftWidth: 1,
    borderBottomLeftRadius: 50,
  },
  arc4: {
    top: -1,
    left: -1,
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
    borderRightColor: "#fff",
    borderRightWidth: 1,
    borderBottomRightRadius: 50,
  },
});
