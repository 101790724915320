import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ImageBackground,
  ScrollView,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import ReturnPHCom from "../../Component/returnPHCom";
import pub from "../../Component/PublicSty";
import dare from "../../ApublicStyle/ChallengeStyle";
import ApublicColorFun from "../../ApublicStyle/TestColorFun";
let screenW = Dimensions.get("window").width;
const Dwidth = Dimensions.get("window").width;
const Dheight = Dimensions.get("window").height;

export default class W_U_Detail_Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "单词提升",
      bookName: "",
      parentId: "",
      list: [],
      grade: 0,
      myMaxGrade: 0,
      maxGrade: 0,
      GradeList: [],
      wordSlogan: {},
      Btn_List: [
        { name: "浏览", function: "gotoStudy" },
        { name: "学习", function: "gotoPractice" },
        { name: "测试", function: "gotoTest" },
        { name: "记录", function: "gotoRecord" },
      ],
      // 数据
      MaxGroup: 0, // 最高闯关序
      LatestGroupNum: 1,
      GroupNumList: [],
      Words: [],
      StepRecored: [],
      StorageUnit: "",
      LoadState: true,
      pageState: 0,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {
    if (config.wordSlogan && config.wordSlogan.length > 0) {
      var randomItem =
        config.wordSlogan[Math.floor(Math.random() * config.wordSlogan.length)];
      this.setState({
        wordSlogan: randomItem,
      });
    } else {
      this.setState({
        wordSlogan: {
          Sentence: "Knowledge is power.",
          SyntacticMeaning: "知识就是力量。",
        },
      });
    }
  }

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("W_U_Detail_Home");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      if (this.props.navigation.state.params.item) {
        let obj = this.props.navigation.state.params.item;
        this.setState(
          {
            bookName: obj.menuName,
            parentId: obj.id,
          },
          () => {
            this.getNum();
          }
        );
      } else {
        this.initData();
      }
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getQuestions = () => {
    let info = {
      parentId: this.state.parentId,
      StudentId: config.user.ID,
    };
    HttpUtil.post("81040_1", info).then((res) => {
      let data = res.Data;
      console.log("81040_1", data);
      this.setState({
        grade: data,
        myMaxGrade: data,
      });
    });
  };

  getMaxGrade = () => {
    let info = {
      parentId: this.state.parentId,
    };
    let api = "";
    if (
      this.props.navigation.state.params.item &&
      this.props.navigation.state.params.item.type == "heigh"
    ) {
      api = "81010_1";
    } else {
      api = "81020_1";
    }
    HttpUtil.post(api, info).then((response) => {
      console.log(api, response);
      if (response.Code == 1) {
        let data = response.Data;
        let arr = [];
        for (let i = 0; i < data; i++) {
          arr.push(i + 1);
        }
        this.setState({
          maxGrade: data,
          GradeList: arr,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  getNum = () => {
    let info = {
      parentId: this.state.parentId,
      StudentId: config.user.ID,
      ClassId: config.classMsg.ClassId,
    };
    HttpUtil.post("81040_2", info).then((response) => {
      console.log("81040_2", response);
      if (response.Code == 1) {
        let data = response.Data;
        let passNum = Number(data[0].grade);
        let arr = [];
        for (let i = 0; i < data[0].allNum; i++) {
          arr.push({
            name: String(i + 1),
            isClearance: i + 1 == passNum ? 1 : i + 1 < passNum ? 2 : 0,
            // 1: 当前 2：已过 0：未通
          });
        }
        this.setState(
          {
            GradeList: arr,
            grade: this.state.grade == 0 ? data[0].grade : this.state.grade,
            myMaxGrade: data[0].grade,
            pageState: 1,
          },
          () => {
            this.initData();
          }
        );
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  /************************************************************************* 分组学习 *************************************************************************/

  initData = () => {
    let Storage = JSON.parse(localStorage.getItem("WordImprovement"));
    if (Storage == null) {
      this.getData();
      return;
    }
    let noyExist = true;
    for (let i = 0; i < Storage.length; i++) {
      if (
        this.state.parentId == Storage[i].BookId &&
        this.state.grade == Storage[i].UnitId
      ) {
        // console.log(Storage[i]);
        noyExist = false;
        let arr = [];
        for (let j = 0; j < Storage[i].MaxGroupNum; j++) {
          arr.push(j + 1);
        }
        this.setState({
          LatestGroupNum: Storage[i].LatestGroupNum,
          GroupNumList: arr,
          MaxGroup: Storage[i].MaxGroup || 1,
          Words: Storage[i].wordArr[Storage[i].LatestGroupNum - 1],
          StepRecored: Storage[i].StepRecored[Storage[i].LatestGroupNum - 1],
          StorageUnit: Storage[i],
          LoadState: false,
        });
      }
    }
    // 本地存储不存在本书本单元
    if (noyExist) {
      this.getData();
      return;
    }
  };

  getData = () => {
    let info = {
      word: "",
      parentId: this.state.parentId,
      changdu: "",
      grade: this.state.grade,
    };
    let api = "";
    if (
      this.props.navigation.state.params.item &&
      this.props.navigation.state.params.item.type == "heigh"
    ) {
      api = "81010";
    } else {
      api = "81020";
    }
    HttpUtil.post(api, info).then((response) => {
      console.log(api, response);
      if (response.Code == 1) {
        let data = response.Data;
        this.handleStorage(data);
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  handleStorage = (data) => {
    let Storage = JSON.parse(localStorage.getItem("WordImprovement")) || [];
    let DataMap = data.map((item) => {
      return {
        word: item.word,
        meaning: item.meaning,
      };
    });
    // 判断取成几组单词
    let GroupEach = 10;
    let addNum = data.length % GroupEach > 6 ? 1 : 0;
    let GroupNum = Math.floor(data.length / GroupEach) + addNum;
    console.log("----------", "分成[ " + GroupNum + " ]组");
    // 每一组的单词 未随机数量
    let wordArr = [];
    if (GroupNum > 1) {
      for (let i = 0; i < GroupNum; i++) {
        let GroupEach2 =
          GroupNum == i + 1 ? data.length - GroupEach * i : GroupEach;
        let arr = DataMap.slice(i * GroupEach, i * GroupEach + GroupEach2);
        wordArr.push(arr);
      }
    } else {
      wordArr = [DataMap];
    }
    console.log("----------", "单词分组", wordArr);
    // 初始化 每一组单词的学习记录
    let record = [];
    for (let i = 0; i < GroupNum; i++) {
      record.push({
        Step1: [],
      });
    }
    console.log("----------", "初始化 每一组单词的学习记录", record);
    // 处理存储对象
    let obj = {
      BookId: this.state.parentId,
      UnitId: this.state.grade,
      LatestGroupNum: 1,
      MaxGroup: 1,
      MaxGroupNum: GroupNum,
      wordArr: wordArr,
      StepRecored: record,
    };
    console.log("----------", "初始化 存储值", obj);
    // 最终的存储数据
    Storage.push(obj);
    console.log("----------", "存储数据", Storage);
    // State 赋值
    let GroupNumList = [];
    for (let i = 0; i < GroupNum; i++) {
      GroupNumList.push(i + 1);
    }
    this.setState(
      {
        LatestGroupNum: 1,
        MaxGroup: 1,
        GroupNumList: GroupNumList,
        Words: wordArr[0],
        StepRecored: record[0],
        StorageUnit: obj,
        LoadState: false,
      },
      () => {
        localStorage.setItem("WordImprovement", JSON.stringify(Storage));
      }
    );
  };

  /*************************************************************************** 交互 ***************************************************************************/
  gotoDetail = (item) => {
    if (item.isClearance == 0) {
      return;
    }
    this.setState(
      {
        grade: item.name,
        LoadState: true,
      },
      () => {
        this.initData();
      }
    );
  };

  Btn_Click = (item) => {
    if (item.function == "") {
      console.log(item);
      return;
    }
    this[item.function]();
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };

  gotoStudy = () => {
    let obj = {
      id: this.props.navigation.state.params.item.id,
      menuName: this.props.navigation.state.params.item.menuName,
      grade: this.state.grade,
    };
    this.props.navigation.navigate("W_U_Browsing", {
      item: obj,
    });
  };

  gotoPractice = () => {
    let query = {
      parentId: this.state.parentId,
      grade: this.state.grade,
      menuName: this.props.navigation.state.params.item.menuName,
    };
    // console.log(query);
    this.props.navigation.navigate("W_U_Study_T", query);
  };

  gotoTest = (type) => {
    this.props.navigation.navigate("W_U_GoGo", {
      bookId: this.state.parentId,
      grade: this.state.grade,
      menuName: this.props.navigation.state.params.item.menuName,
    });
  };

  gotoRecord = () => {
    this.props.navigation.navigate(
      "W_U_Record",
      this.props.navigation.state.params
    );
  };

  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
            fontWeight: "bold",
          }}
        >
          {this.state.bookName}
        </Text>
      </View>
    );
  };

  _renderItem = (item, index) => {
    return (
      <TouchableOpacity
        onPress={() => this.gotoDetail(item)}
        key={index}
        style={[
          dare.Btn_Sty,
          {
            backgroundColor: ApublicColorFun.DareColor(
              item.isClearance,
              item.name,
              this.state.grade
            ),
            marginLeft: index % dare.Row_Num != 0 && dare.Margin_Space,
          },
        ]}
      >
        <Text style={dare.Btn_Font_Sty}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  _renderLoading = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 40,
          marginVertical: 10,
        }}
      >
        <ActivityIndicator size={"small"} color={"#1c76fc"} animating={true} />
        <Text
          style={{
            color: "#1c76fc",
            lineHeight: 40,
            height: "100%",
            marginLeft: 10,
          }}
        >
          正在加载
        </Text>
      </View>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="#fff"
          translucent={true}
          barStyle={"dark-content"}
        />
        <ImageBackground
          source={require("../../images/class/studyBackground01.png")}
          style={{ flex: 1 }}
          resizeMode="cover"
        >
          <ScrollView
            style={{
              width: "100%",
              height: Dimensions.get("window").height,
            }}
            showsVerticalScrollIndicator={false}
          >
            <View style={styles.studyRecordCon}>
              <Text
                style={[
                  styles.studyRecordTxt,
                  { marginBottom: 30, fontSize: 20 },
                ]}
              >
                {this.state.bookName}
              </Text>
              <Text style={styles.studyRecordTxt}>
                您当前选中的等级为：{this.state.grade}
              </Text>
            </View>
            <View
              style={{
                alignItems:
                  this.state.GradeList.length > dare.Row_Num
                    ? "flex-start"
                    : "center",
                justifyContent: "flex-start",
              }}
            >
              <View
                style={{
                  paddingVertical: 10,
                  paddingHorizontal: 20,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {this.state.pageState == 1 &&
                  this.state.GradeList.length > 0 &&
                  this.state.GradeList.map((item, index) => {
                    return this._renderItem(item, index);
                  })}
                {/* 加载状态 */}
                {this.state.pageState == 0 && (
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                      height: 30,
                      backgroundColor: "transparent",
                    }}
                  >
                    <ActivityIndicator
                      size={"small"}
                      color={"#666"}
                      animating={true}
                    />
                    <Text
                      style={{
                        color: "#666",
                        lineHeight: 30,
                        height: "100%",
                        marginLeft: 10,
                      }}
                    >
                      正在加载
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: 20,
                marginTop: 20,
              }}
            >
              {this.state.Btn_List.map((item, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => this.Btn_Click(item)}
                    style={[
                      pub.btn_sty,
                      {
                        width: "23.5%",
                        marginBottom: 10,
                        backgroundColor: "rgb(255 255 255 / 46%)",
                      },
                    ]}
                  >
                    <Text style={[pub.btn_text_sty]}>{item.name}</Text>
                  </TouchableOpacity>
                );
              })}
            </View>
            <Text style={[pub.note_extxt_sty, { paddingHorizontal: 20 }]}>
              <View style={{ width: "100%" }}>
                <Text style={pub.note_prompt_txt_sty}>提示：</Text>
              </View>
              单词提升单词为升级制，当正确率等于100%时可挑战下一等级。分组学习会根据各等级单词词汇量进行分组，根据分组进行背诵学习。你的潜力是无限的，继续挖掘吧！
            </Text>
          </ScrollView>
        </ImageBackground>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  btnSty: {
    width: "30%",
    height: 45,
    backgroundColor: "#1c76fc",
    borderRadius: 25,
    justifyContent: "center",
  },
  studyRecordCon: {
    width: "100%",
    marginTop: "20%",
    paddingHorizontal: 20,
    marginVertical: 10,
  },
  studyRecordTxt: {
    width: "100%",
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 20,
  },
  studyRecordTxt1: {
    width: "100%",
    textAlign: "left",
    fontSize: 16,
    marginBottom: 12,
  },
  studyCon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  /* 分组学习 组列表 */
  GroupContainer: {
    flex: 1,
    height: "100%",
    paddingRight: pub.interval_line.marginHorizontal,
    flexDirection: "row",
    alignItems: "center",
  },
  titleCon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  titleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
    marginRight: 10,
  },
});
