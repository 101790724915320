import { Dimensions } from "react-native";
const Dwidth = Dimensions.get("window").width; //414

var tagsStyles = {
  p: {
    lineHeight: 20,
    margin: 0,
    padding: 0,
    whiteSpace: "pre",
  },
  img: {
    maxWidth: Dwidth,
    resizeMode: "center",
  },
  td: {
    borderWidth: 1,
    borderColor: "#e5e5e5",
  },
  span: {
    whiteSpace: "pre",
    lineHeight: 20,
  },
  h4: {
    marginVertical: 8,
    fontSize: 15,
  },
};

export default tagsStyles;

/* 一般直接引用 */
/**
 * tagsStyles={tagsStyles}
 */

/* 引用另需修改 */
/**
 * tagsStyles={{
      p: {
        ...tagsStyles.p,
        whiteSpace: "pre",
      },
      span: {
        ...tagsStyles.span,
      },
      img: {
        ...tagsStyles.img,
      },
      td: {
        ...tagsStyles.td,
      },
    }}
 */
