/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-01 11:12:18
 * @LastEditTime: 2019-08-14 09:48:50
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from "react";
import { StyleSheet, View, Dimensions, StatusBar, Image } from "react-native";

import { StackActions, NavigationActions } from "react-navigation";
import BaseView from "./Component/BaseView";

var deviceWidth = Dimensions.get("window").width;

import config from "./Component/Config";
import Dimension from "./Component/Dimension";
import HttpUtil from "./Component/HttpUtil";
import storage from "./Component/storage";
import { ToastShort } from "./Component/ToastUtlis";
import Color from "./Component/Color";
import StorageUtils from "./Component/StorageUtils";
import * as dd from "dingtalk-jsapi";

export default class ViewPager extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      time: 1,
      viewImag: null,
      username: "",
    };

    var timer = null;
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    var ua = navigator.userAgent.toLowerCase();
    console.info("ua______", ua, ua.match(/wxwork/i) == "wxwork");
    // this.getUrl();
    this.getStorage();
  }

  jumpTabpage = () => {
    const resetAction = StackActions.reset({
      index: 0,
      actions: [
        NavigationActions.navigate({
          routeName: "Tab",
        }),
      ],
    });
    this.props.navigation.dispatch(resetAction);
  };

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  getUrl() {
    let url = window.location.href;
    console.info("url", url);
    if (url.indexOf("?") != -1) {
      let obj = {};
      let arr = url.slice(url.indexOf("?") + 1).split("&");
      arr.forEach((item) => {
        let param = item.split("=");
        obj[param[0]] = param[1];
      });
      console.log("获取到参数", obj);
      if (obj.type === "wxBind") {
        //微信绑定
        if (obj.code) {
          if (localStorage.getItem("shareInfo")) {
            // 分享链接绑定 有shareUser信息
            console.log("分享链接绑定 有shareUser信息");
            this.getWxIdAndBindClass(obj.code);
          } else {
            // 绑定微信 非分享链接
            this.getWxId(obj.code);
          }
        } else {
          //未获取到code
          console.info("wxBind,未获取到code");
          this.getStorage();
        }
      } else if (obj.type === "wxShare") {
        var shareObj = {};
        shareObj = {
          shareUserID: obj.UserID,
          shareGroupID: obj.GroupID,
        };
        localStorage.setItem("shareInfo", JSON.stringify(shareObj));
        var appid = "wx7f217e7eeca63426";
        var local = "http://math.math110.com/?type=wxBind";
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_base#wechat_redirect`;
      } else {
        //其他
        // this.getStorage();
      }
    } else {
      console.log("没有参数,直接进");
      this.getStorage();
      return null;
    }
  }

  GetInfo = () => {
    HttpUtil.post("40009").then((response) => {
      console.info("个人信息UserInfo", response);
      storage.save({
        key: "user",
        data: response.Data,
      });
      config.user = response.Data;
      setTimeout(() => {
        const resetAction = StackActions.reset({
          index: 0,
          actions: [
            NavigationActions.navigate({
              routeName: "Tab",
            }),
          ],
        });
        this.props.navigation.dispatch(resetAction);
      }, 1000);
    });
  };

  getWxIdAndBindClass = (objCode) => {
    let info = {
      js_code: objCode,
    };
    console.log("getWxId code", objCode, info);
    HttpUtil.post("8000_1", info).then((response) => {
      console.log("getWxId response", response);
      if (response.Code == 1) {
        localStorage.setItem("MyWxID", JSON.stringify(response.Data.openid));
        storage
          .load({
            key: "user",
            autoSync: false,
          })
          .then((ret) => {
            if (ret == null || ret == undefined) {
              this.getStorage();
            } else {
              this.bindWx(response.Data.openid, ret);
            }
          })
          .catch((e) => {
            this.getStorage();
          });
        this.joinClass(response.Data.openid);
      } else {
        console.log("8000_1 获取微信ID出错", response.Msg);
      }
    });
  };

  joinClass = (wxId) => {
    var info = {
      ClassId: JSON.parse(localStorage.getItem("shareInfo")).shareGroupID,
      WxId: wxId,
    };
    console.info("1110info", info);
    localStorage.removeItem("shareInfo");
    HttpUtil.post("1110", info).then((response) => {
      console.info("1110加入班级", response);
      if (response.Code == 1) {
        this.getStorage();
      } else {
        ToastShort(response.Msg);
        this.getStorage();
      }
    });
  };

  getWxId = (code) => {
    let info = {
      js_code: code,
    };
    console.log("getWxId code", code, info);
    HttpUtil.post("8000_1", info).then((response) => {
      console.log("getWxId response", response);
      if (response.Code == 1) {
        localStorage.setItem("MyWxID", JSON.stringify(response.Data.openid));
        storage
          .load({
            key: "user",
            autoSync: false,
          })
          .then((ret) => {
            if (ret == null || ret == undefined) {
              this.getStorage();
            } else {
              this.bindWx(response.Data.openid, ret);
            }
          })
          .catch((e) => {
            this.getStorage();
          });
      } else {
        console.log("8000_1 获取微信ID出错", response.Msg);
        this.getStorage();
      }
    });
  };

  bindWx = (wxId, user) => {
    var param = {
      UserId: user.ID,
      WxId: wxId,
      Type: 0,
    };
    console.log(param);
    var that = this;
    HttpUtil.post("1117", param)
      .then((response) => {
        console.info("绑定微信id--", response);
        if (response.Code == "1") {
          ToastShort("绑定成功");
          // this.getStorage();
          this.GetInfo();
        } else if (response.Code == "2") {
          ToastShort(response.Msg);
          var resetAction = StackActions.reset({
            index: 0,
            actions: [
              NavigationActions.navigate({
                routeName: "Login",
              }),
            ],
          });
          that.props.navigation.dispatch(resetAction);
        } else {
          ToastShort("您的微信已绑定账号");
          this.getStorage();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getStorage = () => {
    var that = this;
    storage
      .load({
        key: "user",
        autoSync: false,
      })
      .then((ret) => {
        console.info("进入获取缓存", ret);
        if (ret == null || ret == undefined) {
          const resetAction = StackActions.reset({
            index: 0,
            actions: [
              NavigationActions.navigate({
                routeName: "Login",
              }),
            ],
          });
          that.props.navigation.dispatch(resetAction);
        } else {
          HttpUtil.post("40009").then((response) => {
            console.info("个人信息UserInfo", response);
            // token失效报错
            if (response.Code == 1002) {
              const resetAction = StackActions.reset({
                index: 0,
                actions: [
                  NavigationActions.navigate({
                    routeName: "Login",
                  }),
                ],
              });
              that.props.navigation.dispatch(resetAction);
            } else {
              config.user = ret;
              this.setState({
                username: ret,
              });
              setTimeout(() => {
                const resetAction = StackActions.reset({
                  index: 0,
                  actions: [
                    NavigationActions.navigate({
                      routeName: "Tab",
                    }),
                  ],
                });
                that.props.navigation.dispatch(resetAction);
              }, 1000);
            }
          });
        }
      })
      .catch((e) => {
        this.setState({
          username: "",
        });
      });
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar hidden={true} />
        <View style={styles.buttontop}></View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  page: {
    width: Dimension.width,
    height: Dimension.height,
  },
  button: {
    flexDirection: "row",
    width: 200,
    height: 40,
  },
  buttontop: {
    position: "absolute",
    top: -1,
    right: -1,
    zIndex: 255,
  },
  bottomView: {
    width: Dimension.width,
    height: 40,
    position: "absolute",
    bottom: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonStyle: {
    width: 150,
    height: 40,
    backgroundColor: "blue",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },
  headImage: {
    width: deviceWidth,
    height: 150,
  },
});
