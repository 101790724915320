/*
 * @Description: In User Settings Ed
 * @Author: your name
 * @Date: 2019-06-01 11:12:18
 * @LastEditTime: 2019-10-12 19:02:54
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  StatusBar,
  TextInput,
  DeviceEventEmitter,
  ImageBackground,
  Animated,
} from "react-native";
import { StackActions, NavigationActions } from "react-navigation";

import config from "../Component/Config";
import base from "../Component/base64";
import { ToastShort, consolePage } from "../Component/ToastUtlis";
import Color from "../Component/Color";
import storage from "../Component/StorageUtils";
import HttpUtil from "../Component/HttpUtil";
import BaseView from "../Component/BaseView";

export default class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      time: 3,
      viewImag: null,
      language: "",
      username: "",
      password: "",
      school: "",
      schoolId: "",
      deptChageModalVisible: false,
      securetyTipViewY: new Animated.Value(500),
      confirmHeight: new Animated.Value(300),
      deptSetList: [],
      userID: "",
    };
    var timer = null;
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.lister = DeviceEventEmitter.addListener("editSchool", (res) => {
      this.setState({
        school: res.school.SchoolName,
        schoolId: res.school.ID,
      });
    });
  }

  componentWillUnmount() {
    this.lister && this.lister.remove();
  }

  selectSchool = () => {};

  setUserName = (text) => {
    this.setState({
      username: text,
    });
  };

  setPassword = (text) => {
    this.setState({
      password: text,
    });
  };

  getWeek = (dt) => {
    let d1 = new Date(dt);
    let d2 = new Date(dt);
    d2.setMonth(0);
    d2.setDate(3); //3 周一为本周第一天；2 周日为本周第一天；1 周六为本周第一天
    let rq = d1 - d2;
    let days = Math.ceil(rq / (24 * 60 * 60 * 1000));
    let num = Math.ceil(days / 7);
    return num + 1;
  };

  login = () => {
    if (this.state.username == "" || this.state.username.trim() == "") {
      ToastShort("请输入用户名");
      return;
    }
    if (this.state.password == "" || this.state.password.trim() == "") {
      ToastShort("请输入密码");
      return;
    }
    var username = this.state.username;
    var password = this.state.password;
    var info = {
      login: username,
      pwd: password,
    };
    console.info("登录信息", info);
    HttpUtil.post("40008", info).then((response) => {
      console.info("个人信息response", response);
      if (response.Code == 1) {
        if (response.token) {
          storage.save({
            key: "userToken",
            data: response.token,
          });
          storage.save({
            key: "user",
            data: response.Data,
          });
          config.userToken = response.token;
          config.user = response.Data;
          setTimeout(() => {
            const resetAction = StackActions.reset({
              index: 0,
              actions: [
                NavigationActions.navigate({
                  routeName: "Tab",
                }),
              ],
            });
            this.props.navigation.dispatch(resetAction);
          }, 1000);
          // this.bindWexin();
          // this.GetInfo();
          // if (
          //   response.Data.wxId != null &&
          //   response.Data.wxId != undefined &&
          //   response.Data.wxId != ""
          // ) {
          //   this.props.navigation.navigate("Tab");
          // } else {
          //   this.bindWexin();
          // }
        } else {
          storage.save({
            key: "userToken",
            data: "",
          });
          config.userToken = "";
        }
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  GetInfo = () => {
    HttpUtil.post("40009").then((response) => {
      console.info("个人信息UserInfo", response);
      storage.save({
        key: "user",
        data: response.result,
      });
      config.user = response.result;
      this.props.navigation.navigate("Tab");
    });
  };

  bindWexin = () => {
    var appid = "wx7f217e7eeca63426";
    var local = "http://math.math110.com/?type=wxBind";
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
      local
    )}&response_type=code&scope=snsapi_base#wechat_redirect`;
  };

  scaleCode = () => {
    this.props.navigation.navigate("ScanCode");
  };

  gotoresigiterSelf = () => {
    this.props.navigation.navigate("ResigiterSelf");
  };

  selectSchool = () => {
    this.props.navigation.navigate("SelectSchool");
  };

  gotoforgot = () => {
    this.props.navigation.navigate("forgot");
  };

  // 展示View
  _showTipView = () => {
    Animated.timing(this.state.securetyTipViewY, {
      toValue: 50,
      duration: 300, //动画时长300毫秒
      useNativeDriver: true, // <-- Add this
    }).start();
  };

  // 隐藏view
  _hiddenTipView = () => {
    var that = this;
    Animated.timing(this.state.securetyTipViewY, {
      toValue: Dimensions.get("window").height,
      duration: 200, //动画时长300毫秒
      useNativeDriver: true, // <-- Add this
    }).start(({ finished }) => {
      that.setState({ deptChageModalVisible: false });
    });
  };

  render() {
    return (
      <BaseView translucent={1} style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(255,255,255,0)"}
          barStyle={"light-content"}
          translucent={true}
        />
        <ImageBackground
          style={{ width: "100%", height: "100%" }}
          source={require("../images/login/bg2.jpeg")}
        >
          <View
            style={{
              width: "100%",
              height: 60,
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45%",
            }}
          >
            <Text
              style={{ color: "#1c76fc", fontSize: 31, fontWeight: "bold" }}
            >
              考研学习
            </Text>
          </View>
          <View
            style={{
              marginTop: 20,
              width: "78%",
              height: 40,
              marginLeft: "12%",
              flexDirection: "row",
              alignItems: "center",
              borderBottomColor: "gray",
              borderBottomWidth: 1,
            }}
          >
            <Image
              source={require("../images/login/username.png")}
              style={{ width: 20, height: 20 }}
            />
            <View
              style={{
                flex: 1,
                height: 40,
                justifyContent: "center",
                marginLeft: 15,
              }}
            >
              <TextInput
                style={styles.password}
                placeholder="请输入账号"
                placeholderTextColor={"gray"}
                selectionColor={"gray"}
                keyboardType="default"
                underlineColorAndroid="transparent"
                secureTextEntry={false}
                clearButtonMode="while-editing"
                onChangeText={(text) => this.setUserName(text)}
              />
            </View>
          </View>
          <View
            style={{
              marginTop: 15,
              width: "78%",
              height: 40,
              marginLeft: "12%",
              flexDirection: "row",
              alignItems: "center",
              borderBottomColor: "gray",
              borderBottomWidth: 1,
            }}
          >
            <Image
              source={require("../images/login/password.png")}
              style={{ width: 20, height: 20 }}
            />
            <View
              style={{
                flex: 1,
                height: 40,
                justifyContent: "center",
                marginLeft: 15,
              }}
            >
              <TextInput
                style={styles.password}
                placeholder="请输入密码"
                placeholderTextColor={"gray"}
                selectionColor={"gray"}
                keyboardType="default"
                underlineColorAndroid="transparent"
                secureTextEntry={true}
                clearButtonMode="while-editing"
                onChangeText={(text) => this.setPassword(text)}
              />
            </View>
          </View>
          <View
            style={{
              width: "90%",
              height: 40,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 60,
              marginLeft: "6%",
            }}
          >
            <TouchableOpacity
              onPress={this.login}
              style={{
                width: "90%",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#1c76fc",
                borderRadius: 20,
              }}
            >
              <Text style={{ color: "white", fontSize: 16 }}>登 录</Text>
            </TouchableOpacity>
          </View>
          {/* <View
            style={{
              width: "90%",
              height: 40,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginLeft: "6%",
            }}
          >
            <TouchableOpacity
              onPress={this.scaleCode}
              style={{
                width: "90%",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#008d8d",
                borderRadius: 20,
              }}
            >
              <Text style={{ color: "white", fontSize: 16 }}>扫 一 扫</Text>
            </TouchableOpacity>
          </View> */}
          <View
            style={{
              width: "100%",
              height: 40,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => this.gotoresigiterSelf()}
              style={{
                width: "40%",
                height: 40,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "#1c76fc", fontSize: 12 }}>
                {"注册账号"}
              </Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  password: {
    width: "100%",
    height: 40,
    outlineWidth: 0,
  },
  container: {
    flex: 1,
    backgroundColor: Color.white,
  },
});
