import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Image,
  TextInput,
  DeviceEventEmitter,
  Alert,
  ScrollView,
} from "react-native";
import BaseView from "../Component/BaseView";
import { ToastShort, consolePage } from "../Component/ToastUtlis";
import config from "../Component/Config";
import storage from "../Component/StorageUtils";
import { StackActions, NavigationActions } from "react-navigation";
import HttpUtil from "../Component/HttpUtil";
import Modal, {
  ModalTitle,
  ModalFooter,
  ModalButton,
  ModalContent,
  ScaleAnimation,
} from "react-native-modals";

import Modal2 from "react-native-modals";

import ModalSty from "../ApublicStyle/ModalStyle";

export default class My extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headImg: "",
      user: "",
      qiandao: false,
      switch: false,
      modalVisible: false,
      modalVisible2: false,
      modalVisible3: false,
      chose1: 1,
      chose2: false,
      chose3: false,
      chose4: false,
      visible: false,
      peopleNum: 0,
      endDate: "",
      ChargeFlag: false,
      UserName: "",
      LoginNum: "",
      wxIDState: 0,
      bindFlag: false,
      inputText: 0.5,
      inputText2: config.user.userName,
      userName2: config.user.userName,
      // 本地数据
      Value: [],
      OptionData: [
        { name: "单词学习", value: 1 },
        { name: "教材学习报告", value: 2 },
        { name: "单词分析", value: 3 },
      ],
      MessageBoxVisible: false,
      MessageBoxVisible2: false,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      var showflag = false;
      if (
        config.user != null &&
        config.user != "" &&
        config.user != undefined &&
        ((config.user.wxId != null &&
          config.user.wxId != undefined &&
          config.user.wxId != "") ||
          (config.user.wxIdtwo != null &&
            config.user.wxIdtwo != undefined &&
            config.user.wxIdtwo != "") ||
          (config.user.wxIdthree != null &&
            config.user.wxIdthree != undefined &&
            config.user.wxIdthree != ""))
      ) {
        showflag = true;
      } else {
        showflag = false;
      }
      this.setState({
        user: config.user,
        UserName: config.user.userName,
        LoginNum: config.user.login,
        chose1: config.isSwitch,
        headImg: config.user.userPic ? config.user.userPic : "",
        endDate: config.user.vipEndtime,
        bindFlag: showflag,
      });
    });
    this.getIsSwitch();
    this.MySignState();
  }

  componentDidMount() {
    console.log("config.user", config.user);
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  getIsSwitch = () => {
    if (config.isSwitch == "") {
      this.setState({
        switch: false,
      });
    } else {
      this.setState({
        switch: config.isSwitch,
      });
    }
  };

  onBackAndroid = () => {
    if (this.state.modalVisible == true) {
      this.setState({
        modalVisible: false,
      });
    } else {
      this.props.navigation.pop();
    }
    return true;
  };

  gotoSCB = () => {
    this.props.navigation.navigate("NewWords");
  };

  passChange = () => {
    this.props.navigation.navigate("PassChange");
  };

  practice = () => {
    this.props.navigation.navigate("Practice");
  };

  quitLogin = () => {
    Alert.alert("", "退出登录后将无法查看个人信息,重新登录后即可查看", [
      { text: "确定", onPress: this.makeQuit },
      { text: "取消", onPress: this.cancel },
    ]);
  };

  cancel = () => {};

  makeQuit = () => {
    storage.remove({
      key: "user",
    });
    config.user = "";
    config.username = "";
    DeviceEventEmitter.emit("quit", {});
    const resetAction = StackActions.reset({
      index: 0,
      actions: [NavigationActions.navigate({ routeName: "Login" })],
    });
    this.props.navigation.dispatch(resetAction);
  };

  onPress = (i) => {
    switch (i) {
      case 0:
        this.props.navigation.navigate("SystemDescription");
        break;
      case 1:
        this.setName();
        break;
      case 3:
        this.passChange();
        break;
      case 4:
        // console.log("上传");
        this.UploadData();
        break;
      case 5:
        // console.log("更新");
        this.UpdateData();
        break;
      case 6:
        this.props.navigation.navigate("MyKnowledgePoints", {
          DText: "我的考点收藏",
        });
        break;
      case 7:
        this.props.navigation.navigate("PrivateWordRecords");
        break;
      case 9:
        this.bindWexin();
        break;
      case 10:
        this.unbindWexin();
        break;
    }
  };

  setName = () => {
    this.setState({
      modalVisible2: true,
    });
  };

  close2 = () => {
    this.setState({
      modalVisible2: false,
    });
  };

  confirmEdit = () => {
    this.close2();
    if (
      this.state.inputText2 == "" ||
      this.state.inputText2 == config.user.userName
    ) {
      return;
    }
    let info = {
      ID: config.user.ID,
      userName: this.state.inputText2,
    };
    HttpUtil.post("50986_2", info).then((response) => {
      console.log("50986_2 修改昵称", response);
      if (response.Code == 1) {
        ToastShort("修改成功");
        config.user.userName = this.state.inputText2;
        storage.save({
          key: "user",
          data: config.user,
        });
        this.setState({
          userName2: this.state.inputText2,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  bindWexin = () => {
    var appid = "wx7f217e7eeca63426";
    var local = "http://math.math110.com/?type=wxBind";
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
      local
    )}&response_type=code&scope=snsapi_base#wechat_redirect`;
  };

  unbindWexin = () => {
    var param = {
      UserId: config.user.ID,
      WxId: JSON.parse(localStorage.getItem("MyWxID")),
      Type: 1,
    };
    HttpUtil.post("1117", param).then((res) => {
      if (res.Code == "1") {
        ToastShort("解绑成功");
        var obj = this.state.user;
        obj.wxId = "";
        storage.save({
          key: "user",
          data: obj,
        });
        config.user = obj;
        this.setState({
          user: obj,
          bindFlag: false,
        });
      } else {
        ToastShort(res.Msg);
      }
    });
  };

  /** 上传数据 / 更新数据 */
  UploadData = () => {
    this.setState({
      MessageBoxVisible2: true,
    });
  };

  UpdateData = () => {
    this.setState({
      MessageBoxVisible: true,
    });
  };

  closeMessageBox = () => {
    this.setState({
      MessageBoxVisible: false,
      MessageBoxVisible2: false,
      Value: [],
    });
  };

  confirmUpload = () => {
    if (this.state.Value.length == 0) {
      ToastShort("未选择要上传的数据");
      return;
    }
    let info = {
      ID: config.user.ID,
    };
    if (this.state.Value.includes(1)) {
      info.memo = localStorage.getItem("FlashCardData");
    }
    if (this.state.Value.includes(2)) {
      info.report = localStorage.getItem("StudyReport");
    }
    if (this.state.Value.includes(3)) {
      info.wordReport = localStorage.getItem("PrivateWordRecords");
    }
    HttpUtil.post("4009_1", info).then((response) => {
      console.log("4009_1 上传学习报告数据", response);
      if (response.Code == 1) {
        this.setState({ MessageBoxVisible2: false, Value: [] }, () => {
          ToastShort("上传成功");
        });
      } else {
        this.setState({ MessageBoxVisible2: false, Value: [] }, () => {
          ToastShort(response.Msg);
        });
      }
    });
  };

  confirmUpdata = () => {
    if (this.state.Value.length == 0) {
      ToastShort("未选择要更新的数据");
      return;
    }
    let info = {
      userId: config.user.ID,
    };
    HttpUtil.post("4009_2", info).then((response) => {
      console.log("4009_2 获取云端数据", response);
      if (response.Code == 1) {
        let data = response.Data[0];
        let errMsg = [];
        let sucMsg = [];
        if (this.state.Value.includes(1)) {
          let memo = data.memo;
          if (memo == "") {
            memo = JSON.stringify([]);
          }
          if (memo == null) {
            errMsg.push("单词学习");
          } else {
            sucMsg.push("单词学习");
            localStorage.setItem("FlashCardData", memo);
          }
        }
        if (this.state.Value.includes(2)) {
          let report = data.report;
          if (report == "") {
            report = JSON.stringify([]);
          }
          if (report == null) {
            errMsg.push("教材学习报告");
          } else {
            sucMsg.push("教材学习报告");
            localStorage.setItem("StudyReport", report);
          }
        }
        if (this.state.Value.includes(3)) {
          let wordReport = data.wordReport;
          if (wordReport == "") {
            wordReport = JSON.stringify([]);
          }
          if (wordReport == null) {
            errMsg.push("单词分析");
          } else {
            sucMsg.push("单词分析");
            localStorage.setItem("PrivateWordRecords", wordReport);
          }
        }
        let MsgVal = "";
        if (sucMsg.length > 0) {
          MsgVal = sucMsg.join("、") + "更新成功";
        }
        if (sucMsg.length > 0 && errMsg.length > 0) {
          MsgVal = MsgVal + "；";
        }
        if (errMsg.length > 0) {
          MsgVal = MsgVal + errMsg.join("、") + "更新失败";
        }
        this.setState({ MessageBoxVisible: false, Value: [] }, () => {
          ToastShort(MsgVal);
        });
      } else {
        this.setState({ MessageBoxVisible: false, Value: [] }, () => {
          ToastShort(response.Msg);
        });
      }
    });
  };

  /** end */

  MySignState = () => {
    var info = {
      userID: config.user.ID,
      ts: config.user.TS,
    };
    consolePage(info);
    HttpUtil.post("4019", info).then((response) => {
      consolePage("获取签到状态", response);
      if (response.Code == 1) {
        this.setState({
          qiandao: response.Data == 1 ? true : false,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  gotoPersonalData = () => {
    this.props.navigation.navigate("PersonalData");
  };

  makeQuit2 = () => {
    this.setState(
      {
        ChargeFlag: false,
      },
      () => {
        this.props.navigation.navigate("Login");
        storage.remove({
          key: "user",
        });
        config.user = "";
        config.username = "";
        const resetAction = StackActions.reset({
          index: 0,
          actions: [NavigationActions.navigate({ routeName: "Login" })],
        });
        this.props.navigation.dispatch(resetAction);
      }
    );
  };

  close3 = () => {
    this.setState({
      modalVisible3: false,
    });
  };

  // 设置延时时间
  setUp = () => {
    this.setState({
      modalVisible3: true,
      inputText: localStorage.getItem("delayTime")
        ? localStorage.getItem("delayTime")
        : 0.5,
    });
  };

  delay = () => {
    localStorage.setItem("delayTime", this.state.inputText);
    ToastShort("设置成功");
    this.close3();
  };

  handleTextChange = (obj) => {
    let a = Number(obj);
    if (a > 5) {
      obj = "5";
    }
    obj = obj.replace(/[^\d.]/g, "");
    //必须保证第一位为数字而不是.
    obj = obj.replace(/^\./g, "");
    //保证只有出现一个.而没有多个.
    obj = obj.replace(/\.{2,}/g, ".");
    //保证.只出现一次，而不能出现两次以上
    obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    console.log(obj);
    return obj;
  };

  header = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 220,
          backgroundColor: "white",
          justifyContent: "center",
          paddingTop: 49,
        }}
      >
        <View style={{ width: "100%", height: 100, flexDirection: "row" }}>
          <TouchableOpacity onPress={() => this.onPress(0)}>
            {this.state.headImg == "" ? (
              <Image
                source={require("../images/public/logo.png")}
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 40,
                  marginLeft: 20,
                }}
              />
            ) : (
              <Image
                source={{ uri: `${this.state.headImg}` }}
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 40,
                  marginLeft: 20,
                }}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.gotoPersonalData()}
            style={{
              height: 80,
              justifyContent: "space-around",
              marginLeft: 20,
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={{ fontSize: 13 }}>账号：</Text>
              <Text>{this.state.userName}</Text>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={{ color: "#333", fontSize: 14, maxWidth: 100 }}
              >
                {this.state.user !== "" ? config.user.login : "请登录"}
              </Text>
              {/* <Image
                source={require("../images/library/edit.png")}
                style={{ width: 10, height: 10, marginLeft: 10 }}
              /> */}
            </View>
            <View
              style={{ display: "flex", flexDirection: "row", marginTop: -3 }}
            >
              <View style={styles.circleSty2}></View>
              <Text style={{ color: "#666666", fontSize: 12 }}>
                用户名：
                {this.state.user !== "" ? this.state.userName2 : ""}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  center = () => {
    return (
      <View style={{ marginTop: 15 }}>
        {/* <TouchableOpacity
          onPress={() => this.onPress(0)}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/explain.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>平台说明</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
              }}
            />
          </View>
        </TouchableOpacity> */}
        <TouchableOpacity
          onPress={() => this.onPress(1)}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/personal.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>修改昵称</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{ width: 20, height: 20, position: "absolute", right: 0 }}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onPress(3)}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/password.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>修改密码</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{ width: 20, height: 20, position: "absolute", right: 0 }}
            />
          </View>
        </TouchableOpacity>
        {this.state.bindFlag === false && (
          <TouchableOpacity
            onPress={() => this.onPress(9)}
            style={[styles.itemview]}
          >
            <View style={styles.item}>
              <Image
                source={require("../images/my/pratice.png")}
                style={{ width: 26, height: 26 }}
              />
              <Text style={{ marginLeft: 5, fontSize: 15 }}>绑定账号</Text>
              <Image
                source={require("../images/my/gt.png")}
                style={{
                  width: 20,
                  height: 20,
                  position: "absolute",
                  right: 0,
                }}
              />
            </View>
          </TouchableOpacity>
        )}
        {this.state.bindFlag === true && (
          <TouchableOpacity
            onPress={() => this.onPress(10)}
            style={[styles.itemview]}
          >
            <View style={styles.item}>
              <Image
                source={require("../images/my/pratice.png")}
                style={{ width: 26, height: 26 }}
              />
              <Text style={{ marginLeft: 5, fontSize: 15 }}>解绑账号</Text>
              <Image
                source={require("../images/my/gt.png")}
                style={{
                  width: 20,
                  height: 20,
                  position: "absolute",
                  right: 0,
                }}
              />
            </View>
          </TouchableOpacity>
        )}
        {/* 延迟设置 */}
        {/* <TouchableOpacity
          onPress={() => this.setUp()}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/setUp.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>延迟设置</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
              }}
            />
          </View>
        </TouchableOpacity> */}
        {/* 延迟设置 */}
        {/* <TouchableOpacity
          onPress={() => this.onPress(7)}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/myWord.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>单词分析</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
              }}
            />
          </View>
        </TouchableOpacity> */}
        {/* 考点收藏 */}
        <TouchableOpacity
          onPress={() => this.onPress(6)}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/collection.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>考点收藏</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
              }}
            />
          </View>
        </TouchableOpacity>
        {/* 单词背诵 上传 更新 数据 */}
        {/* <TouchableOpacity
          onPress={() => this.onPress(4)}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/upload.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>上传数据</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
              }}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onPress(5)}
          style={[styles.itemview]}
        >
          <View style={styles.item}>
            <Image
              source={require("../images/my/update.png")}
              style={{ width: 26, height: 26 }}
            />
            <Text style={{ marginLeft: 5, fontSize: 15 }}>更新数据</Text>
            <Image
              source={require("../images/my/gt.png")}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                right: 0,
              }}
            />
          </View>
        </TouchableOpacity> */}
      </View>
    );
  };

  quit = () => {
    return (
      <TouchableOpacity onPress={() => this.makeQuit()} style={[styles.quit]}>
        <View style={styles.quititem}>
          <Image
            source={require("../images/my/quit.png")}
            style={{ width: 26, height: 26 }}
          />
          <Text style={{ marginLeft: 5, fontSize: 15 }}>退出</Text>
          <Image
            source={require("../images/my/gt.png")}
            style={{ width: 20, height: 20, position: "absolute", right: 0 }}
          />
        </View>
      </TouchableOpacity>
    );
  };

  /* 更新 */
  MessageBox = () => {
    return (
      <Modal2
        style={{ position: "relative" }}
        modalAnimation={
          new ScaleAnimation({
            initialValue: 0, // optional
            useNativeDriver: true, // optional
          })
        }
        transparent={true}
        modalTitle={
          <ModalTitle
            textStyle={{ color: "#333", fontSize: 13 }}
            title="友情提示"
          />
        }
        visible={this.state.MessageBoxVisible}
        onTouchOutside={() => {
          this.closeMessageBox();
        }}
        onRequestClose={() => {
          this.closeMessageBox();
        }}
      >
        <ModalContent>
          <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
            <View style={styles.CheckGroupBox}>
              {this.state.OptionData.map((item, index) => {
                return this.CheckItemCom(item, index);
              })}
            </View>
            <Text style={{ fontSize: 14, color: "#999", lineHeight: 22 }}>
              请选择需要更新的数据，
              <Text style={{ color: "#1c76fc" }}>点击空白处关闭</Text>
            </Text>
            <Text style={{ fontSize: 12, color: "red", lineHeight: 22 }}>
              注意：选择更新将覆盖本地数据，请慎重操作!
            </Text>
          </View>
        </ModalContent>
        <ModalFooter>
          <ModalButton
            text="取 消"
            disabled={false}
            textStyle={{ color: "#333333", fontSize: 14 }}
            onPress={() => this.closeMessageBox()}
          />
          <ModalButton
            text="更 新"
            disabled={false}
            textStyle={{ color: "#1c76fc", fontSize: 14 }}
            onPress={() => this.confirmUpdata()}
          />
        </ModalFooter>
      </Modal2>
    );
  };

  /* 上传 */
  MessageBox2 = () => {
    return (
      <Modal2
        style={{ position: "relative" }}
        modalAnimation={
          new ScaleAnimation({
            initialValue: 0, // optional
            useNativeDriver: true, // optional
          })
        }
        transparent={true}
        modalTitle={
          <ModalTitle
            textStyle={{ color: "#333", fontSize: 13 }}
            title="友情提示"
          />
        }
        visible={this.state.MessageBoxVisible2}
        onTouchOutside={() => {
          this.closeMessageBox();
        }}
        onRequestClose={() => {
          this.closeMessageBox();
        }}
      >
        <ModalContent>
          <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
            <View style={styles.CheckGroupBox}>
              {this.state.OptionData.map((item, index) => {
                return this.CheckItemCom(item, index);
              })}
            </View>
            <Text style={{ fontSize: 14, color: "#999", lineHeight: 22 }}>
              请选择需要上传的数据，
              <Text style={{ color: "#1c76fc" }}>点击空白处关闭</Text>
            </Text>
            <Text style={{ fontSize: 12, color: "red", lineHeight: 22 }}>
              注意：选择更新将覆盖云端数据，请慎重操作!
            </Text>
          </View>
        </ModalContent>
        <ModalFooter>
          <ModalButton
            text="取 消"
            disabled={false}
            textStyle={{ color: "#333333", fontSize: 14 }}
            onPress={() => this.closeMessageBox()}
          />
          <ModalButton
            text="上 传"
            disabled={false}
            textStyle={{ color: "#1c76fc", fontSize: 14 }}
            onPress={() => this.confirmUpload()}
          />
        </ModalFooter>
      </Modal2>
    );
  };

  handleChecked = (item) => {
    let checked = require("../images/class/checked.png");
    let unchecked = require("../images/class/unchecked.png");
    if (this.state.Value.includes(item)) {
      return checked;
    }
    return unchecked;
  };

  CheckedItem = (val) => {
    let arr = JSON.parse(JSON.stringify(this.state.Value));
    if (this.state.Value.includes(val)) {
      let index = this.state.Value.indexOf(val);
      arr.splice(index, 1);
    } else {
      arr.push(val);
    }
    this.setState({
      Value: arr,
    });
  };

  CheckItemCom = (item, index) => {
    return (
      <TouchableOpacity
        key={index}
        onPress={() => this.CheckedItem(item.value)}
        style={[
          styles.checkSty,
          {
            backgroundColor: this.state.Value.includes(item.value)
              ? "#409eff"
              : "#ecf5ff",
          },
        ]}
      >
        {/* <Image
          source={this.handleChecked(item.value)}
          style={{ width: 14, height: 14, marginRight: 5 }}
        /> */}
        <Text
          style={{
            color: this.state.Value.includes(item.value) ? "#fff" : "#409eff",
            fontSize: 12,
          }}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <BaseView>
        <StatusBar
          backgroundColor="rgba(0,0,0,0)"
          translucent={true}
          barStyle={"dark-content"}
        />
        <View style={styles.container}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {this.header()}
            {this.center()}
            {this.quit()}
            <View style={{ width: "100%", height: config.WhiteSpace }} />
          </ScrollView>
        </View>
        {/* 延迟设置 */}
        <Modal
          style={{ position: "relative" }}
          modalAnimation={
            new ScaleAnimation({
              initialValue: 0, // optional
              useNativeDriver: true, // optional
            })
          }
          transparent={true}
          modalTitle={
            <ModalTitle
              textStyle={{ color: "#333", fontSize: 13 }}
              title="延迟设置"
            />
          }
          visible={this.state.modalVisible3}
          onTouchOutside={() => {
            this.setState({ modalVisible3: false });
          }}
          onRequestClose={() => this.delay()}
        >
          <ModalContent>
            <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
              <TextInput
                placeholder="请输入延迟时间"
                placeholderTextColor={"#999999"}
                selectionColor={"#000000"}
                autoFocus={true}
                keyboardType="numeric"
                underlineColorAndroid="transparent"
                secureTextEntry={false}
                style={[
                  {
                    fontSize: 16,
                    outlineWidth: 0,
                    borderBottomWidth: 1,
                    borderBottomColor: "#999999",
                    marginBottom: 20,
                  },
                ]}
                clearButtonMode="while-editing"
                onChangeText={(text) =>
                  this.setState({ inputText: this.handleTextChange(text) })
                }
                value={this.state.inputText}
                maxLength={3}
              />
              <Text style={{ fontSize: 14, color: "#999", lineHeight: 22 }}>
                根据设定的时间限制，在单元测试和专项测试的页面上，回答当前问题并且答对后，系统将延迟多少秒自动跳转到下一题。（单位：秒，范围：0
                ~ 5）
              </Text>
            </View>
          </ModalContent>
          <ModalFooter>
            <ModalButton
              text="取 消"
              disabled={false}
              textStyle={{ color: "#333333", fontSize: 14 }}
              onPress={() => this.close3()}
            />
            <ModalButton
              text="确 定"
              disabled={false}
              textStyle={{ color: "#1c76fc", fontSize: 14 }}
              onPress={() => this.close3()}
            />
          </ModalFooter>
        </Modal>
        {/* 修改昵称 */}
        <Modal
          style={{ position: "relative" }}
          modalAnimation={
            new ScaleAnimation({
              initialValue: 0, // optional
              useNativeDriver: true, // optional
            })
          }
          transparent={true}
          modalTitle={
            <ModalTitle
              textStyle={{ color: "#333", fontSize: 13 }}
              title="修改昵称"
            />
          }
          visible={this.state.modalVisible2}
          onTouchOutside={() => {
            this.setState({ modalVisible2: false });
          }}
        >
          <ModalContent>
            <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
              <TextInput
                placeholder="请输入昵称"
                placeholderTextColor={"#999999"}
                selectionColor={"#000000"}
                autoFocus={true}
                keyboardType="default"
                underlineColorAndroid="transparent"
                secureTextEntry={false}
                style={[
                  {
                    fontSize: 14,
                    outlineWidth: 0,
                    borderBottomWidth: 1,
                    borderBottomColor: "#999999",
                    marginBottom: 20,
                    lineHeight: 25,
                  },
                ]}
                clearButtonMode="while-editing"
                onChangeText={(text) => this.setState({ inputText2: text })}
                value={this.state.inputText2}
              />
            </View>
          </ModalContent>
          <ModalFooter>
            <ModalButton
              text="取 消"
              disabled={false}
              textStyle={{ color: "#333333", fontSize: 14 }}
              onPress={() => this.close2()}
            />
            <ModalButton
              text="确 定"
              disabled={false}
              textStyle={{ color: "#1c76fc", fontSize: 14 }}
              onPress={() => this.confirmEdit()}
            />
          </ModalFooter>
        </Modal>
        {/* 更新数据 选择 */}
        {this.MessageBox()}
        {/* 上传数据 选择 */}
        {this.MessageBox2()}
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  quititem: {
    width: "90%",
    height: 60,
    flexDirection: "row",
    alignItems: "center",
  },
  quit: {
    width: "100%",
    height: 60,
    backgroundColor: "white",
    flexDirection: "row",
    marginTop: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  item: {
    width: "90%",
    height: 50,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: "#f4f1f4",
    borderBottomWidth: 1,
  },
  itemview: {
    width: "100%",
    height: 50,
    backgroundColor: "#ffffff",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f4f1f4",
  },
  container2: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 3,
    backgroundColor: "white",
    alignItems: "center",
  },
  modalTitle: {
    color: "#000000",
    fontSize: 16,
    marginTop: 10,
  },
  modalMessage: {
    color: "#8a8a8a",
    fontSize: 14,
    margin: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  horizonLine: {
    backgroundColor: "#9f9fa3",
    height: 0.5,
    alignSelf: "stretch",
  },
  verticalLine: {
    backgroundColor: "#9f9fa3",
    width: 1,
    alignSelf: "stretch",
  },
  leftBn: {
    borderBottomLeftRadius: 3,
    padding: 7,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  leftBnText: {
    fontSize: 16,
    color: "#8a8a8a",
  },
  rightBn: {
    borderBottomRightRadius: 3,
    padding: 7,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBnText: {
    fontSize: 16,
    color: "#00A9F2",
  },
  eyeChose: {
    width: 15,
    height: 15,
    position: "absolute",
    top: -1,
    right: -1,
  },
  eyeNoChose: {
    width: 0,
    height: 0,
    position: "absolute",
    top: 0,
    right: 0,
  },
  circleSty: {
    height: 6,
    width: 6,
    backgroundColor: "#FD932B",
    borderRadius: 3,
    marginTop: 6,
    marginRight: 7,
  },
  circleSty2: {
    height: 6,
    width: 6,
    backgroundColor: "#44C296",
    borderRadius: 3,
    marginTop: 6,
    marginRight: 7,
  },
  /* 选择框 */
  CheckGroupBox: {
    width: "100%",
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  checkSty: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 5,
    borderWidth: 1,
    marginBottom: 10,
    borderColor: "#b3d8ff",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
