import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Dimensions,
  ScrollView,
  Modal,
  Image,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import ReturnPHCom from "../../Component/returnPHCom";
import Modal2, {
  ModalTitle,
  ModalFooter,
  ModalButton,
  ModalContent,
  ScaleAnimation,
} from "react-native-modals";
import color from "../../Component/Color";
import AudioManager from "../../Component/AudioManager";

import { Audio } from "expo-av";
import storage from "../../Component/CustomStorage";
var sound = new Audio.Sound();

import test from "../../ApublicStyle/TestStyle";
import ApublicColorFun from "../../ApublicStyle/TestColorFun";

var deviceHeight = Dimensions.get("window").height;
var deviceWidth = Dimensions.get("window").width;

export default class wordBarrierDetail2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bookId: "",
      courseList: [],
      list: [],
      quesIndex: 0,
      pageState: 0,
      isSwitch: false, // fasle: 英  true: 美
      modalVisible: false,
      isClick: false,
      typeName: "美",
      // 提交数据之后
      testQuestion: [],
      rightNum: "--",
      isSubmit: false,
      end: false,
      modalVisible2: false,
      num: 0,
      passNum: 0,
      totalNum: 0,
      loading: false,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    const item = this.props.navigation.state.params;
    if (item) {
      this.setState({
        passNum: item.PassNumber, // 已闯到关卡
        num: Number(item.currentNumber), // 当前关卡
        bookId: item.ID,
        totalNum: Number(item.totalNum), // 总关卡数
      });
    }
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "comprehensive/wordBarrierDetail2");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getQuestions();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  getQuestions = () => {
    let info = {
      BookId: this.state.bookId,
      PassNumber: String(this.state.num),
    };
    HttpUtil.post("1120_1", info).then((res) => {
      let data = res.Data;
      if (data && data.length == 0) {
        this.setState({
          pageState: 1,
        });
        return;
      }
      data.forEach((i) => {
        let num = Math.floor(Math.random() * 3);
        let num2 = Math.floor(Math.random() * 2);
        i.questions.forEach((j) => {
          j.color = "";
          j.type = num;
        });
        // 0: 看英选中 1：看中选英 2：听音选英
        i.type =
          i.engStyle && i.engStyle != "" && i.engStyle != null ? num : num2;
      });
      console.log("获取题目1120", data);
      this.setState(
        {
          list: data,
          pageState: 1,
        },
        () => {
          if (this.state.list[0].type == 2 || this.state.list[0].type == 0) {
            this.playSound();
          }
        }
      );
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  OptionRecord = (item, correct, type) => {
    let option = item.word; // 选项 单词
    let correctAnswer = correct.word; // 正确单词
    let myAnswer = correct.myAnswer; // 我的答案
    if (option == correctAnswer) {
      return "#05c08b";
    }
    if (myAnswer != correctAnswer && myAnswer == option) {
      return "#F56C6C";
    }

    return "#909399";
  };

  switch = () => {
    this.setState({
      isSwitch: !this.state.isSwitch,
    });
    this.close();
  };

  // 打开
  open = () => {
    this.setState({
      modalVisible: true,
    });
  };

  // 关闭
  close = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handlePressIn = () => {
    this.setState({
      isClick: true,
    });
  };

  handlePressOut = () => {
    this.setState({
      isClick: false,
    });
  };

  /*************************************************************************** 逻辑 ***************************************************************************/
  // 答题 选择
  doQues = (item, index) => {
    let obj = this.state.list[this.state.quesIndex];
    if (obj.myAnswer != "" && obj.myAnswer != null) {
      // 选择之后 禁止修改
      return;
    }
    // if (obj.type == 1) {
    //   this.playSound();
    // }
    obj.myAnswer = item.word;
    if (
      (item.word == obj.word && obj.myAnswer == obj.word) ||
      item.word == obj.word
    ) {
      // 正确
      item.color = 1;
      obj.isCorrect = "1";
    } else if (obj.myAnswer != obj.word && obj.myAnswer == item.word) {
      // 错误
      item.color = 2;
      obj.isCorrect = "0";
    } else {
      item.color = "";
    }
    let newAnwer = JSON.parse(JSON.stringify(this.state.list));
    this.setState({
      list: newAnwer,
    });
    if (obj.type == 1) {
      this.playAudioSet();
    }
    if (
      obj.myAnswer == obj.word &&
      this.state.quesIndex + 1 < this.state.list.length &&
      obj.type != 1
    ) {
      setTimeout(
        () => {
          this.doNext();
        },
        localStorage.getItem("delayTime")
          ? localStorage.getItem("delayTime") * 1000
          : 500
      );
    }
    if (
      obj.myAnswer != "" &&
      this.state.quesIndex + 1 == this.state.list.length
    ) {
      this.setState({
        end: true,
      });
    }
  };

  // 播放
  playSound = (data) => {
    var tempAudioUrl = "";
    if (this.state.isSwitch) {
      // 美音
      tempAudioUrl =
        config.audioUrl + this.state.list[this.state.quesIndex].amrStyle;
    } else {
      // 英音
      tempAudioUrl =
        config.audioUrl + this.state.list[this.state.quesIndex].engStyle;
    }
    AudioManager.playAudio(tempAudioUrl);
  };

  playAudioSet = async () => {
    let audioUrl = "";
    let obj = this.state.list[this.state.quesIndex];
    if (this.state.isSwitch) {
      // 美音
      audioUrl = config.audioUrl + obj.amrStyle;
    } else {
      // 英音
      audioUrl = config.audioUrl + obj.engStyle;
    }
    await sound.loadAsync({ uri: audioUrl });

    await sound.playAsync();
    sound.setOnPlaybackStatusUpdate((status) => {
      if (status.didJustFinish) {
        console.log("音频播放完成");
        sound.unloadAsync(); // 播放完成后卸载音频
        if (
          obj.myAnswer == obj.word &&
          this.state.quesIndex + 1 < this.state.list.length
        ) {
          this.doNext();
        }
      }
    });
  };

  // 下一题
  doNext = () => {
    if (this.state.end) {
      this.submit();
      return;
    }
    let num = this.state.quesIndex + 1;
    this.setState(
      {
        quesIndex: num,
      },
      () => {
        if (this.state.list[num].type == 2 || this.state.list[num].type == 0) {
          this.playSound();
        }
      }
    );
  };

  // 下一轮
  nextRound = (type) => {
    if (type == 1) {
      this.props.navigation.navigate("wordBarrier2");
    } else if (type == 2) {
      let num = 0;
      if (this.state.num == this.state.totalNum) {
        ToastShort("已经是最后一关了");
        this.props.navigation.pop();
        return;
      } else {
        num = Number(this.state.num) + 1;
      }
      this.setState(
        {
          pageState: 1,
          quesIndex: 0,
          list: [],
          testQuestion: [],
          rightNum: "--",
          isSubmit: false,
          end: false,
          num: num,
          loading: false,
        },
        () => {
          this.getQuestions();
        }
      );
    } else {
      this.setState(
        {
          pageState: 1,
          quesIndex: 0,
          list: [],
          testQuestion: [],
          rightNum: "--",
          isSubmit: false,
          end: false,
          loading: false,
        },
        () => {
          this.getQuestions();
        }
      );
    }
  };

  // 提交
  submit = () => {
    if (this.state.loading) {
      ToastShort("请勿重复提交");
      return;
    }
    this.setState({
      loading: true,
    });
    let links = [];
    let num = 0;
    links = links.concat(this.state.list);
    links.forEach((item) => {
      if (item.isCorrect == "1") {
        num++;
      }
    });
    this.setState(
      {
        rightNum: num,
        testQuestion: links,
      },
      () => {
        this.handlePrivateWordRecords();
      }
    );
    // console.log(links);
    this.postData(links, num);
  };

  postData = (links, num) => {
    let info = {
      ClassId: config.classMsg.ClassId,
      BookId: this.state.bookId,
      StudentName: config.user.userName,
      StudentId: config.user.ID,
      StudentBh: config.user.userBh,
      Type: "0",
      PassNumber: String(this.state.num),
      Ts: config.user.TS,
      submitList: links,
      PassState:
        this.state.totalNum == this.state.num &&
        num == this.state.testQuestion.length
          ? 3
          : this.state.totalNum != this.state.num && num == links.length
          ? 2
          : 1,
    };
    HttpUtil.post("1121", info)
      .then((res) => {
        console.log(res);
        if (res.Code == 1) {
          ToastShort("提交成功");
          this.setState({
            pageState: 2,
            isSubmit: true,
          });
        } else {
          this.setState({
            loading: false,
          });
          ToastShort(res.Msg);
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        console.log(e);
        ToastShort("提交失败");
      });
  };

  handlePrivateWordRecords = () => {
    let newData = this.state.testQuestion.map((item) => {
      return {
        word: item.word.trim(),
        state: item.isCorrect == "1" ? 1 : 0,
      };
    });
    storage.PrivateWordRecords(newData);
  };
  /*************************************************************************** 跳转 ***************************************************************************/

  back = () => {
    if (this.state.quesIndex == 0 || this.state.isSubmit) {
      this.props.navigation.pop();
      return;
    } else {
      this.setState({
        modalVisible2: true,
      });
    }
    // this.submit()
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.back();
    }
  }

  confirm = () => {
    this.setState({
      modalVisible2: false,
    });
    this.props.navigation.pop();
  };

  close2 = () => {
    this.setState({
      modalVisible2: false,
    });
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          关卡 {this.state.num}
        </Text>
        {/* <TouchableOpacity onPress={() => this.open()} style={{ width: 40, heigh: 40, justifyContent: 'center', alignItems: 'center', position: 'absolute', top: BaseView.currentHeight + 8, right: 10 }}>
          <Image
            source={require('../../images/word/more.png')}
            style={{ width: 23, height: 23 }}
          />
        </TouchableOpacity> */}
      </View>
    );
  };

  _renderNext = () => {
    return (
      <View>
        <View style={styles.flex_row}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => this.switch()}
            onPressIn={() => this.handlePressIn()}
            onPressOut={() => this.handlePressOut()}
            style={[
              styles.btnSty,
              {
                backgroundColor: this.state.isClick
                  ? color.background2
                  : "#fff",
              },
            ]}
          >
            <Image
              source={require("../../images/public/qh.png")}
              style={{ width: 16, height: 16, marginRight: 6 }}
            />
            <Text style={{ textAlign: "center", fontSize: 14, lineHeight: 40 }}>
              切换
              <Text style={{ color: "red" }}>
                {this.state.isSwitch ? "英" : "美"}
              </Text>
              音
            </Text>
          </TouchableOpacity>
          {/* <TouchableOpacity activeOpacity={1} onPress={() => this.switch(2)} onPressIn={() => this.handlePressIn(2)} onPressOut={() => this.handlePressOut(2)} style={[styles.btnSty, { backgroundColor: this.state.isClick2 ? color.background2 : '#fff' }]}>
            <Image
              source={require('../../images/public/zidong.png')}
              style={{ width: 16, height: 16, marginRight: 6 }}
            />
            <Text style={{ textAlign: 'center', fontSize: 14, lineHeight: 40 }}><Text style={{ color: 'red' }}>{this.state.isSwitch2 ? '关闭' : '开启'}</Text>自动播放</Text>
          </TouchableOpacity> */}
        </View>
        <View style={styles.NextBtn}>
          {/* 进度 */}
          <View
            style={{
              height: 40,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 12 }}>{"练习进度"}</Text>
            <Text style={test.schedule_text}>
              {this.state.list && this.state.list.length > 0
                ? this.state.quesIndex + 1 + "/" + this.state.list.length
                : "-- / --"}
            </Text>
          </View>
          {/* <Text style={{ fontSize: 14, color: "red" }}>
          退出将自动提交结果
        </Text> */}
          {/* 切换题目 */}
          {(this.state.list &&
            this.state.list.length > 0 &&
            this.state.list[this.state.quesIndex].myAnswer != null &&
            this.state.list[this.state.quesIndex].myAnswer !=
              this.state.list[this.state.quesIndex].word) ||
          (this.state.end && !this.state.loading) ? (
            <TouchableOpacity
              onPress={() => {
                this.doNext();
              }}
              style={test.btn_noDisabled}
            >
              <Text style={{ fontSize: 15, color: "#fff" }}>
                {this.state.quesIndex + 1 == this.state.list.length
                  ? "提 交"
                  : "下一题"}
              </Text>
            </TouchableOpacity>
          ) : (
            <View style={test.btn_disabled}>
              <Text style={{ fontSize: 15, color: "#fff" }}>
                {this.state.quesIndex + 1 == this.state.list.length
                  ? "提 交"
                  : "下一题"}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  stemCom = () => {
    let data = this.state.list[this.state.quesIndex];
    return (
      data &&
      data != "" && (
        <View style={test.stem_container}>
          {/* 题目 */}
          <View style={test.stem_space}>
            {data.type == 0 && ( // 看英选中
              <TouchableOpacity
                style={[test.stem_space, test.row_center_worp]}
                onPress={() => this.playSound()}
              >
                <Text
                  style={[test.stem_question_E, { maxWidth: deviceWidth - 80 }]}
                >
                  {data.word}
                </Text>
                <Image
                  source={require("../../images/word/isRadio1.png")}
                  style={{ width: 32, height: 32, marginLeft: 8, marginTop: 3 }}
                />
              </TouchableOpacity>
            )}
            {data.type == 1 && ( // 看中选英
              <Text style={test.stem_question}>{data.meaning}</Text>
            )}
            {data.type == 2 && ( // 听音选英
              <TouchableOpacity
                onPress={() => this.playSound(data)}
                style={{ textAlign: "center" }}
              >
                <Image
                  source={require("../../images/word/isRadio1.png")}
                  style={{ width: 40, height: 40 }}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
      )
    );
  };

  optionCom = () => {
    let data =
      this.state.list && this.state.list.length > 0
        ? this.state.list[this.state.quesIndex].questions
        : "";
    let answer =
      this.state.list && this.state.list.length > 0
        ? this.state.list[this.state.quesIndex]
        : "";
    return (
      <View style={test.container_space}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  this.doQues(item, index);
                }}
                key={index}
                style={[
                  test.option_box,
                  {
                    backgroundColor: ApublicColorFun.Option(
                      "backgroundColor",
                      answer.myAnswer,
                      answer.word,
                      item.word
                    ),
                    borderColor: ApublicColorFun.Option(
                      "borderColor",
                      answer.myAnswer,
                      answer.word,
                      item.word
                    ),
                  },
                ]}
              >
                <View style={test.option_container}>
                  <Text style={[test.option_index, { display: "flex" }]}>
                    {String.fromCharCode(64 + parseInt(1 + index)) + ". "}
                    {(item.color == 1 || item.color == 2) &&
                    item.color != "" ? (
                      this.wordInfo(item)
                    ) : (
                      <Text style={test.option_value_E}>
                        {item.type == 1 || item.type == 2
                          ? item.word
                          : item.meaning}
                      </Text>
                    )}
                  </Text>
                  {item.color != "" && item.color == 1 && (
                    <Image
                      source={require("../../images/word/correct.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                  {item.color != "" && item.color == 2 && (
                    <Image
                      source={require("../../images/word/erro.png")}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
      </View>
    );
  };

  wordInfo = (item) => {
    return (
      <View style={{ flexDirection: "column", width: "95%", marginLeft: 5 }}>
        <Text style={test.option_value_E}>{item.word}</Text>
        <Text style={test.option_value_mean}>{item.meaning}</Text>
      </View>
    );
  };

  fullLoading = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size={"large"} color={"#666"} animating={true} />
        <Text style={{ color: "#666", fontSize: 18, marginTop: 15 }}>
          正在加载中
        </Text>
      </View>
    );
  };

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }}>
          <View
            style={{ width: "100%", paddingHorizontal: 20, paddingTop: 20 }}
          >
            <Text
              style={{
                color:
                  this.state.rightNum / this.state.testQuestion.length == 1
                    ? "#40CC5C"
                    : "#E83333",
                fontSize: 20,
                fontWeight: 600,
                lineHeight: 20,
                textAlign: "center",
              }}
            >
              {(
                (this.state.rightNum / this.state.testQuestion.length) *
                100
              ).toFixed(0)}
              % 正确率
            </Text>
          </View>
          {/* 作答情况 */}
          <View
            style={{
              margin: 20,
              height: 58,
              borderRadius: 10,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}
              >
                {this.state.rightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                正确
              </Text>
            </View>
            <View
              style={{
                width: "34%",
                height: "70%",
                borderLeftWidth: 1,
                borderColor: "#E5E5E5",
                borderRightWidth: 1,
              }}
            >
              <Text
                style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}
              >
                {this.state.testQuestion
                  ? this.state.testQuestion.length - this.state.rightNum
                  : ""}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                错误
              </Text>
            </View>
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ fontSize: 18, color: "#333", textAlign: "center" }}
              >
                {this.state.testQuestion.length}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                总数
              </Text>
            </View>
          </View>
          {this.state.testQuestion &&
            this.state.testQuestion.length > 0 &&
            this.state.testQuestion.map((item, index) => {
              let ques = item.questions;
              return (
                <View
                  key={index}
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingHorizontal: 20,
                    marginBottom: 20,
                  }}
                >
                  <Text style={[test.option_index, { marginBottom: 5 }]}>
                    {index + 1 + ". " + item.word}
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "#333",
                      lineHeight: 16,
                      marginBottom: 10,
                      marginLeft: 15,
                    }}
                  >
                    {item.meaning}
                  </Text>
                  {ques &&
                    ques.length > 0 &&
                    ques.map((i, key) => {
                      return (
                        <View key={key} style={{ marginBottom: 5 }}>
                          <Text
                            style={[
                              test.option_index,
                              {
                                color: ApublicColorFun.OptionRecord(
                                  i.word,
                                  item.myAnswer,
                                  item.word
                                ),
                              },
                            ]}
                          >
                            {String.fromCharCode(64 + parseInt(1 + key)) + ". "}
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  test.option_value_E,
                                  {
                                    color: ApublicColorFun.OptionRecord(
                                      i.word,
                                      item.myAnswer,
                                      item.word
                                    ),
                                  },
                                ]}
                              >
                                {i.word}
                              </Text>
                              <Text
                                style={[
                                  test.option_value_mean,
                                  {
                                    color: ApublicColorFun.OptionRecord(
                                      i.word,
                                      item.myAnswer,
                                      item.word
                                    ),
                                  },
                                ]}
                              >
                                {i.meaning}
                              </Text>
                            </View>
                          </Text>
                        </View>
                      );
                    })}
                </View>
              );
            })}
        </ScrollView>
      </View>
    );
  };

  _renderNextTest = () => {
    return (
      <View style={styles.NextBtn}>
        {/* 下一轮  */}
        <TouchableOpacity
          onPress={() => {
            this.nextRound(1);
          }}
          style={{
            height: 40,
            width: "45%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>{"返回"}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            if (this.state.rightNum / this.state.testQuestion.length == 1) {
              this.nextRound(2);
            } else {
              this.nextRound(3);
            }
          }}
          style={{
            height: 40,
            width: "45%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>
            {this.state.rightNum / this.state.testQuestion.length == 1
              ? "下一关"
              : "重新闯关"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
  /*************************************************************************** render ***************************************************************************/

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#fff",
          }}
        >
          {this._renderHeader()}
          {this.state.pageState == 1 && (
            <ScrollView
              style={test.scroll_container}
              showsVerticalScrollIndicator={false}
            >
              {/* 题干 */}
              {this.stemCom()}
              {/* 选项 */}
              {this.optionCom()}
            </ScrollView>
          )}
          {this.state.pageState == 1 && this._renderNext()}
          {this.state.pageState == 0 && this.fullLoading()}
          {this.state.pageState == 2 && this.testRecord()}
          {this.state.pageState == 2 && this._renderNextTest()}
        </View>
        <Modal
          style={{ position: "relative" }}
          animationType="fade"
          transparent={true}
          visible={this.state.modalVisible}
          onRequestClose={() => this.close()}
        >
          <View
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <Text
              onPress={() => this.close()}
              style={{ height: "100%", width: "100%" }}
            ></Text>
            <View style={styles.modalContent}>
              <TouchableOpacity
                activeOpacity={1}
                onPress={() => this.switch()}
                onPressIn={() => this.handlePressIn()}
                onPressOut={() => this.handlePressOut()}
                style={{
                  width: "100%",
                  height: 40,
                  paddingLeft: 15,
                  paddingRight: 15,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: this.state.isClick
                    ? color.background2
                    : "#fff",
                }}
              >
                <Image
                  source={require("../../images/public/qh.png")}
                  style={{ width: 16, height: 16, marginRight: 6 }}
                />
                <Text
                  style={{ textAlign: "center", fontSize: 14, lineHeight: 40 }}
                >
                  切换
                  <Text style={{ color: "red" }}>
                    {this.state.isSwitch ? "英" : "美"}
                  </Text>
                  音
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <Modal2
          style={{ position: "relative" }}
          modalAnimation={
            new ScaleAnimation({
              initialValue: 0, // optional
              useNativeDriver: true, // optional
            })
          }
          transparent={true}
          modalTitle={
            <ModalTitle
              textStyle={{ color: "#333", fontSize: 13 }}
              title="友情提示"
            />
          }
          footer={
            <ModalFooter>
              <ModalButton
                text="取 消"
                disabled={false}
                textStyle={{ color: "#333333", fontSize: 14 }}
                onPress={() => this.close2()}
              />
              <ModalButton
                text="退 出"
                disabled={false}
                textStyle={{ color: "#1c76fc", fontSize: 14 }}
                onPress={() => this.confirm()}
              />
            </ModalFooter>
          }
          visible={this.state.modalVisible2}
          onTouchOutside={() => {
            this.setState({ modalVisible2: false });
          }}
          onRequestClose={() => this.close2()}
        >
          <ModalContent>
            <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
              <Text style={{ fontSize: 14, color: "#999", lineHeight: 22 }}>
                注：点击退出即返回上一页，此次闯关数据不做记录，您之后将重新做题！
              </Text>
            </View>
          </ModalContent>
        </Modal2>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  NextBtn: {
    paddingHorizontal: 20,
    height: 60,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    width: "35%",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 45 + BaseView.currentHeight,
    right: 20,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    backgroundColor: "#f8f8f8",
    paddingBottom: 10,
  },
  btnSty: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
  },
});
