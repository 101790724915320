import React from "react";
import { Text, View, Image, Dimensions } from "react-native";
import char from "../../../ApublicStyle/CharacterStyle";
import { initValue } from "../../../Component/WordHighLight";
import test from "../../../ApublicStyle/TestStyle";

var deviceWidth = Dimensions.get("window").width;

export function Record(RightNum, TotalNum, Links) {
  return (
    <View>
      {/* 作答情况 */}
      <View
        style={{
          margin: 20,
          height: 58,
          borderRadius: 10,
          borderColor: "#E5E5E5",
          borderWidth: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View style={{ width: "33%", height: "70%" }}>
          <Text style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}>
            {RightNum}
          </Text>
          <Text
            style={{
              color: "#999",
              fontSize: 10,
              textAlign: "center",
              marginTop: 3,
            }}
          >
            正确
          </Text>
        </View>
        <View
          style={{
            width: "34%",
            height: "70%",
            borderLeftWidth: 1,
            borderColor: "#E5E5E5",
            borderRightWidth: 1,
          }}
        >
          <Text style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}>
            {TotalNum - RightNum}
          </Text>
          <Text
            style={{
              color: "#999",
              fontSize: 10,
              textAlign: "center",
              marginTop: 3,
            }}
          >
            错误
          </Text>
        </View>
        <View
          style={{
            width: "33%",
            height: "70%",
          }}
        >
          <Text style={{ fontSize: 18, color: "#333", textAlign: "center" }}>
            {TotalNum}
          </Text>
          <Text
            style={{
              color: "#999",
              fontSize: 10,
              textAlign: "center",
              marginTop: 3,
            }}
          >
            总数
          </Text>
        </View>
      </View>
      {Links.map((item, index) => {
        return (
          <View
            key={index}
            style={{
              paddingBottom: 10,
              borderColor: "#e5e5e5",
              borderBottomWidth: 1,
            }}
          >
            {item.map((row, key) => {
              return QuesItem(row, key);
            })}
          </View>
        );
      })}
    </View>
  );
}

function QuesItem(row, key) {
  return (
    <View key={key} style={{ paddingHorizontal: 20, paddingTop: 10 }}>
      <Text style={char.SENTENCE_STY}>
        {"Q" + (key + 1) + "、"}
        {initValue(row.source.trim(), row.word)}
      </Text>
      <View
        style={{
          flexDirection: "row",
          marginTop: 8,
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 14,
            lineHeight: 20,
            maxWidth: deviceWidth - 40 - 20 - 10,
          }}
        >
          {"我的答案："}
          <Text
            style={{
              color: row.isCorrect == 1 ? "#05c08b" : "#F56C6C",
              fontSize: 16,
            }}
          >
            {row.myAnswer}
          </Text>
        </Text>
        <View
          style={{
            paddingLeft: 10,
          }}
        >
          {row.isCorrect == 1 ? (
            <Image
              source={require("../../../images/public/complete.png")}
              style={test.sentence_R_Img}
            />
          ) : (
            <Image
              source={require("../../../images/public/noComplete.png")}
              style={test.sentence_R_Img}
            />
          )}
        </View>
      </View>

      <Text style={{ ...char.SENTENCE_MEAN_STY, marginTop: 8 }}>
        {row.senMeaning}
      </Text>
    </View>
  );
}
