import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import config from "../../Component/Config";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import ReturnPHCom from "../../Component/returnPHCom";
import CustomStorage from "../../Component/CustomStorage";
import Loading from "../../Component/Loading";
import BottomBlock from "../../AmoduleCollection/BottomBlock";

import BtnTree from "../../AmoduleCollection/ButtonNavigation";

let screenW = Dimensions.get("window").width;

export default class InternetWordsHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "内容资源",
      type: "POST",
      TypeList: [],
      pageState: 0,
      defaultProps: {
        Title: "GroupName",
        children: "wordsType",
        childName: "wordType2",
        childName_2: "wordType",
      },
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("InternetWordsHome", "内容资源");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getTypeSecond();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/

  getTypeSecond = () => {
    let info = { name: "", type: this.state.type };
    HttpUtil.post("7706_7", info).then((response) => {
      console.log("7706_7", response);
      if (response.Code == 1) {
        let data = this.handleProgressShow(response.Data);
        this.setState({
          TypeList: data,
          pageState: 1,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  handleProgressShow = (Array) => {
    if (JSON.parse(localStorage.getItem("StorageProgress")) == null) {
      return Array;
    }
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"))[
      "ContentResources"
    ];
    for (let i = 0; i < Array.length; i++) {
      for (let z = 0; z < Array[i].wordsType.length; z++) {
        for (let j = 0; j < storageData.length; j++) {
          if (storageData[j].ID == Array[i].wordsType[z].ID) {
            Array[i].wordsType[z].wordType2 =
              Array[i].wordsType[z].wordType +
              " ( " +
              (storageData[j].page + 1) +
              "/" +
              storageData[j].pageTotal +
              " )";
          }
        }
      }
    }
    return Array;
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };

  gotoTagSyntax = (item, index, data) => {
    let query = {
      wordtypeId: item.ID,
      wordtype: item.wordType,
      type: this.state.type,
    };
    this.props.navigation.navigate("InternetWordsDetail", query);
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <View></View>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  syntaxTitle = (item, index) => {
    if (item.wordsType != null) {
      return (
        <View style={{ marginTop: 20, paddingHorizontal: 20 }} key={index}>
          <View style={styles.syntaxTitleCon}>
            <View style={styles.syntaxTitleBefore}></View>
            <Text style={styles.syntaxTitleSty}>{item.GroupName}</Text>
          </View>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {item.wordsType.length > 0 &&
              item.wordsType.map((val, key) => {
                return this.syntaxTitle2(val, key, item);
              })}
          </View>
        </View>
      );
    } else {
      return <View key={index}></View>;
    }
  };

  syntaxTitle2 = (item, index, data) => {
    return (
      <TouchableOpacity
        onPress={() => this.gotoTagSyntax(item, index, data)}
        key={index}
        style={[styles.SubtagCon]}
      >
        <Text style={styles.SubtagSty}>{item.wordType2 || item.wordType}</Text>
      </TouchableOpacity>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#fff",
          }}
        >
          {this._renderHeader()}

          {this.state.pageState == 0 && <Loading />}
          {/* 页面顶部 */}
          {this.state.pageState == 1 && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ flex: 1, backgroundColor: "#fff" }}
            >
              {this.state.TypeList.length > 0 && (
                <BtnTree
                  TreeData={this.state.TypeList}
                  Props={this.state.defaultProps}
                  onClickBtn={this.gotoTagSyntax}
                />
              )}
              <BottomBlock />
            </ScrollView>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  syntaxTitleCon: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  syntaxTitleBefore: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  syntaxTitleSty: {
    fontSize: 16,
    color: "#333",
  },
  SubtagCon: {
    padding: 10,
    marginRight: 10,
    backgroundColor: "rgba(28, 118, 252, 0.1)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    borderRadius: 6,
  },
  SubtagSty: {
    color: "#333",
    fontSize: 12,
    paddingHorizontal: 5,
    textAlign: "center",
  },
});
