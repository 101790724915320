import React from "react";
import {
  Dimensions,
  PermissionsAndroid,
  TouchableOpacity,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
  Modal,
  Image,
} from "react-native";
import BaseView from "../../Component/BaseView";
import HttpUtil from "../../Component/HttpUtil";
import Loading from "../../Component/Loading";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import ReturnPHCom from "../../Component/returnPHCom";
import Empty from "../../Component/Empty";
import WebView from "react-native-webview";
import RenderHtml from "react-native-render-html";
import { removeElement, isTag } from "domutils";
import resource from "../ApublicResourceSty";
import Color from "../../Component/Color";
import Paginator from "../../Component/Paginator";
import { initValue } from "../../Component/WordHighLight";
import tagsStyles from "../../ApublicStyle/TagsStyles";
import PatSty from "../../ApublicStyle/ZebraPatternData";
import char from "../../ApublicStyle/CharacterStyle";
const Dwidth = Dimensions.get("window").width; //414

export default class KP_ContentIntegration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      documentTitle: this.props.navigation.state.params.Title,
      dictID: this.props.navigation.state.params.dictID,
      pageValue: this.props.navigation.state.params.pageValue,
      navOptions: [
        { name: "考点内容", Value: 1 },
        { name: "单词短语", Value: 2 },
        { name: "考点语句", Value: 3 },
      ],
      Index: 0,
      pageTotal: 0,
      pageState: 0, // 1 语法 2 单词短语 3 考点语句
      // 语法
      ContentData: [],
      // 单词短语
      WordsData: [],
      WordsDataAll: [],
      // 语句
      SentenceData: [],
      SentenceDataAll: [],
      // 弹窗
      modalVisible: false,
      isClick: false,
      practiceList: [
        {
          name: "单词练习",
          isClick: false,
          router: "KP_WordsTest",
        },
        {
          name: "语句练习",
          isClick: false,
          router: "KP_SentenceTest",
        },
        {
          name: "辨析练习",
          isClick: false,
          router: "KP_SA_SentenceTest",
        },
      ],
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {
    if (this.props.navigation.state.params.GramTestQuesNum > 0) {
      this.state.practiceList.push({
        name: "语法测试",
        isClick: false,
        router: "KP_GrammarTest",
      });
      this.setState({
        practiceList: this.state.practiceList,
      });
    }
  }

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("KP_ContentIntegration", "知识点内容整合");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      this.getRoot();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getRoot = () => {
    if (this.state.pageValue == 1) {
      this.getPointsContent();
      return;
    }
    if (this.state.pageValue == 2) {
      this.getPointsWords();
      return;
    }
    if (this.state.pageValue == 3) {
      this.getPointsSentence();
      return;
    }
  };

  getPointsContent = () => {
    let info = {
      dictId: this.state.dictID,
    };
    HttpUtil.post("626262", info).then((response) => {
      console.log("626262 获取知识点内容", response);
      if (response.Code == 1) {
        let data = response.Data;
        for (let i = 0; i < data.length; i++) {
          let arr = data[i].memo.trim().split("");
          if (arr[0] != "<") {
            data[i].memo = "<p>" + data[i].memo + "</p>";
          }
        }
        this.setState({
          ContentData: data,
          pageState: 1,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  getPointsWords = () => {
    let info = {
      dictId: this.state.dictID,
    };
    HttpUtil.post("626462", info).then((response) => {
      console.log("626462 获取知识点单词", response);
      if (response.Code == 1) {
        let data = response.Data;
        this.setState(
          {
            WordsDataAll: data,
          },
          () => {
            this.handleWordsData();
          }
        );
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  getPointsSentence = () => {
    let info = {
      dictId: this.state.dictID,
    };
    HttpUtil.post("626562", info).then((response) => {
      console.log("626562 获取知识点句子", response);
      if (response.Code == 1) {
        let data = this.handleSentenceData(response.Data);
        this.handleSentenceAllArray(data);
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  handleWordsData = () => {
    let data = this.state.WordsDataAll;
    let dataChildArr = [];
    let total = 0,
      pageSize = 15;
    total = Math.ceil(data.length / pageSize);
    for (let i = 0; i < total; i++) {
      let Array = data.slice(i * pageSize, i * pageSize + pageSize);
      dataChildArr.push(Array);
    }
    this.setState(
      {
        Index: 0,
        pageTotal: total,
        WordsData: dataChildArr,
        pageState: 2,
      },
      () => {
        // this.StorageProgress();
      }
    );
  };

  handleSentenceData = (data) => {
    let arr = data.map((item) => item.word);
    const wordsOrder = [...new Set(arr)];
    // 定义一个空对象来存放处理后的结果
    const resultMap = {};
    // 遍历数组
    data.forEach((obj) => {
      const { ID, word } = obj;
      // 如果 word 是重复的
      if (resultMap[word]) {
        resultMap[word].Children.push(obj);
      } else {
        resultMap[word] = {
          ID,
          word,
          Children: [obj],
        };
      }
    });
    // 按照 wordsOrder 的顺序重排结果数组
    const resultArray = wordsOrder
      .map((word) => resultMap[word])
      .filter((item) => item);
    return resultArray;
  };

  handleSentenceAllArray = (data) => {
    let pageSize = 3;
    let pageTotal = Math.ceil(data.length / pageSize);
    let Arrarr = [];
    for (let i = 0; i < pageTotal; i++) {
      let Array = data.slice(i * pageSize, (i + 1) * pageSize);
      Arrarr.push(Array);
    }
    this.setState({
      Index: 0,
      pageState: 3,
      pageTotal: pageTotal,
      SentenceDataAll: Arrarr,
    });
  };

  navWidth = (index) => {
    return 100 / this.state.navOptions.length + "%";
  };

  // 打开
  open = () => {
    this.setState({
      modalVisible: true,
    });
  };

  // 关闭
  close = () => {
    this.setState({
      modalVisible: false,
    });
  };

  gotoTest = (item) => {
    this.close();
    let query = {
      Title: item.name,
      dictID: this.state.dictID,
      dictValue: this.state.dictValue,
      documentTitle: this.state.documentTitle,
    };
    this.props.navigation.navigate(item.router, query);
  };

  handlePressIn = (item) => {
    item.isClick = true;
    let newData = JSON.parse(JSON.stringify(this.state.practiceList));
    this.setState({
      practiceList: newData,
    });
  };

  handlePressOut = (item) => {
    item.isClick = false;
    let newData = JSON.parse(JSON.stringify(this.state.practiceList));
    this.setState({
      practiceList: newData,
    });
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  handlePageChange = (page) => {
    let i = page - 1;
    this.myRef.current.scrollTo({ x: 0, y: 0, animated: true });
    this.setState({
      Index: i,
    });
  };

  changeNav = (item) => {
    if (item.Value == this.state.pageValue) {
      return;
    }
    this.setState(
      {
        Index: 0,
        pageTotal: 0,
        pageState: 0,
        pageValue: item.Value,
      },
      () => {
        this.getRoot();
      }
    );
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <TouchableOpacity
          onPress={() => this.open()}
          style={{
            width: 40,
            heigh: 40,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight + 8,
            right: 10,
          }}
        >
          <Image
            source={require("../../images/class/knowledgeTest.png")}
            style={{ width: 23, height: 23 }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  _renderContentItem = (item, index) => {
    return (
      <View
        style={[resource.BookConSty, { marginTop: index == 0 && 20 }]}
        key={index}
      >
        <View style={resource.BookTopBgSty}>
          <Text style={resource.DetailTitle}>
            {index + 1 + ". " + item.title}
          </Text>
        </View>
        <View style={{ padding: 10 }}>
          <RenderHtml
            contentWidth={Dwidth - 30 * 2}
            source={{ html: item.memo }}
            WebView={WebView}
            ignoredStyles={["fontSize", "height", "width", "margin"]}
            tagsStyles={tagsStyles}
          />
        </View>
      </View>
    );
  };

  _renderWordsItem = (item, index) => {
    return (
      <View
        style={[
          styles.courseContentCon,
          { backgroundColor: index % 2 === 0 ? Color.ZebraPattern : "#ffffff" },
        ]}
        key={index}
      >
        <View style={styles.courseContentCon1}>
          <View style={styles.courseContentItem1}>
            <View style={PatSty.flexDirection1}>
              <Text style={PatSty.Text_Word_Index}>{index + 1 + ".  "}</Text>
              <View style={{ flex: 1 }}>
                <Text style={PatSty.Text_Word_Index}>
                  {item.word}
                  <Text style={PatSty.Text_Chinese}>{item.meaning}</Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  _renderSentenceItem = (item, index) => {
    return (
      <View
        style={[resource.BookConSty, { marginTop: index == 0 && 20 }]}
        key={index}
      >
        <View style={resource.BookTopBgSty}>
          <Text style={resource.DetailTitle}>{item.word}</Text>
        </View>
        {item.Children.map((row, key) => {
          return this.sentenceItem(row, key, item.word);
        })}
      </View>
    );
  };

  sentenceItem = (item, index) => {
    return (
      <View
        style={[
          styles.courseContentConSen,
          {
            borderTopWidth: index != 0 && 1,
          },
        ]}
        key={index}
      >
        <View style={styles.courseContentCon1Sen}>
          <View style={styles.courseContentItem1Sen}>
            <View style={[styles.flex_row, { alignItems: "baseline" }]}>
              <Text style={char.SENTENCE_STY}>
                {index + 1 + ".  "}
                {initValue(item.sentence, item.word)}
              </Text>
            </View>
            <Text style={char.SENTENCE_MEAN_STY}>{item.senMeaning}</Text>
          </View>
        </View>
      </View>
    );
  };

  _renderModal = () => {
    return (
      <Modal
        style={{ position: "relative" }}
        animationType="fade"
        transparent={true}
        visible={this.state.modalVisible}
        onRequestClose={() => this.close()}
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <Text
            onPress={() => this.close()}
            style={{ height: "100%", width: "100%" }}
          ></Text>
          <View style={styles.modalContent}>
            {this.state.practiceList.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  activeOpacity={1}
                  onPress={() => this.gotoTest(item)}
                  onPressIn={() => this.handlePressIn(item)}
                  onPressOut={() => this.handlePressOut(item)}
                  style={{
                    width: "100%",
                    height: 40,
                    paddingLeft: 15,
                    paddingRight: 15,
                    alignItems: "center",
                    justifyContent: "center",
                    borderColor: "#e5e5e5",
                    borderTopWidth: index != 0 && 1,
                    backgroundColor: item.isClick ? Color.background2 : "#fff",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      lineHeight: 40,
                    }}
                  >
                    {item.name}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
      </Modal>
    );
  };
  /*************************************************************************** 页面 ***************************************************************************/
  NavigationCom = () => {
    return (
      <View style={styles.navBoxSty}>
        {this.state.navOptions.map((item, index) => {
          return (
            <TouchableOpacity
              onPress={() => {
                this.changeNav(item);
              }}
              key={index}
              style={[
                styles.navItemSty,
                {
                  width: this.navWidth(index),
                },
              ]}
            >
              <Text
                style={[
                  styles.navName,
                  {
                    color:
                      item.Value == this.state.pageValue ? "#1c76fc" : "#333",
                  },
                ]}
              >
                {item.name}
              </Text>
              <View
                style={[
                  styles.navFoot,
                  {
                    backgroundColor:
                      item.Value == this.state.pageValue ? "#1c76fc" : "#fff",
                  },
                ]}
              ></View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  _renderContent = () => {
    return (
      <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        {this.state.ContentData.length > 0 &&
          this.state.ContentData.map((item, index) => {
            return this._renderContentItem(item, index);
          })}
        {this.state.ContentData.length == 0 && <Empty></Empty>}
      </ScrollView>
    );
  };

  _renderWords = () => {
    return (
      <ScrollView
        style={{ flex: 1 }}
        ref={this.myRef}
        showsVerticalScrollIndicator={false}
      >
        {this.state.WordsData.length > 0 &&
          this.state.WordsData[this.state.Index].map((item, index) => {
            return this._renderWordsItem(item, index);
          })}
        {this.state.WordsData.length == 0 && <Empty></Empty>}
        {/* <View style={{ width: "100%", height: 20 }}></View> */}
      </ScrollView>
    );
  };

  _renderSentence = () => {
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={this.myRef}
        style={{ flex: 1, backgroundColor: "#fff" }}
      >
        {this.state.SentenceDataAll.length > 0 &&
          this.state.SentenceDataAll[this.state.Index].map((item, index) => {
            return this._renderSentenceItem(item, index);
          })}
        {this.state.pageTotal == 0 && <Empty></Empty>}
      </ScrollView>
    );
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#fff",
          }}
        >
          {/* 页面顶部 */}
          {this._renderHeader()}
          {this.NavigationCom()}
          {this.state.pageState == 0 && <Loading></Loading>}
          {this.state.pageState == 1 && this._renderContent()}
          {this.state.pageState == 2 && this._renderWords()}
          {this.state.pageState == 3 && this._renderSentence()}
          {/* 分页器 */}
          {(this.state.pageState == 2 || this.state.pageState == 3) &&
            this.state.pageTotal > 0 && (
              <View style={resource.BottomChangeCon}>
                <Paginator
                  pageStart={this.state.Index}
                  totalPages={this.state.pageTotal}
                  onPageChange={this.handlePageChange}
                />
              </View>
            )}
        </View>
        {this._renderModal()}
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
  },
  courseContentCon: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    alignItems: "flex-start",
  },
  courseContentCon1: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  courseContentItem1: {
    flex: 1,
    // marginRight: 20,
  },
  /**句子 */
  courseContentConSen: {
    width: "100%",
    paddingVertical: 15,
    paddingHorizontal: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    alignItems: "flex-start",
    borderColor: "#e5e5e5",
  },
  courseContentCon1Sen: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  courseContentItem1Sen: {
    flex: 1,
    // marginRight: 20,
  },
  /**切换 */
  navBoxSty: {
    paddingHorizontal: 20,
    height: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  navItemSty: {
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
  },
  navName: {
    lineHeight: 28,
    marginBottom: 3,
  },
  navFoot: {
    width: 14,
    height: 3,
    borderRadius: 3,
  },
  /* 弹窗 */
  modalContent: {
    width: "35%",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 45 + BaseView.currentHeight,
    right: 20,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
});
