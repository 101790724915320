import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Dimensions,
  ScrollView,
  Image,
} from "react-native";
import BaseView from "../Component/BaseView";
import config from "../Component/Config";
import { ToastShort, consolePage } from "../Component/ToastUtlis";
import HttpUtil from "../Component/HttpUtil";

import Navigation from "../AmoduleCollection/IconNavigation";
import BottomBlock from "../AmoduleCollection/BottomBlock";

var deviceWidth = Dimensions.get("window").width;

let iconWH = deviceWidth * 0.9 * 0.1;

export default class ResourceHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      Nav0: {
        Title: "单词提升",
        IconList: [
          {
            name: "单词提升",
            imgUrl: require("../images/class/wordPromote.png"),
            router: "W_U_Home",
            query: {},
          },
          {
            name: "研词闯关",
            imgUrl: require("../images/class/classhome_icon002.png"),
            router: "Post_CL_Home",
            query: {},
          },
        ],
      },
      Nav1: {
        Title: "扩展资源",
        IconList: [
          {
            name: "分类单词",
            imgUrl: require("../images/class/classify.png"),
            router: "classifyWordHome",
            query: {},
          },
          {
            name: "分类词组",
            imgUrl: require("../images/class/phrase.png"),
            router: "classifyPhraseHome",
            query: {},
          },
          {
            name: "分类例句",
            imgUrl: require("../images/class/classhome_icon016.png"),
            router: "classifySentenceHome",
            query: {},
          },
          {},
          {
            name: "词组资源",
            imgUrl: require("../images/class/phrase2.png"),
            router: "phraseLook2",
            query: {
              type: "MID",
            },
          },
          {
            name: "词组例句",
            imgUrl: require("../images/class/phrase3.png"),
            router: "PhraseExampleSentenceHome",
            query: {
              type: "MID",
            },
          },
          {
            name: "例句资源",
            imgUrl: require("../images/class/juxing.png"),
            router: "ExampleSentenceHome",
            query: {
              type: "POST",
            },
          },
          {},
          {
            name: "语法资源",
            imgUrl: require("../images/class/classhome_icon013.png"),
            router: "syntaxHome",
            query: {},
          },
          {
            name: "内容资源",
            imgUrl: require("../images/class/InternetWords.png"),
            router: "InternetWordsHome",
            query: {},
          },
        ],
      },
      Nav2: {},
      Props_Nav2: {
        Title: "Title",
        list: "IconList",
        list_name: "DText",
        list_imgUrl: "imgUrl",
      },
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "ResourceHome");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      // 获取班级列表
      this.getClassList();
      this.getGroup();
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  // 获取班级列表
  getClassList = () => {
    var info = {
      UserId: config.user.ID,
      ts: config.user.TS,
    };
    HttpUtil.post("1101", info).then((response) => {
      console.log("获取班级列表 1101", response);
      if (response.Code == 1) {
        if (response.Data.length > 0) {
          response.Data.forEach((i) => {
            i.flag2 = false;
            i.showFlag = 0;
          });
          var tempClassInfo = "";
          for (let i = 0; i < response.Data.length; i++) {
            if (response.Data[i].flag == "1") {
              tempClassInfo = response.Data[i];
            }
          }
          if (tempClassInfo == "") {
            tempClassInfo = response.Data[0];
          }
          config.classMsg = tempClassInfo;
        } else {
          // 加入公共班级
          this.joinCommonClass();
        }
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  // 加入公共班级
  joinCommonClass = () => {
    var info = {
      ClassId: "ecda7bbd6a1e4e5eb464f2a3acc4b734",
      WxId: JSON.parse(localStorage.getItem("MyWxID")),
    };
    console.info("1110 info", info);
    HttpUtil.post("1110", info).then((response) => {
      console.info("1110加入班级", response);
      if (response.Code == 1) {
        // 获取班级列表
        this.getClassList();
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  getGroup = () => {
    let info = {
      Code: "grouping",
    };
    HttpUtil.post("76111", info).then((response) => {
      // console.log("76111  grouping 获取考点分组", response);
      if (response.Code == 1) {
        let data = response.Data;
        for (let i = 0; i < data.length; i++) {
          data[i].imgUrl = require(`../images/library/Group_${i % 9}.png`);
        }
        let nav = {
          Title: "考点提升",
          IconList: data,
        };
        this.setState({
          Nav2: nav,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  // 扩展提示
  handleIconClick = (item) => {
    if (item.router == undefined) {
      return;
    }
    if (item.query) {
      this.props.navigation.navigate(item.router, item.query);
      return;
    }
  };

  handleIconClick_KP = (item) => {
    let query = {
      ParentId: item.ID,
      DText: item.DText,
    };
    this.props.navigation.navigate("KP_Home", query);
  };

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor="rgba(0,0,0,0)"
          translucent={true}
          barStyle={"dark-content"}
        />
        <View style={{ height: "100%" }}>
          <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
            <Navigation
              nav={this.state.Nav0}
              onIconClick={this.handleIconClick}
            />
            <Navigation
              nav={this.state.Nav1}
              onIconClick={this.handleIconClick}
            />
            {this.state.Nav2.Title && (
              <Navigation
                nav={this.state.Nav2}
                Props={this.state.Props_Nav2}
                onIconClick={this.handleIconClick_KP}
              />
            )}
            <BottomBlock />
          </ScrollView>
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    width: "100%",
    flex: 1,
  },
  classInfoCon: {
    width: "100%",
    marginTop: 20,
  },
  info: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: "#E6E6E6",
  },
  info_book: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleCon: {
    marginHorizontal: 20,
    marginTop: 12,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  titleLine: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  titleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
  },
  flexRow: {
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    borderBottomColor: "#e5e5e5",
    flexWrap: "wrap",
  },
  btnSty: {
    marginBottom: 10,
    flexDirection: "column",
    alignItems: "center",
  },
  iconOutSty: {
    padding: 8,
    borderRadius: 10,
    backgroundColor: "#fff",
  },
  btn_sty: {
    width: "48%",
    height: 49,
    marginTop: 30,
    backgroundColor: "#1c76fc",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  modalContent: {
    width: "70%",
    height: "100%",
    backgroundColor: "#ffffff",
  },
  treeLine: {
    width: "100%",
    height: 46,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  treeInLine: {
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textShow: {
    color: "#333",
    flex: 1,
    lineHeight: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  btn_detail: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
