import React from "react";
import {
  StyleSheet,
  Text,
  View,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import Color from "../../Component/Color";
import ReturnPHCom from "../../Component/returnPHCom";
import Paginator from "../../Component/Paginator";
import CustomStorage from "../../Component/CustomStorage";
import PatSty from "../../ApublicStyle/ZebraPatternData";
let screenW = Dimensions.get("window").width;

import Loading from "../../Component/Loading";

export default class W_U_Browsing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.myRef = React.createRef();
    this.state = {
      documentTitle: this.props.navigation.state.params.item.menuName,
      word: "",
      changdu: "",
      parentId: this.props.navigation.state.params.item.id,
      wordList: [],
      pageState: 0,
      grade: 1,
      maxGrade: 0,
      GradeList: [],
      SPName: "PreparatoryVocabulary",
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.getMaxGrade();
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("W_U_Browsing");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
      if (this.props.navigation.state.params.item.grade) {
        this.setState(
          {
            grade: this.props.navigation.state.params.item.grade,
          },
          () => {
            this.getData();
          }
        );
      } else {
        this.ValuePage();
      }
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };

  ValuePage = () => {
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"));
    if (storageData == null) {
      this.getData();
      return;
    }
    if (storageData[this.state.SPName] == undefined) {
      this.getData();
      return;
    }
    for (let i = 0; i < storageData[this.state.SPName].length; i++) {
      if (storageData[this.state.SPName][i].ID == this.state.parentId) {
        this.setState(
          {
            grade: storageData[this.state.SPName][i].page,
          },
          () => {
            this.getData();
          }
        );
        return;
      }
    }
    this.getData();
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getData = () => {
    let info = {
      word: this.state.word,
      parentId: this.state.parentId,
      changdu: this.state.changdu,
      grade: this.state.grade,
    };
    let api = "";
    if (
      this.props.navigation.state.params.item &&
      this.props.navigation.state.params.item.type == "heigh"
    ) {
      api = "81010";
    } else {
      api = "81020";
    }
    HttpUtil.post(api, info).then((response) => {
      console.log(api, response);
      if (response.Code == 1) {
        let data = response.Data;
        this.setState(
          {
            wordList: data,
            pageState: 1,
          },
          () => {
            this.StorageProgress();
          }
        );
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  getMaxGrade = () => {
    let info = {
      parentId: this.state.parentId,
    };
    let api = "";
    if (
      this.props.navigation.state.params.item &&
      this.props.navigation.state.params.item.type == "heigh"
    ) {
      api = "81010_1";
    } else {
      api = "81020_1";
    }
    HttpUtil.post(api, info).then((response) => {
      console.log(api, response);
      if (response.Code == 1) {
        let data = response.Data;
        this.setState({
          maxGrade: data,
        });
      } else {
        ToastShort(response.Msg);
      }
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  handlePageChange = (page) => {
    this.myRef.current.scrollTo({ x: 0, y: 0, animated: true });
    this.setState(
      {
        grade: page,
      },
      () => {
        this.getData();
      }
    );
  };

  StorageProgress = () => {
    CustomStorage.StorageProgress(
      this.state.SPName,
      this.state.parentId,
      this.state.documentTitle,
      this.state.grade,
      this.state.maxGrade
    );
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }

  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
      </View>
    );
  };

  _renderItem = (item, index) => {
    return (
      <View
        style={[
          styles.courseContentCon,
          { backgroundColor: index % 2 === 0 ? Color.ZebraPattern : "#ffffff" },
        ]}
        key={index}
      >
        <View style={styles.courseContentCon1}>
          <View style={styles.courseContentItem1}>
            <View style={PatSty.flexDirection1}>
              <Text style={PatSty.Text_Word_Index}>{index + 1 + ".  "}</Text>
              <View style={{ flex: 1 }}>
                <Text style={PatSty.Text_Word_Index}>
                  {item.word}
                  <Text style={PatSty.Text_Chinese}>{item.meaning}</Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  changeBtnCon = () => {
    let pageStart = Number(this.state.grade) - 1;
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingHorizontal: 20,
          backgroundColor: "#fff",
          height: 40,
        }}
      >
        {/* 分页器 */}
        <Paginator
          pageStart={pageStart}
          totalPages={this.state.maxGrade}
          onPageChange={this.handlePageChange}
        />
      </View>
    );
  };

  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {this._renderHeader()}

          {this.state.pageState == 1 && (
            <ScrollView
              ref={this.myRef}
              style={{ flex: 1, backgroundColor: "#fff" }}
              showsVerticalScrollIndicator={false}
            >
              {this.state.wordList.length > 0 &&
                this.state.wordList.map((item, index) => {
                  return this._renderItem(item, index);
                })}
            </ScrollView>
          )}
          {this.state.pageState == 0 && <Loading></Loading>}

          {this.state.pageState == 1 &&
            this.state.maxGrade > 0 &&
            this.changeBtnCon()}
        </View>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
  },
  courseContentCon: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    alignItems: "flex-start",
  },
  courseContentCon1: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  courseContentItem1: {
    flex: 1,
    // marginRight: 20,
  },
  courseContentItem2Txt: {
    fontSize: 16,
    color: "#666666",
    marginLeft: 10,
  },
  modalContent: {
    width: "70%",
    height: "100%",
    backgroundColor: "#ffffff",
  },
  treeLine: {
    width: "100%",
    height: 46,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  treeInLine: {
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textShow: {
    color: "#333",
    flex: 1,
    lineHeight: 30,
    flexDirection: "row",
    alignItems: "center",
  },
});
