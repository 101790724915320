import { Dimensions } from "react-native";

var deviceWidth = Dimensions.get("window").width;

let Row_Num = 10;
let Margin_Space = 6;
let Btn_WH = (deviceWidth - 40 - Margin_Space * (Row_Num - 1)) / Row_Num;
let Margin_Space1 = 8;
let Btn_WH1 = (deviceWidth - 40 - Margin_Space1 * (Row_Num - 1)) / Row_Num;

const dare = {
  // 闯关每行按钮数量
  Row_Num: Row_Num,
  // 按钮间距
  Margin_Space: Margin_Space,
  // 按钮样式
  Btn_Sty: {
    width: Btn_WH,
    height: Btn_WH,
    borderRadius: Btn_WH / 2,
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  // 按钮字体样式
  Btn_Font_Sty: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  /********** 分组按钮 **********/
  Group_Btn: {
    marginRight: 10,
    // paddingHorizontal: 5,
  },
  Group_Btn_Font: {
    width: Btn_WH1,
    header: Btn_WH1,
    lineHeight: Btn_WH1,
    textAlign: "center",
    borderRadius: 20,
    fontSize: 14,
  },
};

export default dare;
