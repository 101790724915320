import React from 'react';
import {
    View,
    StyleSheet,
    Platform,
    StatusBar,
    SafeAreaView,
    Dimensions,
    Modal
} from 'react-native';
import color from './Color';
import { isIphoneX } from './isIphoneX';
// import Orientation from 'react-native-orientation';
import Dimension from './Dimension';

const STATUSBAR_HEIGHT = 20;
const STATUSBAR_BACKGROUNDCOLOR = '#FF9C1E';

let screenW = Dimensions.get('window').width; //414
let screenH = Dimensions.get('window').height;  //896
// iPhoneX iPhoneXS
const X_WIDTH = 375;
const X_HEIGHT = 812;

//iPhone XR
const XR_Width = 414;
const XR_Height = 896;

export default class BaseView extends React.PureComponent {
    static currentHeight = 10;
    static currentHeight2 = 10;
    constructor(props) {
        super(props);
        this.state = {
            marginT: isIphoneX == 2164 ? -44 : -20
        }

    };
    // //判断手机屏幕状态（横屏或者竖屏）
    // isOrientation = () => {
    //     var initial = Orientation.getInitialOrientation();
    //     if (initial === 'PORTRAIT') {
    //         //竖屏

    //     } else {
    //         //横屏
    //     }
    // }
    // // 强制竖屏
    // vertical = () => {
    //     Orientation.lockToPortrait();
    // }

    // // 强制横屏
    // across = () => {
    //     Orientation.lockToLandscape();
    // }

    componentWillMount() {

        this.isX = this.isIphoneX();
        this.marginT = -20;
        if (this.isX) {
            this.setState({
                marginT: -44
            })
        }
    }

    isIphoneX = () => {
        return (
            Platform.OS === 'ios' &&
            ((screenH === X_HEIGHT && screenW === X_WIDTH) ||
                (screenH === X_WIDTH && screenW === X_HEIGHT))
        )
    }

    componentDidMount() {
        // this.vertical();
    }



    render() {
        const { bgColor, style, translucent, isloading } = this.props

        return (
            <View style={{ backgroundColor: bgColor != undefined ? bgColor : color.background, flex: 1, width: Dimension.width, height: Dimension.height }}>
                <StatusBar hidden={false} backgroundColor={'#55118a'} barStyle={'light-content'} />
                {this.props.children}
                {isloading && this.renderIsLoading()}
            </View>
        )
    }

    renderIsLoading() {
        return (
            <Modal
                animationType='fade'            // 淡入淡出
                transparent={true}              // 透明
                visible={true}    // 根据isModal决定是否显示
                onRequestClose={() => { this.props.onRequestClose }}  // android必须实现  //android按物理按键时触发的函数
            >
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <View>
                        {/* <ActivityIndicator
                            animating={true}
                            size='small'
                            color='gray'
                            style={{ alignItems: 'center', justifyContent: 'center', padding: 8 }}></ActivityIndicator>
                        <Text>加载中…</Text> */}
                    </View>
                </View>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    baseView: {
        flex: 1,
        backgroundColor: color.background,
        // alignItems: 'center',
    }


})