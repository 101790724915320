import React, { Component } from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity, Image, Text } from 'react-native';
import { PanGestureHandler, State } from 'react-native-gesture-handler';

const { width, height } = Dimensions.get('window'); // 获取屏幕宽度和高度
const RETURN_CONTAINER_WIDTH = 50; // 返回按钮容器的宽度
const RETURN_CONTAINER_HEIGHT = 50; // 返回按钮容器的高度

class returnPHCom extends Component {
  constructor(props) {
    super(props);
    let savedPositionString = localStorage.getItem('returnOffset');
    let savedPosition = savedPositionString ? JSON.parse(savedPositionString) : null;
    let defaultTranslateX = savedPosition && width > savedPosition.x && savedPosition.x >= 10 ? savedPosition.x : width - 110;
    let defaultTranslateY = savedPosition && savedPosition.y > 0 && savedPosition.y <= height ? savedPosition.y : (height - 100) / 2;
    this.state = {
      translateX: defaultTranslateX,
      translateY: defaultTranslateY,
      lastOffsetX: defaultTranslateX, // 添加一个状态来保存上一次的水平偏移量
      lastOffsetY: defaultTranslateY, // 添加一个状态来保存上一次的垂直偏移量
    };
  }

  back = () => {
    this.props._setReturnName('back');
  }

  onGestureEvent = event => {
    let newTranslateX = this.state.lastOffsetX + event.nativeEvent.translationX;
    let newTranslateY = this.state.lastOffsetY + event.nativeEvent.translationY;
    // 确保盒子不超出屏幕的左右边界和上下边界
    newTranslateX = Math.max(10, Math.min(newTranslateX, width - RETURN_CONTAINER_WIDTH - 10));
    newTranslateY = Math.max(30, Math.min(newTranslateY, height - RETURN_CONTAINER_HEIGHT - 30));
    this.setState({
      translateX: newTranslateX,
      translateY: newTranslateY,
    });
  };

  onHandlerStateChange = event => {
    if (event.nativeEvent.state === State.END) {
      let finalTranslateX = this.state.translateX;
      let finalTranslateY = this.state.translateY;
      // 判断是否应该吸附到左侧或右侧
      // if (finalTranslateX < width / 2) {
      //   finalTranslateX = 10; // 吸附到左侧并留有10的距离
      // } else {
      //   finalTranslateX = width - RETURN_CONTAINER_WIDTH - 10; // 吸附到右侧并留有10的距离
      // }
      let obj = {
        x: finalTranslateX,
        y: finalTranslateY,
      }
      localStorage.setItem('returnOffset', JSON.stringify(obj));
      this.setState({
        translateX: finalTranslateX,
        translateY: finalTranslateY,
        lastOffsetX: finalTranslateX, // 保存当前位置为下一次的起始水平偏移
        lastOffsetY: finalTranslateY, // 保存当前位置为下一次的起始垂直偏移
      });
    }
  };

  render() {
    const { translateX, translateY } = this.state;

    return (
      <View style={styles.container}>
        <PanGestureHandler
          onGestureEvent={this.onGestureEvent}
          onHandlerStateChange={this.onHandlerStateChange}>
          <TouchableOpacity activeOpacity={0.8} onPress={this.back} style={[styles.returnBtn, {
            transform: [
              { translateX: translateX },
              { translateY: translateY },
            ],
          },]}>
            <Image
              source={require("../images/public/returnPop.png")}
              style={{ width: 18, height: 18 }}
            />
            <Text style={styles.returnTxt}>返回</Text>
          </TouchableOpacity>
        </PanGestureHandler>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 100,
  },
  returnBtn: {
    width: 50,
    height: 50,
    backgroundColor: '#f77b0094',
    borderRadius: 25,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  returnTxt: {
    fontSize: 12,
    color: '#ffffff',
    marginTop: 5,
  }
});

export default returnPHCom;