import { ToastShort } from "./ToastUtlis";
import config from "../Component/Config";
import axios from "axios";
import qs from "qs";

// 110线上地址
let KmBaseUrl = "http://www.math110.com/Math110Back/MobService/index";

export default class HttpUtil {
  static UrlReturn() {
    return KmBaseUrl;
  }

  static get(url) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static addQueryString = (params) => {
    var str = "";
    for (var Key in params) {
      str += Key + "=" + params[Key] + "&";
    }
    return "?" + str;
  };

  static futch = (url, opts = {}, onProgress) => {
    console.log(url, opts);
    return new Promise((res, rej) => {
      var xhr = new XMLHttpRequest();
      xhr.open(opts.method || "get", url);
      for (var k in opts.headers || {})
        xhr.setRequestHeader(k, opts.headers[k]);
      xhr.onload = (e) => res(e.target);
      xhr.onerror = rej;
      if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.send(opts.body);
    });
  };

  static post(code, data) {
    return new Promise((resolve, reject) => {
      let param = {
        jyh: "",
        parm: "",
      };
      // console.info("获取新接口")
      param.jyh = code;
      param.parm = JSON.stringify(data);
      var tempToken = "";

      storage
        .load({
          key: "userToken",
        })
        .then((ret) => {
          var configTempUser = "";
          if (ret) {
            tempToken = ret;
            configTempUser = ret;
            fetch(KmBaseUrl, {
              method: "POST",
              headers: {
                "content-type": "application/x-www-form-urlencoded",
                "X-Token": tempToken,
              },
              body: qs.stringify(param),
            })
              .then((response) => response.json())
              .then((result) => {
                // console.log(result);
                var response =
                  result.result != undefined
                    ? JSON.parse(result.result)
                    : result;
                resolve(response);
              })
              .catch((e) => {
                console.info(e);
                ToastShort(config.error);
              });
          } else {
            fetch(KmBaseUrl, {
              method: "POST",
              headers: {
                "content-type": "application/x-www-form-urlencoded",
                "X-Token": tempToken,
              },
              body: qs.stringify(param),
            })
              .then((response) => response.json())
              .then((result) => {
                // console.info(result)
                var response = JSON.parse(result.result);
                resolve(response);
              })
              .catch((e) => {
                console.info(e);
                ToastShort(config.error);
              });
          }
        })
        .catch((e) => {
          fetch(KmBaseUrl, {
            method: "POST",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              "X-Token": tempToken,
            },
            body: qs.stringify(param),
          })
            .then((response) => response.json())
            .then((result) => {
              // console.info(result)
              var response = JSON.parse(result.result);
              resolve(response);
            })
            .catch((e) => {
              console.info(e);
              ToastShort(config.error);
            });
        });
    });
  }

  static isEmptyObject(obj) {
    for (var key in obj) {
      return false; // 返回false，不为空对象
    }
    return true; // 返回true，为空对象
  }

  // 先定义延时函数
  static delay(timeOut = 8 * 1000) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("网络超时"));
      }, timeOut);
    });
  }

  // fetch网络请求
  static fetchPromise = (method, url, formData) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            reject(new Error("服务器异常"));
          }
        })
        .then((responseJson) => {
          resolve(responseJson);
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  };

  // race任务
  static _fetch = (fetchPromise, timeout) => {
    return Promise.race([fetchPromise, this.delay(timeout)]);
  };

  // post
  static HttpPost = (formData, timeout = 30 * 1000) => {
    return this._fetch(this.fetchPromise("POST", KmBaseUrl, formData), timeout);
  };

  // get
  static HttpGet = (url, timeout = 8 * 1000) => {
    return this._fetch(fetchPromise("Get", url), timeout);
  };
}
