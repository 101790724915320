/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 * @flow
 */

import React, { Component } from 'react';
import { ModalPortal } from 'react-native-modals';
import {
  StatusBar, View
} from 'react-native';
import { RootSiblingParent } from 'react-native-root-siblings';

import StackNavigate from './src/stackNavigator';
import * as dd from 'dingtalk-jsapi';
import HttpUtil from './src/Component/HttpUtil'

export default class App extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    // 获取钉钉或企业微信信息
    // this.getUrl();
    console.info('打印输出++++');
  }
  
  // 通过免登授权码获取信息
  determineIfLoginIsRequired = (loginCode) => {
    alert('222')
    var dingdingUserInfoUrl = 'https://oapi.dingtalk.com/topapi/v2/user/getuserinfo?access_token=6e8840f9a9483ade921af323debe0d35';
    dd.httpRequest({
      headers: {
        "Content-Type": "application/json"
      },
      url: dingdingUserInfoUrl,
      method: 'POST',
      // 需要手动调用JSON.stringify将数据进行序列化
      data: JSON.stringify({
        code: loginCode,
      }),
      dataType: 'json',
      success: function (res) {
        console.log('res', res);
        alert(JSON.stringify(res))
        // ToastShort(res);
        this.setState({
          userDingID: res.id,
        });
        alert(33)
      },
      fail: function (err) {
        ToastShort('fail');
        ToastShort(JSON.stringify(err));
        alert(33)
      },
      complete: function (err) {
        ToastShort('fail');
        ToastShort(JSON.stringify(err));
        alert(33)
      }
    });
  }

  render() {
    return (

      <RootSiblingParent>
        <StackNavigate screenProps={{ statusBarHeight: StatusBar.currentHeight }} />
        <ModalPortal />
      </RootSiblingParent>
      // <View>

      //   <ModalPortal />
      // </View>

    );
  }
}

 //AppRegistry.registerComponent('MyProject', () => MyProject);
