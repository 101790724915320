import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  Image,
} from "react-native";
import { ToastShort, consolePage } from "./ToastUtlis";

const Paginator = ({ pageStart, totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(pageStart + 1);
  const [inputPage, setInputPage] = useState(pageStart + 1);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setInputPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setInputPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  const handleHomepage = () => {
    setCurrentPage(1);
    setInputPage(1);
    onPageChange(1);
  };

  const handleLastpage = () => {
    setCurrentPage(totalPages);
    setInputPage(totalPages);
    onPageChange(totalPages);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      onPageChange(pageNumber);
    } else {
      ToastShort(`请输入有效的页数（1-${totalPages}）`);
    }
  };

  const handleTextChange = (obj) => {
    if (obj > totalPages) {
      return totalPages;
    }
    let valueLimit = /^\+?[1-9]\d*$/;
    if (valueLimit.test(obj)) {
      return obj;
    }
    return "";
  };

  return (
    <View style={styles.container}>
      <View style={styles.RowFlex}>
        <TouchableOpacity
          style={[styles.button, { marginRight: 3 }]}
          onPress={handleHomepage}
          disabled={currentPage === 1}
        >
          <Image
            source={require("../images/public/goHomepage.png")}
            style={styles.buttonImage}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={handlePrev}
          disabled={currentPage === 1}
        >
          <Image
            source={require("../images/public/previousPage.png")}
            style={styles.buttonImage}
          />
        </TouchableOpacity>
      </View>

      <Text style={styles.pageText}>
        {currentPage} / {totalPages}
      </Text>
      <View style={styles.RowFlex}>
        <TouchableOpacity
          style={styles.button}
          onPress={handleNext}
          disabled={currentPage === totalPages}
        >
          <Image
            source={require("../images/public/nextPage.png")}
            style={styles.buttonImage}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, { marginLeft: 3 }]}
          onPress={handleLastpage}
          disabled={currentPage === totalPages}
        >
          <Image
            source={require("../images/public/goLastpage.png")}
            style={styles.buttonImage}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.goToContainer}>
        <TextInput
          style={styles.input}
          keyboardType="numeric"
          value={inputPage}
          onChangeText={(text) => setInputPage(handleTextChange(text))}
          onSubmitEditing={handleGoToPage}
        />
        <TouchableOpacity onPress={handleGoToPage} style={styles.inputTextCon}>
          <Text style={styles.inputText}>{"Go"}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  button: {
    paddingHorizontal: 6,
    paddingVertical: 11,
    // marginHorizontal: 5,
  },
  buttonText: {
    color: "#333",
  },
  buttonImage: {
    width: 13,
    height: 13,
  },
  RowFlex: {
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonText2: {
    color: "#1c76fc",
  },
  pageText: {
    color: "#1c76fc",
    fontWeight: 500,
    marginHorizontal: 8,
    fontSize: 16,
  },
  goToContainer: {
    flexDirection: "row",
    alignItems: "center",
    // marginLeft: 20,
  },
  input: {
    borderWidth: 1,
    outlineWidth: 0,
    borderColor: "#ccc",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    width: 50,
    textAlign: "center",
  },
  goToButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    marginLeft: 10,
    borderColor: "#1c76fc",
    borderWidth: 1,
  },
  inputTextCon: {
    marginLeft: 10,
  },
  inputText: {
    fontSize: 16,
    color: "#333",
  },
});

export default Paginator;
