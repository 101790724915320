import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  StatusBar,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
} from "react-native";
import BaseView from "../../../Component/BaseView";
import config from "../../../Component/Config";
import { ToastShort, consolePage } from "../../../Component/ToastUtlis";
import HttpUtil from "../../../Component/HttpUtil";
import test from "../../../ApublicStyle/TestStyle";
import ReturnPHCom from "../../../Component/returnPHCom";
import Modal2, {
  ModalTitle,
  ModalFooter,
  ModalButton,
  ModalContent,
  ScaleAnimation,
} from "react-native-modals";

const punctuationMarks = /[，？ ！ ? : ; ! 。, .]/g;

export default class KP_SentenceTestDetail_Ass extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentTitle: "语句练习",
      list: [],
      quesIndex: 0,
      pageState: 0,
      isSwitch: false, // fasle: 英  true: 美
      isClick: false,
      typeName: "美",
      // 提交数据之后
      testQuestion: [],
      rightNum: "--",
      isSubmit: false,
      end: false,
      modalVisible2: false,
      isTip: false,
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    if (this.props.navigation.state.params) {
      this.getQuestions();
    }
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("组装", "KP_SentenceTestDetail_Ass");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  replaceString = (inputString) => {
    // 使用正则表达式替换'###'为换行符'\n'，'&&&'为'________'
    const replacedString = inputString
      .split(/&&&/g)
      .join("________")
      .split(/###/g)
      .join("\n");
    return replacedString;
  };

  getQuestions = () => {
    let info = {
      dictId: this.props.navigation.state.params.dictID,
    };
    HttpUtil.post("626561_2", info).then((res) => {
      let data = res.Data;
      console.log(data);
      if (data && data.length == 0) {
        this.setState({
          pageState: 1,
        });
        return;
      }
      data.forEach((i) => {
        i.isCorrect = "";
        i.selectedWords = Array.from({ length: i.children.length }, () => ({
          word: "",
          isSame: "",
          id: "",
          symbol: "",
        }));
        i.indexList = [];
        i.sentence.split(" ").forEach((a, b) => {
          if (this.getLastPunctuation(a) != "") {
            i.selectedWords[b].symbol = this.getLastPunctuation(a);
          }
        });
        i.children.forEach((j, index) => {
          j.select = 0; // 未选择
          j.id = index;
          // j.word = j.word.split(punctuationMarks).filter(item => item !== "?").join('');
        });
      });
      console.log("获取题目1120", data);
      this.setState({
        list: data,
        pageState: 1,
      });
    });
  };
  /*************************************************************************** 交互 ***************************************************************************/
  OptionRecord = (item) => {
    if (item.isCorrect == "1") {
      return "#05c08b";
    } else {
      return "#F56C6C";
    }
  };

  /*************************************************************************** 逻辑 ***************************************************************************/
  // 确认
  doQues = (item, index) => {
    let obj = this.state.list[this.state.quesIndex];
    if (obj.isCorrect != "") {
      // 选择之后 禁止修改
      return;
    }
    let queArr = obj.sentence.split(" ");
    // queArr.forEach((i) => {
    //   i.split(punctuationMarks).filter(item => item !== "?").join('')
    // })
    console.log(queArr);
    for (let i = 0; i < queArr.length; i++) {
      if (queArr[i] !== obj.selectedWords[i].word) {
        obj.selectedWords[i].isSame = 0;
        if (obj.isCorrect == "" || obj.isCorrect == "1") {
          obj.isCorrect = "0";
        }
      } else {
        obj.selectedWords[i].isSame = 1;
        if (obj.isCorrect == "") {
          obj.isCorrect = "1";
        }
      }
    }
    let newAnwer = JSON.parse(JSON.stringify(this.state.list));
    this.setState(
      {
        list: newAnwer,
      },
      () => {
        console.log(newAnwer);
      }
    );
    if (
      obj.isCorrect != "" &&
      this.state.quesIndex + 1 == this.state.list.length
    ) {
      this.setState({
        end: true,
      });
    }
  };

  select = (item, index, data) => {
    let obj = this.state.list[this.state.quesIndex];
    if (obj.isCorrect != "") {
      // 选择之后 禁止修改
      return;
    }
    if (item.select == 1) {
      return;
    }
    item.select = 1;
    let a = [];
    a = a.concat(...this.state.list);
    let updatedArray = [...data.selectedWords];
    let emptyIndex = updatedArray.findIndex((item) => item.word === "");
    if (emptyIndex !== -1) {
      updatedArray[emptyIndex].word = item.word;
      updatedArray[emptyIndex].id = item.id;
      a[this.state.quesIndex].selectedWords = updatedArray;
      this.setState({
        list: a,
      });
    }
  };

  remove = (item, index, data) => {
    let obj = this.state.list[this.state.quesIndex];
    if (obj.isCorrect != "") {
      // 选择之后 禁止修改
      return;
    }
    if (item.word == "") {
      return;
    }
    let a = [];
    a = a.concat(...this.state.list);
    let optionIndex = a[this.state.quesIndex].children.findIndex(
      (i) => i.id === item.id
    );
    let updatedArray = [...data.selectedWords];
    updatedArray[index].word = "";
    a[this.state.quesIndex].children[optionIndex].select = 0;
    a[this.state.quesIndex].selectedWords = updatedArray;
    this.setState({
      list: a,
    });
  };

  removeAll = (data) => {
    let a = [];
    a = a.concat(...this.state.list);
    a[this.state.quesIndex].selectedWords.forEach((i) => {
      i.word = "";
      i.isSame = "";
      i.id = "";
    });
    a[this.state.quesIndex].children.forEach((i) => {
      i.select = 0;
    });
    this.setState({
      list: a,
    });
  };

  // 下一题
  doNext = () => {
    // 提交
    if (this.state.end) {
      this.submit();
      return;
    }
    // 确认
    if (this.state.list[this.state.quesIndex].isCorrect == "") {
      this.doQues();
      return;
    }
    let num = this.state.quesIndex + 1;
    this.setState({
      quesIndex: num,
    });
  };

  // 返回
  nextRound = () => {
    this.props.navigation.navigate("classCourseSource");
  };

  // 提交
  submit = () => {
    if (this.state.isSubmit) {
      return;
    }
    let links = [];
    let num = 0;
    links = links.concat(this.state.list);
    links.forEach((item) => {
      if (item.isCorrect == "1") {
        num++;
      }
      let arr = [];
      item.selectedWords.forEach((i) => {
        arr.push(i.word);
      });
      item.myAnswer = arr.join(" ");
    });
    this.setState({
      isSubmit: true,
      rightNum: num,
      testQuestion: links,
    });
    // console.log(links);
    this.postData(links, num);
  };

  postData = (links, num) => {
    let info = {
      BookId: this.props.navigation.state.params.dictID,
      ClassId: config.classMsg.ClassId,
      StudentName: config.user.userName,
      StudentId: config.user.ID,
      StudentBh: config.user.userBh,
      AllNum: links.length,
      RightNum: num,
      RightRate: num / links.length,
      Type: 3,
      SubmitAnswer: JSON.stringify(links),
    };
    HttpUtil.post("626366", info)
      .then((res) => {
        console.log(res);
        if (res.Code == 1) {
          ToastShort("提交成功");
          this.setState({
            pageState: 2,
            isSubmit: true,
          });
        } else {
          ToastShort(res.Msg);
        }
      })
      .catch((e) => {
        console.log(e);
        ToastShort("提交失败");
      });
  };

  getLastPunctuation(str) {
    // 定义标点符号的正则表达式
    const punctuationRegex = /[^\w\s]/;
    // 使用正则表达式检查字符串的最后一个字符是否是标点符号
    const lastChar = str.trim().charAt(str.trim().length - 1);
    // 如果最后一个字符是标点符号，则返回该字符，否则返回空字符串
    return punctuationRegex.test(lastChar) ? lastChar : "";
  }
  /*************************************************************************** 跳转 ***************************************************************************/

  back = () => {
    if (this.state.quesIndex == 0 || this.state.isSubmit) {
      this.props.navigation.pop();
      return;
    } else {
      this.setState({
        modalVisible2: true,
      });
    }
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.back();
    }
  }

  confirm = () => {
    this.setState({
      modalVisible2: false,
    });
    this.props.navigation.pop();
  };

  close2 = () => {
    this.setState({
      modalVisible2: false,
    });
  };

  tip = () => {
    this.setState({
      isTip: !this.state.isTip,
    });
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
      </View>
    );
  };

  _renderNext = () => {
    return (
      <View>
        <View style={styles.NextBtn}>
          {/* 进度 */}
          <View
            style={{
              height: 40,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Text style={{ fontSize: 12 }}>{"练习进度"}</Text>
            <Text style={test.schedule_text}>
              {this.state.list && this.state.list.length > 0
                ? this.state.quesIndex + 1 + "/" + this.state.list.length
                : "-- / --"}
            </Text>
          </View>
          <Text style={{ fontSize: 14, color: "red" }}>退出将自动提交结果</Text>
          {/* 切换题目 */}
          {
            <TouchableOpacity
              onPress={() => {
                this.doNext();
              }}
              style={test.btn_noDisabled}
            >
              <Text style={{ fontSize: 15, color: "#fff" }}>
                {this.nextText()}
              </Text>
            </TouchableOpacity>
          }
        </View>
      </View>
    );
  };

  // 底部标签文本
  nextText = () => {
    if (
      this.state.list &&
      this.state.list.length != 0 &&
      this.state.list[this.state.quesIndex].isCorrect == ""
    ) {
      return "确 认";
    }
    if (
      this.state.list &&
      this.state.list.length != 0 &&
      this.state.quesIndex + 1 == this.state.list.length
    ) {
      return "提 交";
    }
    if (
      this.state.list &&
      this.state.list.length != 0 &&
      this.state.list[this.state.quesIndex].isCorrect != ""
    ) {
      return "下一题";
    }
  };

  stemCom = () => {
    let data = this.state.list[this.state.quesIndex];
    return (
      data &&
      data != "" && (
        <View style={test.stem_container}>
          <Text
            style={{
              fontSize: 13,
              color: "#003179",
              textAlign: "right",
              lineHeight: 24,
              marginTop: 10,
            }}
          >
            提示：点击横线上单词可清除
          </Text>
          {/* 题目 */}
          <View
            style={[
              test.stem_space,
              {
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              },
            ]}
          >
            {data.selectedWords.map((item, index) => {
              return (
                <View style={{ flexDirection: "row" }} key={index}>
                  <TouchableOpacity
                    onPress={() => this.remove(item, index, data)}
                    style={[styles.quesItem]}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 22,
                          fontWeight: 500,
                          color:
                            item.isSame == 1
                              ? "#1c76fc"
                              : item.isSame === 0
                              ? "#F56C6C"
                              : "#333",
                        },
                      ]}
                    >
                      {item.word
                        .split(punctuationMarks)
                        .filter((item) => item !== "?")
                        .join("")}
                    </Text>
                  </TouchableOpacity>
                  <Text
                    style={{ fontSize: 22, fontWeight: 500, color: "#333" }}
                  >
                    {item.symbol}
                  </Text>
                </View>
              );
            })}
          </View>
          {data && data.isCorrect == "" && (
            <View
              style={{
                marginTop: 20,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  style={{ width: 15, height: 15 }}
                  source={require("../../../images/class/tip.png")}
                />
                <TouchableOpacity onPress={() => this.tip()}>
                  <Text style={{ color: "#1c76fc" }}>提示</Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity onPress={() => this.removeAll(data)}>
                <Text style={{ color: "#1c76fc" }}>全部清除</Text>
              </TouchableOpacity>
            </View>
          )}
          {this.state.isTip && (
            <View
              style={{
                marginTop: 30,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  color: "#999",
                  fontWeight: 500,
                  lineHeight: 24,
                }}
              >
                {data.senMeaning}
              </Text>
            </View>
          )}
          {data && data.isCorrect != "" && this.SpellCorrectly(data)}
        </View>
      )
    );
  };

  SpellCorrectly = (data) => {
    return (
      <View
        style={{
          marginTop: 30,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={[test.option_index, { fontSize: 20 }]}>
          {data.sentence}
        </Text>
        <Text
          style={[
            {
              fontSize: 18,
              marginTop: 15,
              color: "#999",
              fontWeight: 500,
              lineHeight: 24,
            },
          ]}
        >
          {data.senMeaning}
        </Text>
      </View>
    );
  };

  optionCom2 = () => {
    let data = this.state.list[this.state.quesIndex];
    return (
      <View style={[styles.option_box]}>
        {data &&
          data.children.map((item, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => this.select(item, index, data)}
                style={[
                  test.Selection_Btn_Sty,
                  {
                    backgroundColor: item.select == 1 ? "#ecf5ff" : "#fff",
                    borderColor: item.select == 1 ? "#b3d8ff" : "#dcdfe6",
                  },
                ]}
              >
                <Text
                  style={[
                    test.option_value_E,
                    { color: item.select == 1 ? "#409eff" : "#606266" },
                  ]}
                >
                  {item.word
                    .split(punctuationMarks)
                    .filter((item) => item !== "?")
                    .join("")}
                </Text>
              </TouchableOpacity>
            );
          })}
      </View>
    );
  };

  fullLoading = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size={"large"} color={"#666"} animating={true} />
        <Text style={{ color: "#666", fontSize: 18, marginTop: 15 }}>
          正在加载中
        </Text>
      </View>
    );
  };

  testRecord = () => {
    return (
      <View style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          <View
            style={{ width: "100%", paddingHorizontal: 20, paddingTop: 20 }}
          >
            <Text
              style={{
                color:
                  this.state.rightNum / this.state.testQuestion.length > 0.8
                    ? "#40CC5C"
                    : "#E83333",
                fontSize: 20,
                fontWeight: 600,
                lineHeight: 20,
                textAlign: "center",
              }}
            >
              {(
                (this.state.rightNum / this.state.testQuestion.length) *
                100
              ).toFixed(0)}
              % 正确率
            </Text>
          </View>
          {/* 作答情况 */}
          <View
            style={{
              margin: 20,
              height: 58,
              borderRadius: 10,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ color: "#40CC5C", fontSize: 18, textAlign: "center" }}
              >
                {this.state.rightNum}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                正确
              </Text>
            </View>
            <View
              style={{
                width: "34%",
                height: "70%",
                borderLeftWidth: 1,
                borderColor: "#E5E5E5",
                borderRightWidth: 1,
              }}
            >
              <Text
                style={{ color: "#E83333", fontSize: 18, textAlign: "center" }}
              >
                {this.state.testQuestion
                  ? this.state.testQuestion.length - this.state.rightNum
                  : ""}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                错误
              </Text>
            </View>
            <View style={{ width: "33%", height: "70%" }}>
              <Text
                style={{ fontSize: 18, color: "#333", textAlign: "center" }}
              >
                {this.state.testQuestion.length}
              </Text>
              <Text
                style={{
                  color: "#999",
                  fontSize: 10,
                  textAlign: "center",
                  marginTop: 3,
                }}
              >
                总数
              </Text>
            </View>
          </View>
          {this.state.testQuestion &&
            this.state.testQuestion.length > 0 &&
            this.state.testQuestion.map((item, index) => {
              let ques = item.questions;
              return (
                <View
                  key={index}
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingHorizontal: 20,
                    marginBottom: 20,
                  }}
                >
                  <Text style={[test.option_index, { marginBottom: 5 }]}>
                    {index + 1 + ". " + item.sentence}
                  </Text>
                  <View style={{ marginBottom: 5, marginLeft: 18 }}>
                    <Text style={[test.option_index, { fontSize: 15 }]}>
                      {item.senMeaning}
                    </Text>
                    <Text
                      style={[
                        test.option_index,
                        { color: this.OptionRecord(item), marginTop: 5 },
                      ]}
                    >
                      <Text style={{ color: "#999", fontSize: 14 }}>
                        我的答案：
                      </Text>
                      {item.myAnswer}
                    </Text>
                  </View>
                </View>
              );
            })}
        </ScrollView>
      </View>
    );
  };

  _renderNextTest = () => {
    return (
      <View style={styles.NextBtn}>
        {/* 下一轮  */}
        <TouchableOpacity
          onPress={() => {
            this.nextRound();
          }}
          style={{
            height: 40,
            width: "100%",
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c76fc",
          }}
        >
          <Text style={{ fontSize: 15, color: "#fff" }}>{"返回"}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  /*************************************************************************** render ***************************************************************************/

  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#fff",
          }}
        >
          {this._renderHeader()}
          {this.state.pageState == 1 && (
            <ScrollView style={{ flex: 1, backgroundColor: "#f8f8f8" }}>
              {/* 题干 */}
              {this.stemCom()}
            </ScrollView>
          )}
          {this.state.pageState == 1 && this.optionCom2()}
          {this.state.pageState == 1 && this._renderNext()}
          {this.state.pageState == 0 && this.fullLoading()}
          {this.state.pageState == 2 && this.testRecord()}
        </View>
        <Modal2
          style={{ position: "relative" }}
          modalAnimation={
            new ScaleAnimation({
              initialValue: 0, // optional
              useNativeDriver: true, // optional
            })
          }
          transparent={true}
          modalTitle={
            <ModalTitle
              textStyle={{ color: "#333", fontSize: 13 }}
              title="友情提示"
            />
          }
          footer={
            <ModalFooter>
              <ModalButton
                text="取 消"
                disabled={false}
                textStyle={{ color: "#333333", fontSize: 14 }}
                onPress={() => this.close2()}
              />
              <ModalButton
                text="退 出"
                disabled={false}
                textStyle={{ color: "#1c76fc", fontSize: 14 }}
                onPress={() => this.confirm()}
              />
            </ModalFooter>
          }
          visible={this.state.modalVisible2}
          onTouchOutside={() => {
            this.setState({ modalVisible2: false });
          }}
          onRequestClose={() => this.close2()}
        >
          <ModalContent>
            <View style={{ width: 260, height: "100%", paddingTop: 20 }}>
              <Text style={{ fontSize: 14, color: "#999", lineHeight: 22 }}>
                注：点击退出即返回上一页，此次测试数据不做记录，您之后将重新做题！
              </Text>
            </View>
          </ModalContent>
        </Modal2>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  NextBtn: {
    paddingHorizontal: 20,
    height: 60,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    width: "35%",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 45 + BaseView.currentHeight,
    right: 20,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    backgroundColor: "#f8f8f8",
    paddingBottom: 10,
  },
  btnSty: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
  },
  quesItem: {
    minWidth: 50,
    height: 30,
    marginRight: 5,
    textAlign: "center",
    borderBottomColor: "#1c76fc",
    borderBottomWidth: 1,
  },
  option_box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    backgroundColor: "#f8f8f8",
    paddingHorizontal: 20,
  },
  option: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: "#fff",
    borderRadius: 5,
    margin: 5,
    borderWidth: 1,
  },
});
