import React from "react";
import {
  StyleSheet,
  Text,
  View,
  PermissionsAndroid,
  StatusBar,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import BaseView from "../../Component/BaseView";
import { ToastShort, consolePage } from "../../Component/ToastUtlis";
import HttpUtil from "../../Component/HttpUtil";
import resource from "../ApublicResourceSty";
import ReturnPHCom from "../../Component/returnPHCom";
import CustomStorage from "../../Component/CustomStorage";
import Paginator from "../../Component/Paginator";
import { initValue } from "../../Component/WordHighLight";
import char from "../../ApublicStyle/CharacterStyle";

export default class PhraseExampleSentenceDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.myRef = React.createRef();
    this.state = {
      documentTitle: this.props.navigation.state.params.Title,
      basis: this.props.navigation.state.params.basis,
      type: this.props.navigation.state.params.type,
      leixing: this.props.navigation.state.params.leixing,
      // 显示相关 前端分页
      pageTotal: 1,
      SentenceList: [],
      pageState: 0,
      SPName: "PhraseExampleSentence",
    };
  }

  static navigationOptions = {
    header: null,
  };

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.ValuePage();
    this.sub = this.props.navigation.addListener("didFocus", () => {
      consolePage("刷新标志", "b");
      consolePage("gSyntax/PhraseExampleSentenceDetail", "词组例句详情");
      StatusBar.setBackgroundColor("rgba(248,248,248,0)");
      StatusBar.setBarStyle("dark-content");
    });
  }

  componentWillUnmount() {
    this.sub && this.sub.remove();
  }

  initWritePermission = async () => {
    if (Platform.OS == "android") {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      } else {
        Toast.show("您未授权存储访问权限, 无法下载更新文件");
      }
    } else {
    }
  };

  ValuePage = () => {
    let storageData = JSON.parse(localStorage.getItem("StorageProgress"));
    if (storageData == null) {
      this.getData();
      return;
    }
    if (storageData[this.state.SPName] == undefined) {
      this.getData();
      return;
    }
    for (let i = 0; i < storageData[this.state.SPName].length; i++) {
      if (storageData[this.state.SPName][i].ID == this.state.basis) {
        this.page = storageData[this.state.SPName][i].page;
        this.getData();
        return;
      }
    }
    this.getData();
  };
  /*************************************************************************** 查询 ***************************************************************************/
  getData = () => {
    let info = {
      type: this.state.type,
      pageSize: 3,
      pageStart: this.page,
    };
    if (this.state.leixing == "0") {
      info.word = this.state.basis;
    }
    if (this.state.leixing == "1") {
      info.wordNum = this.state.basis;
    }
    HttpUtil.post("54011_5", info).then((response) => {
      console.log("54011_5", response);
      if (response.Code == 1) {
        let data = response.Data;
        let total = Math.ceil(response.Num / 3);
        this.setState(
          {
            SentenceList: data,
            pageTotal: total,
            isLoadMore: false,
            pageState: 1,
          },
          () => {
            this.StorageProgress();
          }
        );
      } else {
        ToastShort(response.Msg);
      }
    });
  };

  handlePageChange = (page) => {
    let i = page - 1;
    this.myRef.current.scrollTo({ x: 0, y: 0, animated: true });
    this.page = i;
    this.getData();
  };
  /*************************************************************************** 交互 ***************************************************************************/
  StorageProgress = () => {
    CustomStorage.StorageProgress(
      this.state.SPName,
      this.state.basis,
      this.state.documentTitle,
      this.page,
      this.state.pageTotal
    );
  };
  /*************************************************************************** 跳转 ***************************************************************************/
  back = () => {
    this.props.navigation.pop();
  };
  /*************************************************************************** 组件 ***************************************************************************/
  _renderHeader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: 40 + BaseView.currentHeight2,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderBottomColor: "#E5E5E5",
          borderBottomWidth: 1,
        }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{
            fontSize: 18,
            color: "#333",
            lineHeight: 40,
            maxWidth: "70%",
          }}
        >
          {this.state.documentTitle}
        </Text>
        <View
          style={{
            width: 100,
            height: 40,
            alignItems: "center",
            position: "absolute",
            top: BaseView.currentHeight2,
            right: 0,
            textAlign: "right",
          }}
        ></View>
      </View>
    );
  };

  treeClassify = (item, key, i) => {
    return (
      <View
        style={[
          resource.BookConSty,
          { marginHorizontal: 0, borderWidth: i == 1 ? 2 : 0 },
        ]}
        key={key}
      >
        {i == 1 && (
          <View style={resource.BookTopBgSty}>
            <Text style={resource.DetailTitle}>
              {item.word + "   " + item.meaning}
            </Text>
          </View>
        )}
        {i != 1 && (
          <View style={[styles.titleCon, { paddingHorizontal: 10 }]}>
            <View style={resource.HomeTitleBefore}></View>
            <View style={{ flex: 1 }}>
              <Text style={resource.DetailTitle}>
                {item.word + "   " + item.meaning}
              </Text>
            </View>
          </View>
        )}
        <View style={{ padding: 10 }}>
          {item.Children.length > 0 &&
            item.Children.map((item, index) => {
              return (
                <View key={index} style={{ marginTop: index != 0 && 10 }}>
                  <Text style={char.SENTENCE_STY}>
                    {initValue(item.sentence, item.word)}
                  </Text>
                  <Text style={char.SENTENCE_MEAN_STY}>{item.senMeaning}</Text>
                </View>
              );
            })}
        </View>
      </View>
    );
  };

  _setReturnName(obj) {
    if (obj == "back") {
      this.props.navigation.pop();
    }
  }
  /*************************************************************************** 页面 ***************************************************************************/
  render() {
    return (
      <BaseView style={styles.container}>
        <StatusBar
          backgroundColor={"rgba(248,248,248,0)"}
          barStyle={"dark-content"}
        />
        <View
          style={{
            width: "100%",
            height: Dimensions.get("window").height,
            backgroundColor: "#f8f8f8",
          }}
        >
          {/* 页面顶部 */}
          <ScrollView
            showsVerticalScrollIndicator={false}
            ref={this.myRef}
            style={{ flex: 1, backgroundColor: "#fff" }}
          >
            {this._renderHeader()}
            <View style={{ paddingHorizontal: 20, marginTop: 20 }}>
              {this.state.SentenceList.length > 0 &&
                this.state.SentenceList.map((child, key) => {
                  return this.treeClassify(child, key, 1);
                })}
            </View>
          </ScrollView>
          {this.state.pageState == 1 && this.state.pageTotal > 0 && (
            <View style={resource.BottomChangeCon}>
              {/* 分页器 */}
              <Paginator
                pageStart={this.page}
                totalPages={this.state.pageTotal}
                onPageChange={this.handlePageChange}
              />
            </View>
          )}
        </View>
        <ReturnPHCom
          source={{ curPage: "extracrr" }}
          _setReturnName={this._setReturnName.bind(this)}
        ></ReturnPHCom>
      </BaseView>
    );
  }
}

// 样式
var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  titleCon: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  titleLine: {
    width: 3,
    height: 16,
    backgroundColor: "#1c76fc",
    borderRadius: 10,
    marginRight: 8,
  },
  titleSty: {
    fontSize: 16,
    color: "#333",
    fontWeight: "bold",
  },
});
