/**
 * loading效果
 */
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";

export default class BottomBlock extends Component {
  // 构造
  constructor(props) {
    super(props);
    // 初始状态
    this.state = {};
  }

  render() {
    return <View style={styles.space} />;
  }
}

const styles = StyleSheet.create({
  space: {
    width: "100%",
    height: 100,
  },
});
