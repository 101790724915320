import { Text } from "react-native";
import config from "./Config";

export function initValue(str, value) {
  value = value.toLowerCase();
  let newVal = value.split(/[^a-zA-Z'-\s]/g);
  newVal = newVal.map((item) => item.trim()).join(" ");
  return HighLight(str, newVal);
}

/* 小写单词、词组 在一起 */
function HighLight(str, value) {
  // const regex = new RegExp(`(?<!\\w)${value}(?!\\w)`);
  const regex = new RegExp(`\\b${value}\\b`);
  let arr = str.split(regex);
  if (arr.length > 1) {
    return arr.map((item, index) => {
      return (
        <Text key={index}>
          {index != 0 && (
            <Text
              style={{ color: config.ColorObj.HighLight, fontWeight: "bold" }}
            >
              {value}
            </Text>
          )}
          {item}
        </Text>
      );
    });
  } else {
    return HighLight2(str, value);
  }
}

/* 大写单词、词组 在一起 */
function HighLight2(str, value) {
  let newVal = value.charAt(0).toUpperCase() + value.slice(1);
  // const regex = new RegExp(`(?<!\\w)${newVal}(?!\\w)`);
  const regex = new RegExp(`\\b${newVal}\\b`);

  let arr = str.split(regex);
  if (arr.length > 1) {
    return arr.map((item, index) => {
      return (
        <Text key={index}>
          {index != 0 && (
            <Text
              style={{ color: config.ColorObj.HighLight, fontWeight: "bold" }}
            >
              {newVal}
            </Text>
          )}
          {item}
        </Text>
      );
    });
  } else {
    console.log(str, value);
    return HighLight3(str, value);
  }
}

/* 单词、词组存在的单词 大小写都标红 */
function HighLight3(str, value) {
  /* 小写value数组 */
  let LowercaseArr = value.split(" ").map((item) => item.toLowerCase());
  /* 大写value数组 */
  let UppercaseArr = LowercaseArr.map(
    (item) => item.charAt(0).toUpperCase() + item.slice(1)
  );
  let valueArr = LowercaseArr.concat(UppercaseArr);
  let strArr = str.split(" ");
  return strArr.map((it) => {
    let newVal = it.split(/[^a-zA-Z'\s]/g).join("");
    if (valueArr.indexOf(newVal) != -1) {
      return HighLightItem(it + " ", valueArr[valueArr.indexOf(newVal)]);
    } else {
      return it + " ";
    }
  });
}

function HighLightItem(str, value) {
  const regex = new RegExp(`\\b${value}\\b`);
  let arr = str.split(regex);
  if (arr.length > 1) {
    return arr.map((item, index) => {
      return (
        <Text key={index}>
          {index != 0 && (
            <Text
              style={{ color: config.ColorObj.HighLight, fontWeight: "bold" }}
            >
              {value}
            </Text>
          )}
          {item}
        </Text>
      );
    });
  } else {
    return str;
  }
}
